import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from "react";
import { Box } from "@mui/material";
import { saveAs } from "file-saver";
import PropTypes from "prop-types";
import { UserContext } from "../../context/UserContext";
import { renderTextTemplate } from "../../components/generation/EditableFieldTemplate";
import { sendFieldFeedback } from "../../services/feedbackService";
import { handleExportToWordBlob } from "../../services/viDocxService";
import { handleSaveAsPDF } from "../../services/bunkerPdfService";
import ExportButtons from "../../components/generation/ExportButtons";
import GenerationCard from "../../components/generation/GenerationCard";
import DocumentViewer from "../../components/common/DocumentViewer";
import ShipsterCard from "../../components/common/ShipsterCard";
import PreviewWrapper from "../../components/generation/PreviewWrapper";
import { getOrganisationFromUser } from "../../utils/organisationUtils";
import { getFilledNavigatorBunkerInstructionsTemplate } from "../../templates/NavigatorBunkerInstructionsTemplate";

const BunkerInstructionsResult = ({ response, file }) => {
  const { user } = useContext(UserContext);
  const [trackedChanges, setTrackedChanges] = useState({});
  const [isEditable, setIsEditable] = useState(true);
  const [formData, setFormData] = useState({});

  const extractFormData = useCallback((bunkerInstructionDetails) => {
    const fields = [
      "vessel",
      "vesselImo",
      "port",
      "date",
      "cargo",
      "surveyor",
      "agent",
      "supplier",
    ];

    return fields.reduce((acc, field) => {
      acc[field] = bunkerInstructionDetails?.[field]?.value || "MISSING";
      return acc;
    }, {});
  }, []);

  useEffect(() => {
    if (response?.bunkerInstructionDetails) {
      setFormData({
        ...extractFormData(response.bunkerInstructionDetails),
        missingValue: "Input Needed",
      });
    }
  }, [response, extractFormData]);

  const trackChange = useCallback(
    (field, newValue, parentField = "bunkerInstructionDetails") => {
      setTrackedChanges((prevChanges) => {
        const originalValue = response[parentField]?.[field]?.value;
        return originalValue !== newValue
          ? { ...prevChanges, [field]: { value: newValue } }
          : prevChanges;
      });
    },
    [response]
  );

  const updateFormData = useCallback(
    (key, value) => {
      trackChange(key, value);
      setFormData((prevData) => ({ ...prevData, [key]: value }));
    },
    [trackChange]
  );

  const sendTrackChangesToBackend = useCallback(async () => {
    try {
      await sendFieldFeedback(
        getOrganisationFromUser(user),
        response.requestId,
        trackedChanges,
        "BUNKER"
      );
    } catch (error) {
      // Error handled silently
    }
  }, [user, response, trackedChanges]);

  const handleCopyToClipboard = useCallback(async () => {
    const element = document.getElementById("text-content");
    if (!element) return;

    try {
      const htmlContent = element.innerHTML;
      const plainText = element.innerText || element.textContent;

      await navigator.clipboard.write([
        new ClipboardItem({
          "text/html": new Blob([htmlContent], { type: "text/html" }),
          "text/plain": new Blob([plainText], { type: "text/plain" }),
        }),
      ]);
      alert("Text copied to clipboard!");
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }

    await sendTrackChangesToBackend();
  }, [sendTrackChangesToBackend]);

  const toggleEdit = () => {
    setIsEditable(!isEditable);
  };

  const renderedTemplates = useMemo(() => {
    const fieldTemplates = [
      { key: "vessel", label: "Vessel Name" },
      { key: "vesselImo", label: "Vessel IMO" },
      { key: "port", label: "Port" },
      { key: "date", label: "ETA Date" },
      { key: "cargo", label: "Cargo" },
      { key: "surveyor", label: "Surveyor" },
      { key: "agent", label: "Agent" },
      { key: "supplier", label: "Physical Supplier" },
    ];

    return fieldTemplates.reduce((acc, { key, label }) => {
      acc[key] = renderTextTemplate(
        formData[key],
        response?.bunkerInstructionDetails?.[key]?.context,
        label,
        key,
        response?.bunkerInstructionDetails?.[key]?.confidence,
        isEditable,
        updateFormData
      );
      return acc;
    }, {});
  }, [formData, response, isEditable, updateFormData]);

  const missingValueTemplate = renderTextTemplate(
    "Input Needed",
    null,
    "Data to be filled out",
    "LOW",
    null,
    isEditable,
    updateFormData
  );

  const renderGeneratedTemplate = () => {
    if (!user || !response?.bunkerInstructionDetails) return;

    const commonProps = {
      vessel: renderedTemplates.vessel,
      vesselImo: renderedTemplates.vesselImo,
      port: renderedTemplates.port,
      date: renderedTemplates.date,
      cargo: renderedTemplates.cargo,
      surveyor: renderedTemplates.surveyor,
      agent: renderedTemplates.agent,
      supplier: renderedTemplates.supplier,
      missingValue: missingValueTemplate,
    };

    return getFilledNavigatorBunkerInstructionsTemplate(commonProps);
  };

  const exportActions = useMemo(() => {
    const handleSaveAsWord = async () => {
      const fileName = `${formData.vessel || "unknown"}-${formData.date || "unknown"}-${formData.port || "unknown"}-BUNKER INSTRUCTIONS.docx`;
      const wordDocBlob = await handleExportToWordBlob(
        formData,
        user.orgId,
        "NAVIGATOR_BUNKER_INSTRUCTIONS"
      );
      saveAs(wordDocBlob, fileName);
    };

    return {
      handleCopy: handleCopyToClipboard,
      handleExportPDF: () =>
        handleSaveAsPDF(formData, user.orgId, "NAVIGATOR_BUNKER_INSTRUCTIONS"),
      handleExportDocx: handleSaveAsWord,
    };
  }, [handleCopyToClipboard, formData, user.orgId]);

  return (
    <Box>
      <PreviewWrapper
        generationCard={
          <GenerationCard
            title="Bunker Instructions"
            multiple={false}
            toggleEdit={toggleEdit}
            id={response.requestId}
            handleToggleExport={() => {}}
          >
            {renderGeneratedTemplate()}
          </GenerationCard>
        }
        file={file}
      >
        <ShipsterCard level={0} title="Source Preview">
          <DocumentViewer file={file} />
        </ShipsterCard>
      </PreviewWrapper>
      <ExportButtons actions={exportActions} validation={false} />
    </Box>
  );
};

BunkerInstructionsResult.propTypes = {
  response: PropTypes.object.isRequired,
  file: PropTypes.string.isRequired,
};

export default BunkerInstructionsResult;
