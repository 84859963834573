import React from "react";
import PropTypes from "prop-types";
import { Box, Typography, useTheme } from "@mui/material";

// Function to determine the correct message based on the filter
const getNoDataMessage = (filter) => {
  switch (filter) {
    case "discrepancies":
      return "No discrepancies found.";
    case "missing":
      return "No missing data found.";
    case "core":
      return "No core data found.";
    default:
      return "No data available.";
  }
};

// Reusable NoDataMessage Component
const NoDataMessage = ({ filter }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        padding: 4,
        textAlign: "center",
        backgroundColor: theme.palette.background.input,
      }}
    >
      <Typography variant="h5">{getNoDataMessage(filter)}</Typography>
    </Box>
  );
};

NoDataMessage.propTypes = {
  filter: PropTypes.string.isRequired,
};

export default NoDataMessage;
