export const API_URL = (() => {
  // eslint-disable-next-line no-undef
  switch (process.env.REACT_APP_ENV) {
    case "production":
      return "https://tools.shipster.club/tools";
    case "staging":
      return "https://staging.tools.shipster.club/tools";
    default:
      return "http://localhost:8000/tools";
  }
})();

export const PLAYWRIGHT_FILES_PATH = "/usr/local/e2e_test_files/";
