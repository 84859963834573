import React, { useState, useContext } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import {
  exportToExcel,
  copyToClipboard,
  copyToClipboardSOFTable,
  copyToClipboardRemarks,
  exportToExcelSOFTable,
  exportToExcelRemarks,
} from "../../helpers";
import {
  Box,
  Grid2,
  Typography,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  IconButton,
  Checkbox,
  useTheme,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import {
  borderStyle,
  boldHeaderStyle,
  borderBRStyle,
} from "../../styles/comparisonTableStyles";
import PropTypes from "prop-types";
import VarianceSummary from "../../components/comparison/VarianceSummary";
import ComparisonTableActions from "../../components/comparison/ComparisonTableActions";
import ComparisonDetails from "../../components/comparison/ComparisonDetails";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ShipsterCard from "../../components/common/ShipsterCard";
import ActionToolBar from "../../components/common/ActionToolBar";
import NoDataMessage from "../../components/comparison/NoDataMessage";
import SignatureStampViewer from "../../components/comparison/SignatureStampViewer";
import { UserContext } from "../../context/UserContext";

const borderRStyle = {
  borderRight: "2px solid #2D6BCB",
};

const cellStyle = {
  width: "20rem",
  wordBreak: "break-word",
};

const indexCellStyle = {
  width: "2rem",
  wordBreak: "break-word",
};

const dateCellStyle = {
  width: "5rem",
  wordBreak: "break-word",
};

const paddingCellStyle = {
  width: "5rem",
};

const SOFResult = ({ result, files }) => {
  const { user } = useContext(UserContext);
  const [filter, setFilter] = useState("all");

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  if (!result) return null;

  const { agent_sof, captain_sof, comparison_result } = result;

  const signatureFields = [
    "captain_signature",
    "captain_stamp",
    "agent_signature",
    "agent_stamp",
  ];

  const handleCopy = (filter) => () => {
    if (filter === "all") {
      return copyToClipboardSOFTable(comparison_result.sof_comparison_details);
    } else if (filter === "core") {
      return copyToClipboardSOFTable(comparison_result.core_comparison_details);
    } else if (filter === "discrepancies") {
      const discrepancies = comparison_result.sof_comparison_details.filter(
        (row) =>
          row.has_time_difference === true ||
          row.type_of_difference === "EVENT_NAME_DIFFERENCE"
      );
      return copyToClipboardSOFTable(discrepancies);
    } else if (filter === "missing") {
      return copyToClipboard(comparison_result.missing_event_details);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const handleSavePdf = (filter) => () => {
    alert("PDF functionality still in development!");
  };

  const handleSaveExcel = (filter) => () => {
    if (filter === "all") {
      return exportToExcelSOFTable(comparison_result.sof_comparison_details);
    } else if (filter === "core") {
      const discrepancies = comparison_result.sof_comparison_details.filter(
        (row) =>
          row.has_time_difference === true ||
          row.type_of_difference === "EVENT_NAME_DIFFERENCE"
      );
      return exportToExcelSOFTable(discrepancies);
    } else if (filter === "discrepancies") {
      return exportToExcelSOFTable(comparison_result.core_comparison_details);
    } else if (filter === "missing") {
      return exportToExcel(comparison_result.missing_event_details);
    }
  };

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom>
        SOF Comparison Result
      </Typography>

      {/* Summary */}
      <VarianceSummary summary={comparison_result.summary} />

      {/* Signature Check */}
      {user.orgId !== "christiania" && (
        <SignatureCheckingTable
          agent_sof={agent_sof}
          captain_sof={captain_sof}
          signatureFields={signatureFields}
          files={files}
        />
      )}

      {/* Events line up comparison table */}
      <ShipsterCard level={1} title="Comparison Results">
        <ComparisonDetails>
          <SOFDetails details={agent_sof} title="Agent SOF"></SOFDetails>
          <SOFDetails details={captain_sof} title="Captain SOF"></SOFDetails>
        </ComparisonDetails>
        <ComparisonTableActions
          filter={filter}
          filterOptions={[
            { value: "all", label: "Show All" },
            { value: "discrepancies", label: "Show Only Discrepancies" },
            { value: "core", label: "Show Only Core Events" },
            { value: "missing", label: "Show Only Missing" },
          ]}
          onFilterChange={handleFilterChange}
          onCopy={handleCopy}
          onSavePdf={handleSavePdf}
          onSaveExcel={handleSaveExcel}
        />
        <SOFTable comparisonResult={comparison_result} filter={filter} />
      </ShipsterCard>

      {/* Remarks */}
      <RemarksTable
        agent_remarks={agent_sof.event_details.filter((row) => row.comment)}
        agent_remarks_table={agent_sof.remarks_table}
        agent_delays_table={agent_sof.delays_table}
        captain_remarks={captain_sof.event_details.filter((row) => row.comment)}
        captain_remarks_table={captain_sof.remarks_table}
        captain_delays_table={captain_sof.delays_table}
      />
    </Box>
  );
};
SOFResult.propTypes = {
  result: PropTypes.object.isRequired,
  files: PropTypes.array.isRequired,
};

const SignatureCheckingTable = ({
  agent_sof,
  captain_sof,
  signatureFields,
  files,
}) => {
  const theme = useTheme();

  const renderCheckIcon = () => {
    return (
      <Box
        sx={{
          width: "1.5rem",
          height: "1.5rem",
          borderRadius: "50%",
          backgroundColor: theme.palette.additional.mint7,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CheckIcon
          sx={{
            color: theme.palette.background.input,
            fontSize: 20,
          }}
        />
      </Box>
    );
  };

  const renderXIcon = () => {
    return (
      <Box
        sx={{
          width: "1.5rem",
          height: "1.5rem",
          borderRadius: "50%",
          backgroundColor: theme.palette.semantic.error,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CloseIcon
          sx={{
            color: theme.palette.background.input,
            fontSize: 20,
          }}
        />
      </Box>
    );
  };

  const renderUnsureIcon = () => {
    return (
      <Box
        sx={{
          width: "1.5rem",
          height: "1.5rem",
          borderRadius: "50%",
          backgroundColor: theme.palette.semantic.warning,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <QuestionMarkIcon
          sx={{
            color: theme.palette.background.input,
            fontSize: 20,
          }}
        />
      </Box>
    );
  };

  const SignatureCheckbox = ({ field, signatureDetector, borderStyle }) => {
    const { present, unsure } = signatureDetector[field];
    return (
      <TableCell key={field} style={borderStyle}>
        <Checkbox
          checked={present && !unsure}
          icon={unsure ? renderUnsureIcon() : renderXIcon()}
          checkedIcon={unsure ? renderUnsureIcon() : renderCheckIcon()}
        />
      </TableCell>
    );
  };
  SignatureCheckbox.propTypes = {
    field: PropTypes.string.isRequired,
    signatureDetector: PropTypes.shape(
      PropTypes.objectOf(
        PropTypes.shape({
          present: PropTypes.bool.isRequired,
          unsure: PropTypes.bool.isRequired,
        })
      )
    ).isRequired,
    borderStyle: PropTypes.object,
  };

  const renderTable = () => {
    return (
      <TableContainer
        style={{
          backgroundColor: theme.palette.background.input,
          maxHeight: 400,
          overflowY: "auto",
          flex: 1,
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell
                style={{ ...borderBRStyle(theme) }}
                sx={{ fontWeight: "bold" }}
              ></TableCell>
              <TableCell
                style={{ ...borderStyle(theme) }}
                sx={{ fontWeight: "bold" }}
              >
                Master Signature
              </TableCell>
              <TableCell
                style={{ ...borderStyle(theme) }}
                sx={{ fontWeight: "bold" }}
              >
                Master Stamp
              </TableCell>
              <TableCell
                style={{ ...borderStyle(theme) }}
                sx={{ fontWeight: "bold" }}
              >
                Agent Signature
              </TableCell>
              <TableCell
                style={{ ...borderBRStyle(theme) }}
                sx={{ fontWeight: "bold" }}
              >
                Agent Stamp
              </TableCell>
              <TableCell
                style={{ ...borderStyle(theme) }}
                sx={{ fontWeight: "bold" }}
              >
                Page Number
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* Agent SOF Row */}
            <TableRow>
              <TableCell style={{ ...borderRStyle }}>Agent SOF</TableCell>
              {signatureFields.map((field) => (
                <SignatureCheckbox
                  key={field}
                  field={field}
                  signatureDetector={agent_sof.signature_detector}
                  borderStyle={{ ...(field === "agent_stamp" && borderRStyle) }}
                />
              ))}
              <TableCell>{agent_sof.signature_detector.page_number}</TableCell>
            </TableRow>
            {/* Captain SOF Row */}
            <TableRow>
              <TableCell style={{ ...borderRStyle }}>Master SOF</TableCell>
              {signatureFields.map((field) => (
                <SignatureCheckbox
                  key={field}
                  field={field}
                  signatureDetector={captain_sof.signature_detector}
                  borderStyle={{ ...(field === "agent_stamp" && borderRStyle) }}
                />
              ))}
              <TableCell>
                {captain_sof.signature_detector.page_number}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <ShipsterCard title="Signature Check" level={1}>
      {renderTable()}
      <Grid2
        container
        spacing={2}
        justifyContent="center"
        alignItems="stretch"
        mt={2}
      >
        <Grid2 item size={6} display="flex" justifyContent="center">
          <SignatureStampViewer
            file={files[0]}
            boundingBoxes={agent_sof.signature_detector.bounding_boxes}
            title={"Agent SOF"}
            width="100%"
          />
        </Grid2>
        <Grid2 item size={6} display="flex" justifyContent="center">
          <SignatureStampViewer
            file={files[1]}
            boundingBoxes={captain_sof.signature_detector.bounding_boxes}
            title={"Master SOF"}
            width="100%"
          />
        </Grid2>
      </Grid2>
    </ShipsterCard>
  );
};

SignatureCheckingTable.propTypes = {
  agent_sof: PropTypes.object.isRequired,
  captain_sof: PropTypes.object.isRequired,
  signatureFields: PropTypes.array.isRequired,
  files: PropTypes.array.isRequired,
};

const SOFDetails = ({ details, title }) => {
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Card
      sx={{
        background: theme.palette.background.level2,
        borderRadius: "8px",
        boxShadow: "0px 4px 8px 0px rgba(38, 40, 44, 0.08)",
      }}
    >
      <CardContent>
        <Typography
          variant="body1"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontWeight: 500,
            marginBottom: isExpanded ? 1 : 0,
            borderBottom: `1px solid ${theme.palette.primary.blue3}`,
          }}
        >
          {title}
          <IconButton size="small" onClick={toggleExpand}>
            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Typography>
        {isExpanded && (
          <Box>
            <Grid2 container spacing={2}>
              <Grid2 item size={4}>
                <Typography
                  variant="body1"
                  sx={{ color: theme.palette.primary.blue3 }}
                >
                  Vessel
                </Typography>

                <Typography
                  variant="body1"
                  sx={{ color: theme.palette.primary.blue3 }}
                >
                  Port
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: theme.palette.primary.blue3 }}
                >
                  Last Port
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: theme.palette.primary.blue3 }}
                >
                  Next Port
                </Typography>
              </Grid2>
              <Grid2 item size={8}>
                <Typography
                  variant="body1"
                  sx={{ color: theme.palette.text.main }}
                >
                  {details.vessel ? details.vessel : "Not specified"}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: theme.palette.text.main }}
                >
                  {details.port ? details.port : "Not specified"}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: theme.palette.text.main }}
                >
                  {details.last_port ? details.last_port : "Not specified"}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: theme.palette.text.main }}
                >
                  {details.next_port ? details.next_port : "Not specified"}
                </Typography>
              </Grid2>
            </Grid2>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

SOFDetails.propTypes = {
  details: PropTypes.object.isRequired, // Define the type of response, e.g., object, string, etc.
  title: PropTypes.string.isRequired,
};

const SOFTable = ({ comparisonResult, filter }) => {
  const renderTable = () => {
    if (filter === "all") {
      return (
        <ComparisonResult
          comparisonResult={comparisonResult.sof_comparison_details}
          filter={filter}
        />
      );
    } else if (filter === "core") {
      return (
        <ComparisonResult
          comparisonResult={comparisonResult.core_comparison_details}
          filter={filter}
        />
      );
    } else if (filter === "discrepancies") {
      const discrepancies = comparisonResult.sof_comparison_details.filter(
        (row) =>
          row.has_time_difference === true ||
          row.type_of_difference === "EVENT_NAME_DIFFERENCE"
      );
      return (
        <ComparisonResult comparisonResult={discrepancies} filter={filter} />
      );
    } else if (filter === "missing") {
      return (
        <MissingEventsComparison
          eventDetails={comparisonResult.missing_event_details}
        />
      );
    }
    return null;
  };

  return renderTable();
};

SOFTable.propTypes = {
  comparisonResult: PropTypes.object,
  filter: PropTypes.string,
};

const MissingEventsComparison = ({ eventDetails }) => {
  const theme = useTheme();
  if (eventDetails.length === 0) {
    return <NoDataMessage filter={"missing"} />;
  }
  return (
    <>
      <TableContainer
        style={{
          backgroundColor: theme.palette.background.input,
          maxHeight: 600,
          overflowY: "auto",
        }}
      >
        <Table stickyHeader>
          <TableHead sx={{ position: "sticky", top: 0, zIndex: 1 }}>
            <TableRow>
              <TableCell style={{ ...boldHeaderStyle(theme) }} colSpan={1} />
              <TableCell style={{ ...boldHeaderStyle(theme) }} colSpan={1} />
              <TableCell
                style={{
                  ...boldHeaderStyle(theme),
                  textAlign: "center",
                }}
                colSpan={3}
              >
                Agent Statement
              </TableCell>

              <TableCell
                style={{
                  ...boldHeaderStyle(theme),
                  textAlign: "center",
                }}
                colSpan={3}
              >
                Master Statement
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                style={{ ...boldHeaderStyle(theme), ...indexCellStyle }}
                sx={{ fontWeight: "bold" }}
              >
                #
              </TableCell>
              <TableCell
                style={{
                  ...borderRStyle,
                  ...boldHeaderStyle(theme),
                  ...dateCellStyle,
                }}
                sx={{ fontWeight: "bold" }}
              >
                Date
              </TableCell>
              <TableCell
                style={{ ...borderStyle(theme), ...cellStyle }}
                sx={{ fontWeight: "bold" }}
              >
                Agent Event
              </TableCell>
              <TableCell
                style={{ ...borderStyle(theme), ...paddingCellStyle }}
              ></TableCell>
              <TableCell
                style={{ ...borderStyle(theme), ...cellStyle }}
                sx={{ fontWeight: "bold" }}
              >
                Agent Times
              </TableCell>
              <TableCell
                style={{ ...borderStyle(theme), ...cellStyle }}
                sx={{ fontWeight: "bold" }}
              >
                Master Event
              </TableCell>
              <TableCell
                style={{ ...borderStyle(theme), ...paddingCellStyle }}
              ></TableCell>
              <TableCell
                style={{ ...borderStyle(theme), ...cellStyle }}
                sx={{ fontWeight: "bold" }}
              >
                Master Times
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {eventDetails.map((row, index) => (
              <TableRow key={index}>
                <TableCell style={{ ...indexCellStyle }}>{index + 1}</TableCell>
                <TableCell style={{ ...borderRStyle, ...dateCellStyle }}>
                  {row.date}{" "}
                </TableCell>
                <TableCell style={{ ...cellStyle }}>
                  {row.present_in_agent_sof ? row.event : "-"}
                </TableCell>
                <TableCell style={{ ...paddingCellStyle }}></TableCell>
                <TableCell style={{ ...cellStyle }}>
                  {row.present_in_agent_sof && row.from_time_str} -{" "}
                  {row.present_in_agent_sof && row.to_time_str}
                </TableCell>
                <TableCell style={{ ...cellStyle }}>
                  {row.present_in_captain_sof ? row.event : "-"}
                </TableCell>
                <TableCell style={{ ...paddingCellStyle }}></TableCell>
                <TableCell style={{ ...cellStyle }}>
                  {row.present_in_captain_sof && row.from_time_str} -{" "}
                  {row.present_in_captain_sof && row.to_time_str}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

MissingEventsComparison.propTypes = {
  eventDetails: PropTypes.array,
  title: PropTypes.string,
};

const ComparisonResult = ({ comparisonResult, filter }) => {
  const theme = useTheme();
  if (comparisonResult.length === 0) {
    return <NoDataMessage filter={filter} />;
  }
  return (
    <>
      <TableContainer
        style={{
          backgroundColor: theme.palette.background.input,
          maxHeight: 600,
          overflowY: "auto",
        }}
      >
        <Table stickyHeader>
          <TableHead sx={{ position: "sticky", top: 0, zIndex: 1 }}>
            <TableRow>
              <TableCell style={{ ...boldHeaderStyle(theme) }} colSpan={1} />
              <TableCell style={{ ...boldHeaderStyle(theme) }} colSpan={1} />
              <TableCell
                style={{
                  ...boldHeaderStyle(theme),
                  textAlign: "center",
                }}
                colSpan={3}
              >
                Agent Statement
              </TableCell>
              <TableCell
                style={{
                  ...boldHeaderStyle(theme),
                  textAlign: "center",
                }}
                colSpan={3}
              >
                Master Statement
              </TableCell>
              <TableCell style={{ ...boldHeaderStyle(theme) }}></TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                style={{ ...boldHeaderStyle(theme), ...indexCellStyle }}
                sx={{ fontWeight: "bold" }}
              >
                #
              </TableCell>
              <TableCell
                style={{
                  ...borderRStyle,
                  ...boldHeaderStyle(theme),
                  ...dateCellStyle,
                }}
                sx={{ fontWeight: "bold" }}
              >
                Date
              </TableCell>
              <TableCell
                style={{ ...borderStyle(theme), ...cellStyle }}
                sx={{ fontWeight: "bold" }}
              >
                Agent Event
              </TableCell>
              <TableCell
                style={{ ...borderStyle(theme), ...paddingCellStyle }}
              ></TableCell>
              <TableCell
                style={{ ...borderStyle(theme), ...cellStyle }}
                sx={{ fontWeight: "bold" }}
              >
                Agent Times
              </TableCell>
              <TableCell
                style={{ ...borderStyle(theme), ...cellStyle }}
                sx={{ fontWeight: "bold" }}
              >
                Master Event
              </TableCell>
              <TableCell
                style={{ ...borderStyle(theme), ...paddingCellStyle }}
              ></TableCell>
              <TableCell
                style={{ ...borderStyle(theme), ...cellStyle }}
                sx={{ fontWeight: "bold" }}
              >
                Master Times
              </TableCell>
              <TableCell
                style={{ ...borderStyle(theme), ...paddingCellStyle }}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {comparisonResult.map((row, index) => {
              // Note order is important here, has_time_difference has priority over event_name_difference
              const textColor =
                row.has_time_difference === true
                  ? theme.palette.semantic.error
                  : row.type_of_difference === "EVENT_NAME_DIFFERENCE"
                    ? "#bbbfbb"
                    : "inherit";

              const timeOrNameDifference =
                row.has_time_difference === true ||
                row.type_of_difference === "EVENT_NAME_DIFFERENCE";
              const differenceDescription = row.has_time_difference
                ? row.time_difference_description
                : row.type_of_difference === "EVENT_NAME_DIFFERENCE"
                  ? row.difference_description
                  : null;
              return (
                <TableRow key={index}>
                  <TableCell style={{ ...indexCellStyle, color: textColor }}>
                    {index + 1}
                  </TableCell>
                  <TableCell
                    style={{
                      ...borderRStyle,
                      ...dateCellStyle,
                      color: textColor,
                    }}
                  >
                    {row.date}
                  </TableCell>
                  <TableCell style={{ ...cellStyle, color: textColor }}>
                    {row.agent_event}
                  </TableCell>
                  <TableCell style={{ ...paddingCellStyle }}></TableCell>
                  <TableCell style={{ ...cellStyle, color: textColor }}>
                    {row.agent_from_time_str} - {row.agent_to_time_str}
                  </TableCell>
                  <TableCell style={{ ...cellStyle, color: textColor }}>
                    {row.captain_event}
                  </TableCell>
                  <TableCell style={{ ...paddingCellStyle }}></TableCell>
                  <TableCell style={{ ...cellStyle, color: textColor }}>
                    {row.captain_from_time_str} - {row.captain_to_time_str}
                  </TableCell>
                  <TableCell style={{ color: textColor }}>
                    {timeOrNameDifference ? (
                      <Tooltip title={differenceDescription || ""}>
                        <IconButton sx={{ color: "gray", opacity: 0.5 }}>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

ComparisonResult.propTypes = {
  comparisonResult: PropTypes.array,
  title: PropTypes.string,
  filter: PropTypes.string,
};

const RemarksTable = ({
  agent_remarks,
  captain_remarks,
  agent_remarks_table,
  captain_remarks_table,
  agent_delays_table,
  captain_delays_table,
}) => {
  const theme = useTheme();

  const concatenatedAgentRemarks = [
    ...agent_remarks,
    ...agent_remarks_table,
    ...agent_delays_table,
  ];

  const concatenatedCaptainRemarks = [
    ...captain_remarks,
    ...captain_remarks_table,
    ...captain_delays_table,
  ];

  // eslint-disable-next-line no-unused-vars
  const handleCopy = (filter) => () => {
    copyToClipboardRemarks(
      concatenatedAgentRemarks,
      concatenatedCaptainRemarks
    );
  };

  // eslint-disable-next-line no-unused-vars
  const handleSavePdf = (filter) => () => {
    alert("PDF functionality still in development!");
  };

  // eslint-disable-next-line no-unused-vars
  const handleSaveExcel = (filter) => () => {
    exportToExcelRemarks(concatenatedAgentRemarks, concatenatedCaptainRemarks);
  };

  const renderTable = () => {
    return (
      <>
        <Box sx={{ marginBottom: 2, marginTop: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 8,
            }}
          >
            {/* Agent Remarks Table */}
            <TableContainer
              style={{
                flex: 1,
                maxHeight: 400,
                overflowY: "auto",
                backgroundColor: theme.palette.background.input,
              }}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        ...boldHeaderStyle(theme),
                      }}
                      colSpan={5}
                    >
                      Agent Remarks
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{ ...boldHeaderStyle(theme) }}
                      sx={{ fontWeight: "bold" }}
                    >
                      #
                    </TableCell>
                    <TableCell
                      style={{ ...borderRStyle, ...boldHeaderStyle(theme) }}
                      sx={{ fontWeight: "bold" }}
                    >
                      Date
                    </TableCell>
                    <TableCell
                      style={{ ...boldHeaderStyle(theme) }}
                      sx={{ fontWeight: "bold" }}
                    >
                      Times
                    </TableCell>
                    <TableCell
                      style={{ ...boldHeaderStyle(theme) }}
                      sx={{ fontWeight: "bold" }}
                    >
                      Event
                    </TableCell>
                    <TableCell
                      style={{ ...boldHeaderStyle(theme) }}
                      sx={{ fontWeight: "bold" }}
                    >
                      Remark
                    </TableCell>
                  </TableRow>
                  {agent_remarks.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell style={{ ...borderRStyle }}>
                        {row.date}
                      </TableCell>
                      <TableCell>
                        {row.from_time_str} - {row.to_time_str}
                      </TableCell>
                      <TableCell>{row.event}</TableCell>
                      <TableCell>{row.comment}</TableCell>
                    </TableRow>
                  ))}
                  {agent_remarks_table.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{index + agent_remarks.length + 1}</TableCell>
                      <TableCell style={{ ...borderRStyle }}>
                        {row.date}
                      </TableCell>
                      <TableCell>
                        {row.from_time_str} - {row.to_time_str}
                      </TableCell>
                      <TableCell>{row.event}</TableCell>
                      <TableCell>{row.comment}</TableCell>
                    </TableRow>
                  ))}
                  {agent_delays_table.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        {index +
                          agent_remarks.length +
                          agent_remarks_table.length +
                          1}
                      </TableCell>
                      <TableCell style={{ ...borderRStyle }}>
                        {row.date}
                      </TableCell>
                      <TableCell>
                        {row.from_time_str} - {row.to_time_str}
                      </TableCell>
                      <TableCell>{row.event}</TableCell>
                      <TableCell>{row.comment}</TableCell>
                    </TableRow>
                  ))}
                  {agent_remarks.length === 0 &&
                    agent_remarks_table.length === 0 &&
                    agent_delays_table.length === 0 && (
                      <TableRow>
                        <TableCell
                          colSpan={5}
                          align="center"
                          sx={{
                            border: "none",
                            py: 12,
                          }}
                        >
                          No remarks found in the document
                        </TableCell>
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </TableContainer>

            {/* Captain Remarks Table */}
            <TableContainer
              style={{
                flex: 1,
                maxHeight: 400,
                overflowY: "auto",
                backgroundColor: theme.palette.background.input,
              }}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        ...boldHeaderStyle(theme),
                      }}
                      colSpan={5}
                    >
                      Master Remarks
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{ ...boldHeaderStyle(theme) }}
                      sx={{ fontWeight: "bold" }}
                    >
                      #
                    </TableCell>
                    <TableCell
                      style={{ ...borderRStyle, ...boldHeaderStyle(theme) }}
                      sx={{ fontWeight: "bold" }}
                    >
                      Date
                    </TableCell>
                    <TableCell
                      style={{ ...boldHeaderStyle(theme) }}
                      sx={{ fontWeight: "bold" }}
                    >
                      Time
                    </TableCell>
                    <TableCell
                      style={{ ...boldHeaderStyle(theme) }}
                      sx={{ fontWeight: "bold" }}
                    >
                      Event
                    </TableCell>
                    <TableCell
                      style={{ ...boldHeaderStyle(theme) }}
                      sx={{ fontWeight: "bold" }}
                    >
                      Remark
                    </TableCell>
                  </TableRow>
                  {captain_remarks.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell style={{ ...borderRStyle }}>
                        {row.date}
                      </TableCell>
                      <TableCell>
                        {row.from_time_str} - {row.to_time_str}
                      </TableCell>
                      <TableCell>{row.event}</TableCell>
                      <TableCell>{row.comment}</TableCell>
                    </TableRow>
                  ))}
                  {captain_remarks_table.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        {index + captain_remarks.length + 1}
                      </TableCell>
                      <TableCell style={{ ...borderRStyle }}>
                        {row.date}
                      </TableCell>
                      <TableCell>
                        {row.from_time_str} - {row.to_time_str}
                      </TableCell>
                      <TableCell>{row.event}</TableCell>
                      <TableCell>{row.comment}</TableCell>
                    </TableRow>
                  ))}
                  {captain_delays_table.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        {index +
                          captain_remarks.length +
                          captain_remarks_table.length +
                          1}
                      </TableCell>
                      <TableCell style={{ ...borderRStyle }}>
                        {row.date}
                      </TableCell>
                      <TableCell>
                        {row.from_time_str} - {row.to_time_str}
                      </TableCell>
                      <TableCell>{row.event}</TableCell>
                      <TableCell>{row.comment}</TableCell>
                    </TableRow>
                  ))}
                  {captain_remarks.length === 0 &&
                    captain_remarks_table.length === 0 &&
                    captain_delays_table.length === 0 && (
                      <TableRow>
                        <TableCell
                          colSpan={5}
                          align="center"
                          sx={{
                            border: "none",
                            py: 12,
                          }}
                        >
                          No remarks found in the document
                        </TableCell>
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </>
    );
  };

  return (
    <ShipsterCard title="Remarks" level={1}>
      <ActionToolBar
        onCopy={handleCopy}
        onSavePdf={handleSavePdf}
        onSaveExcel={handleSaveExcel}
      />
      {renderTable()}
    </ShipsterCard>
  );
};
RemarksTable.propTypes = {
  agent_remarks: PropTypes.array,
  captain_remarks: PropTypes.array,
  agent_remarks_table: PropTypes.array,
  captain_remarks_table: PropTypes.array,
  agent_delays_table: PropTypes.array,
  captain_delays_table: PropTypes.array,
};

export default SOFResult;
