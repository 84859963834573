import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Collapse,
  Alert,
  Grid2,
} from "@mui/material";
import { extractaMRBlMatch, compareMRBL } from "../../services/mrService";
import Feedback from "../../components/common/Feedback";
import { UserContext } from "../../context/UserContext";
import TwoDocumentUpload from "../../components/common/TwoDocumentUpload";
import DocumentUploader from "../../components/common/DocumentUploader";
import ToolHeader from "../../components/common/ToolHeader";
import ToolStepProgressBar from "../../components/common/ToolStepProgressBar";
import MultipleMRBLResult from "./MultipleMRBLResult";
import Step1Buttons from "../../components/common/Step1Buttons";
import CloseFooter from "../../components/common/CloseFooter";
import MatesReceiptsResult from "./MatesReceiptsResult";
import { getOrganisationFromUser } from "../../utils/organisationUtils";
import { getNumberOfPages } from "../../helpers";
import LoadingOverlay from "../../components/common/LoadingOverlay";
import {
  pdfDocxFileTypes,
  pdfXlsxFileTypes,
  getToolMessages,
} from "../../utils/loaderUtils";

const BLMRComparison = () => {
  const [billOfLading, setBillOfLading] = useState([]);
  const [matesReceipts, setMatesReceipts] = useState(null);
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const [useBLOCR, setUseBLOCR] = useState(true);
  const [useBLVision, setUseBLVision] = useState(true);
  const [useMROCR, setUseMROCR] = useState(true);
  const [useMRVision, setUseMRVision] = useState(true);
  const [useContentCache, setUseContentCache] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [result, setResult] = useState(null);
  const [mrpdfPageCount, setMRpdfPageCount] = useState(1);
  const [blpdfPageCount, setBLpdfPageCount] = useState(1);
  const { user } = useContext(UserContext);
  const steps = ["Upload & Compare", "Results"];
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    if (matesReceipts) {
      getNumberOfPages(matesReceipts)
        .then((pageCount) => setMRpdfPageCount(pageCount))
        .catch((error) => {
          console.error("Error getting mates receipts page count:", error);
        });
    }
    if (billOfLading?.length) {
      getNumberOfPages(billOfLading[0])
        .then((pageCount) => setBLpdfPageCount(pageCount))
        .catch((error) => {
          console.error("Error getting BL page count:", error);
        });
    }
  }, [matesReceipts, billOfLading]);

  const handleStepChange = (step) => {
    if (step <= currentStep) setCurrentStep(step);
  };

  const handleAdvancedOptionsClick = () => {
    setShowAdvancedOptions(!showAdvancedOptions);
  };

  const handleCompare = async () => {
    if (matesReceipts.length === 0 || billOfLading.length === 0) {
      alert("Please upload file(s) in both sections before comparing.");
      return;
    }

    setLoading(true);
    setError(null);
    setResult(null);

    if (mrpdfPageCount > 1 && blpdfPageCount > 1) {
      try {
        const response = await extractaMRBlMatch(billOfLading, matesReceipts, {
          useMROCR,
          useMRVision,
          useBLOCR,
          useBLVision,
          useContentCache,
          organisationId: getOrganisationFromUser(user),
        });
        setResult(response);
        setCurrentStep(1);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const comparisonResult = await compareMRBL(
          billOfLading,
          matesReceipts,
          {
            useOCR: useMROCR,
            useVision: useMRVision,
            useCache: useContentCache,
            organisationId: getOrganisationFromUser(user),
          }
        );
        setResult(comparisonResult);
        setCurrentStep(1);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const transformResult = (result) => {
    const { comparison_result: comparison } = result;
    const doc1 = comparison.mates_receipt;
    const doc2 = comparison.bill_of_lading;
    const comparisonData = comparison.mr_bl_comparison;
    const request_id = result.request_id;
    return { doc1, doc2, comparisonData, request_id };
  };

  return (
    <Box p={3}>
      <ToolHeader
        title={"Mate Receipts -> draft B/L"}
        subtext={
          "Compare cargo from Mate Receipts against draft Bill of Lading"
        }
      ></ToolHeader>
      <ToolStepProgressBar
        steps={steps}
        currentStep={currentStep}
        onStepChange={handleStepChange}
      />

      {(!result || currentStep === 0) && (
        <>
          {error && (
            <Alert severity="error" sx={{ my: 2 }}>
              {error}
            </Alert>
          )}

          <TwoDocumentUpload>
            <Grid2 size={6}>
              <DocumentUploader
                files={matesReceipts}
                setFiles={setMatesReceipts}
                title="Upload Mates Receipt"
                setResult={setResult}
                allowedFileTypes={pdfDocxFileTypes}
                singleFile={true}
              />
            </Grid2>

            <Grid2 size={6}>
              <DocumentUploader
                files={billOfLading}
                setFiles={setBillOfLading}
                title="Upload Bill of Lading"
                setResult={setResult}
                allowedFileTypes={pdfXlsxFileTypes}
                singleFile={false}
              />
            </Grid2>
          </TwoDocumentUpload>

          {user.isAdmin && (
            <>
              <Button
                variant="outlined"
                fullWidth
                onClick={handleAdvancedOptionsClick}
                sx={{ mt: 3 }}
              >
                {showAdvancedOptions
                  ? "Hide Advanced Options"
                  : "Show Advanced Options"}
              </Button>
              <Collapse in={showAdvancedOptions}>
                <Box sx={{ border: "1px solid #ccc", padding: "10px", mt: 2 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={useBLOCR}
                        onChange={(e) => setUseBLOCR(e.target.checked)}
                      />
                    }
                    label="Use BL OCR"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={useBLVision}
                        onChange={(e) => setUseBLVision(e.target.checked)}
                      />
                    }
                    label="Use BL Vision"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={useMROCR}
                        onChange={(e) => setUseMROCR(e.target.checked)}
                      />
                    }
                    label="Use MR OCR"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={useMRVision}
                        onChange={(e) => setUseMRVision(e.target.checked)}
                      />
                    }
                    label="Use MR Vision"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={useContentCache}
                        onChange={(e) => setUseContentCache(e.target.checked)}
                      />
                    }
                    label="Use Cache"
                  />
                </Box>
              </Collapse>
            </>
          )}

          {/* Compare Button */}
          <Step1Buttons
            title={"Compare MR to B/L"}
            validation={
              matesReceipts?.length === 0 ||
              billOfLading?.length === 0 ||
              loading
            }
            handleMoveNextStep={handleCompare}
          />

          {loading && (
            <LoadingOverlay messages={getToolMessages["BLMR_COMPARISON"]} />
          )}
        </>
      )}
      {result && currentStep === 1 && (
        <>
          {mrpdfPageCount === 1 && blpdfPageCount === 1 && (
            <MatesReceiptsResult
              response={transformResult(result)}
              titles={["Mates Receipt", "Bill of Lading"]}
            />
          )}
          {mrpdfPageCount > 1 && blpdfPageCount > 1 && (
            <MultipleMRBLResult response={result} comparedWithBl={true} />
          )}
          <Feedback requestId={result.request_id} type={"BLMR"} />
          <CloseFooter />
        </>
      )}
    </Box>
  );
};

export default BLMRComparison;
