export const boldHeaderStyle = (theme) => ({
  fontWeight: 700,
  fontSize: "16px",
  borderBottom: `1px solid ${theme.palette.primary.main}`,
});

export const borderStyle = (theme) => ({
  borderBottom: `1px solid ${theme.palette.primary.main}`,
});

export const borderBRStyle = (theme) => ({
  borderBottom: `1px solid ${theme.palette.primary.main}`,
  borderRight: `1px solid ${theme.palette.primary.main}`,
});

export const calculatedStyle = (theme) => ({
  fontSize: 14,
  background: theme.palette.gradients.ai,
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  display: "inline-block",
});
