import React from "react";
import { Box, Typography, List, ListItem, ListItemText } from "@mui/material";
import { contentStyle } from "../styles/templateStyles";
import {
  DetailItem,
  TopPaddedText,
  Section,
  ListSection,
} from "./TemplateSections";

export const getCenturionVITemplate = ({
  brokersEmail,
  cargo,
  cpDate,
  dischargePortAgents,
  dischargePortAgentEmail,
  dischargeRate,
  laycan,
  loadPortAgentEmail,
  loadPortAgents,
  missingValue,
  portOfDischarge,
  portOfLoading,
  portOfLoadingShort,
  speed,
  vessel,
}) => {
  return {
    text1: `
            To Master M/V ${vessel} 

            Fm Centurion Bulk Pte Ltd 
 

            Dear Captain, 

            

            Good day, 

            
            1. We are hereby pleased to send you instructions for your next voyage employment as follows:
        
            - CP date: ${cpDate}

            - Laycan: ${laycan}

            - Cargo: ${cargo}

            - Loadport: ${portOfLoading}

            - Loadrate: ${missingValue}

            - Disch.port: ${portOfDischarge}

            - Disc.rate: ${dischargeRate}

            - Speed: ${speed}
            Please perform all sea voyages during our charter at vessels CP speed unless otherwise advised by WNI.
             

            Additional information: 

            - Cables, pipes etc to be protected so that is impossible for discharging equipment to contact them. 

            - Charterers undertake that loading of first layer of scrap not to be released until lowered as close as possible to tanktop and not to be dumped / dropped during loading. First layer of scrap to be loaded at an height and to be evenly stowed / trimmed to satisfaction of master before loading balance of cargo. In case of sufficient shredder loaded first in each hold this clause is not applicable method used for protecting tanktop to be as per customary load practice. 

            - Vessel to provide free charge of electricity of 440 V - 60 A, - min 45 KW per grabs/crane to operate receivers grabs simultanously. vessel to supply 440 v 3 phases 60 cycles and 45kw/60a per cranes from the power supply from cranes or engine room for discharging. 

            - Master to sign 'clean on board bs/l' and have the right and obligation to reject unclean / unfit cargo 

            - We have arranged for following company to perform a condition survey in (${portOfLoadingShort} less berthing terms)) in order to detect all detect all damages caused by stevedores in load and discharge port in case any and also go onboard and check vessels power output for the grabs for discharging as per agreement with Owners: 
        
            ${missingValue}

            2. Agents details as follows 

            Load port agents: ${loadPortAgents}

            Discharge port agents: ${dischargePortAgents}

            3. Stowage plan 

            Please provide us with vessels preliminary stowage plan basis information provided above and vessel max possible cargo intake. Kindly provide us with vessels DWT calculations (with all weights) including details of vessels BROB on departure load port together with departure drafts and FW/Constants ROB. Please also indicate BROB arrival discharge port, arrival draft Discharge port and FW/Constants ROB. Please send loadicator printout. 

         

            In our experience dead ballast can be reduced significantly if you adjust the loading sequence so that vessel is trimming aft 2,5-3 meters in the fist stages of the loading and de-ballasting period. This way you would be able to reduce dead ballast by stripping ballast tanks with a large trim to next to nothing - in this case less that a few tons in each tank. Of course this requires planning and attention to the matter, but we are sure you can undertake this task in a deeply professionally manner. 

            

            4. Notices at loadport and discharge port: 

            
            Load: Please provide a daily notices to following addresses for load port: 

            operations@centurionbulk.com 

            ${brokersEmail}
            ${loadPortAgentEmail}
            ${dischargePortAgentEmail}

            Discharge: Please provide a daily notices to following addresses for discharge port: 

            operations@centurionbulk.com 

            ${brokersEmail}
            ${dischargePortAgentEmail}

            5. Bunker planning: 

            ${missingValue}

            6. NOR: 

            I. Vessel to tender NOR immediately upon arrival at the port area: commercial, fiscal and administrative area of the port. This can be well before the actual anchorage or pilot station - please check with agents in advance. 

            II. Retender NOR at the berth 

            III. If vessel is not to berth immediately upon arrival, please re-tender NOR at upon arrival vessels customary waiting/anchorage area. NOR may be tendered atdn shinc. Please ensure that you have applied for free pratique well in advance of arrival port, and that NOR is tendered WIBON/WIFPON. 

            Notice of readiness to be tendered as soon as vessel has arrived and is ready to load or discharge any time day/night Sundays and holidays included, whether in port or not, whether in free pratique or not, whether customs cleared or not. This NOR must be tendered “Without prejudice to original (previous) notice of readiness dated….. ” 

            IV. To avoid that charterers dispute the validity of the notice for any reason, you should then give second, third and fourth notice of readiness, when vessel berths and again with the subsequent notices “Without prejudice to original (previous) notice of readiness dated….. ”

            
            Please also include total cargo quantity to be loaded/discharged in the notice of readiness after consulting with us for maximum cargo intake. 

            
            At first opportunity, Please hand over to agents your “confirmation of notice of readiness” in writing and showing the same date and time as the cable you have dispatched. Regarding date / time of acceptance, we would like to ask you for the following remark always to be inserted be agents / shippers / receivers “To be accepted as per the provisions of the charter party”


            

            7. Bills of Lading & SOF 


            Please present the proforma Bill of Lading to us. 

            Please authorise agents to sign Bills of Lading on behalf of vessel always provided in strict conformity with Mate's Receipt. 

            Please do not sign or authorize signature for any 'freight prepaid' Bills of Lading unless otherwise instructed by us. 

            
            When you will sign the original SOF please be careful, take time to read it slowly and check all remarks. 

            If you don't agree with some remarks please strikeout the line and put the correct comments. 

            

            Also please sign each page of SOF to be sure agent have presented you all pages and not only the first and last. 

            

            8. Special attention 

            It is imperative that all stevedore damages are photographed and reported immediately upon detection of damages. 

            
            Therefore kindly confirm safe receipt of these instructions together with: 


            a) Daily ETA Notices to Centurion Bulk and agents starting right now. 

            b) Pre-stowplan bss above details at the earliest 

            c) Bunker requirement for the voyage bss full speed and ECO speed 


            Thank you

 
            `,
  };
};

export const getFilledCenturionVITemplate = ({
  vessel,
  cpDate,
  laycan,
  speed,
  cargo,
  loadingAgents,
  loadAgentEmail,
  loadRate,
  dischargeAgents,
  dischargeAgentEmail,
  dischargeRate,
  portOfLoading,
  portOfDischarge,
  brokersEmail,
  missingValue,
}) => {
  const additionalInformationList = [
    "- Cables, pipes, etc., to be protected so that it is impossible for discharging equipment to contact them.",
    "- Charterers undertake that loading of the first layer of scrap not to be released until lowered as close as possible to tanktop and not to be dumped/dropped during loading. The first layer of scrap to be loaded at a height and to be evenly stowed/trimmed to the satisfaction of the master before loading the balance of the cargo. In case sufficient shredder is loaded first in each hold, this clause is not applicable. The method used for protecting tanktop to be as per customary load practice.",
    "- Vessel to provide free charge of electricity of 440 V - 60 A, min 45 KW per grab/crane to operate receivers’ grabs simultaneously. Vessel to supply 440 V, 3 phases, 60 cycles, and 45 KW/60 A per crane from the power supply from cranes or engine room for discharging.",
    "- Master to sign 'clean on board BS/L' and have the right and obligation to reject unclean/unfit cargo.",
  ];

  const norList = [
    "I. Vessel to tender NOR immediately upon arrival at the port area: commercial, fiscal and administrative area of the port. This can be well before the actual anchorage or pilot station - please check with agents in advance.",
    "II. Retender NOR at the berth.",
    "III. If vessel is not to berth immediately upon arrival, please re-tender NOR upon arrival at the vessel's customary waiting/anchorage area. NOR may be tendered atdn shinc. Please ensure that you have applied for free pratique well in advance of arrival port, and that NOR is tendered WIBON/WIFPON. Notice of readiness to be tendered as soon as vessel has arrived and is ready to load or discharge any time day/night Sundays and holidays included, whether in port or not, whether in free pratique or not, whether customs cleared or not. This NOR must be tendered “Without prejudice to original (previous) notice of readiness dated…..”",
    "IV.To avoid that charterers dispute the validity of the notice for any reason, you should then give second, third and fourth notice of readiness, when vessel berths and again with the subsequent notices “Without prejudice to original (previous) notice of readiness dated…..”",
  ];

  const specialAttentionList = [
    "a) Daily ETA Notices to Centurion Bulk and agents starting right now. ",
    "b) Pre-stowplan bss above details at the earliest ",
    "c) Bunker requirement for the voyage bss full speed and ECO speed ",
  ];

  return (
    <Box sx={contentStyle} id="text-content">
      <Typography variant="body1">To Master {vessel}</Typography>
      <Typography variant="body1">Fm Centurion Bulk Pte Ltd</Typography>
      <TopPaddedText text="Dear Captain," />
      <Typography sx={{ mt: 4 }} variant="body1">
        Good day,
      </Typography>
      <Section title="1. We are hereby pleased to send you instructions for your next voyage employment as follows::">
        <Typography sx={{ mt: 2 }} variant="body1">
          - CP Date: {cpDate}
        </Typography>
        <DetailItem label="- Laycan" value={laycan} />
        <DetailItem label="- Cargo" value={cargo} />
        <DetailItem label="- Load Port" value={portOfLoading} />
        <DetailItem label="- Load Rate" value={loadRate} />
        <DetailItem label="- Discharge Port" value={portOfDischarge} />
        <DetailItem label="- Discharge Rate" value={dischargeRate} />
        <DetailItem label="- Speed" value={speed} />
        <Typography variant="body1">
          Please perform all sea voyages during our charter at vessels CP speed
          unless otherwise advised by WNI.
        </Typography>
      </Section>
      <TopPaddedText text="Additional information:" />
      <List
        sx={{
          listStyleType: "none",
          pl: 0,
        }}
      >
        {additionalInformationList.map((item, index) => (
          <ListItem
            key={`cleaning-${index}`}
            sx={{ display: "list-item", py: 0.0 }}
          >
            <ListItemText primary={item} />
          </ListItem>
        ))}
        <ListItem sx={{ display: "list-item", py: 0.0 }}>
          - We have arranged for the following company to perform a condition
          survey in &nbsp;
          {portOfLoading}
          &nbsp; less berthing terms to detect all damages caused by stevedores
          in load and discharge ports, if any, and also go onboard to check
          vessel&apos;s power output for the grabs for discharging as per
          agreement with Owners.
        </ListItem>
      </List>
      <Typography sx={{ mt: 2 }} variant="body1">
        {missingValue}
      </Typography>
      <Section title="2. Agents details as follows">
        <TopPaddedText text="Load Port Agents" />
        <Typography variant="body1"> {loadingAgents} </Typography>
        <TopPaddedText text="Discharge Port Agents" />
        <Typography variant="body1"> {dischargeAgents} </Typography>
      </Section>
      <Section title="3. Stowage Plan">
        <TopPaddedText
          text="Please provide us with vessels preliminary stowage plan basis
        information provided above and vessel max possible cargo intake. Kindly
        provide us with vessels DWT calculations (with all weights) including
        details of vessels BROB on departure load port together with departure
        drafts and FW/Constants ROB. Please also indicate BROB arrival discharge
        port, arrival draft Discharge port and FW/Constants ROB. Please send
        loadicator printout."
        />
        <TopPaddedText
          text="In our experience dead ballast can be reduced significantly if you
        adjust the loading sequence so that vessel is trimming aft 2,5-3 meters
        in the fist stages of the loading and de-ballasting period. This way you
        would be able to reduce dead ballast by stripping ballast tanks with a
        large trim to next to nothing - in this case less that a few tons in
        each tank. Of course this requires planning and attention to the matter,
        but we are sure you can undertake this task in a deeply professionally
        manner."
        />
      </Section>
      <Section title="4. Notices at loadport and discharge port: ">
        <TopPaddedText
          text="Load: Please provide a daily notices to following addresses for load
        port:"
        />
        <Typography variant="body1">operations@centurionbulk.com</Typography>
        <Typography variant="body1"> {brokersEmail} </Typography>
        <Typography variant="body1"> {loadAgentEmail} </Typography>
        <TopPaddedText
          text="Discharge: Please provide a daily notices to following addresses for
        discharge port:"
        />
        <Typography variant="body1">operations@centurionbulk.com</Typography>
        <Typography variant="body1"> {brokersEmail} </Typography>
        <Typography variant="body1"> {dischargeAgentEmail} </Typography>
      </Section>
      <Section title="5. Bunker Planning">
        <TopPaddedText text={missingValue} />
      </Section>
      <Section title="6. NOR:">
        <ListSection items={norList} />
        <TopPaddedText text="Please also include total cargo quantity to be loaded/discharged in the notice of readiness after consulting with us for maximum cargo intake. " />
        <TopPaddedText text="At first opportunity, Please hand over to agents your \\“confirmation of notice of readiness\\” in writing and showing the same date and time as the cable you have dispatched. Regarding date / time of acceptance, we would like to ask you for the following remark always to be inserted be agents / shippers / receivers \\“To be accepted as per the provisions of the charter party\\”" />
      </Section>
      <Section title="7. Bills of Lading & SOF">
        <TopPaddedText text="Please present the proforma Bill of Lading to us." />
        <TopPaddedText
          text="Please authorise agents to sign Bills of Lading on behalf of vessel
        always provided in strict conformity with Mate's Receipt."
        />
        <TopPaddedText
          text="Please do not sign or authorize signature for any 'freight
        prepaid' Bills of Lading unless otherwise instructed by us."
        />
        <TopPaddedText
          text="When you will sign the original SOF please be careful, take time to read
        it slowly and check all remarks."
        />
        <TopPaddedText
          text="If you don't agree with some remarks please strikeout the line and
        put the correct comments."
        />
        <TopPaddedText
          text="Also please sign each page of SOF to be sure agent have presented you
        all pages and not only the first and last."
        />
      </Section>
      <Section title="8. Special attention">
        <ListSection items={norList} />
        <TopPaddedText
          text="It is imperative that all stevedore damages are photographed and
        reported immediately upon detection of damages."
        />
        <TopPaddedText
          text="Therefore kindly confirm safe receipt of these instructions together
        with:"
        />
        <ListSection items={specialAttentionList} />
        <TopPaddedText text="Thank you" />
      </Section>
    </Box>
  );
};
