import { getDocument, GlobalWorkerOptions } from "pdfjs-dist";
import { PDFDocument } from "pdf-lib";
import { Document, Packer } from "docx";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import ExcelJS from "exceljs";
import mammoth from "mammoth";
import { handleSaveLOI } from "./services/loiExportService";

GlobalWorkerOptions.workerSrc =
  "https://cdn.jsdelivr.net/npm/pdfjs-dist@latest/build/pdf.worker.min.mjs";

export const toSnakeCase = (str) => {
  if (!str) return "";
  const regex =
    /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g;
  return str
    .match(regex)
    .map((x) => x.toLowerCase())
    .join("_");
};

export const toTitleCase = (s) => {
  if (!s) return "";
  else {
    return s
      .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase()) // Initial char (after -/_)
      .replace(/[-_]+(.)/g, (_, c) => " " + c.toUpperCase()); // First char after each -/_
  }
};

export const getFormattedFileName = (filename, prefix = "", extension = "") => {
  if (!filename) {
    return `${prefix}Document${extension}`;
  }

  // Removes docx, pdf extensions
  // Replaces all characters that are not in the regex (also spaces) with an underscore
  const baseName = filename
    .replace(".docx", "")
    .replace(".pdf", "")
    .replace(/[^a-zA-Z0-9())]/g, "_");

  // Replaces occurences of multiple underscores with a single underscore
  let formattedBaseName = `${prefix}${baseName}${extension}`.replace(
    /_+/g,
    "_"
  );
  return formattedBaseName;
};

export const spliceFile = async (uploadedFile, startPage, endPage) => {
  // Extract the original file name without extension
  const originalFileName = uploadedFile.name.replace(/\.[^/.]+$/, "");

  // Check the file type
  const fileType = uploadedFile.type;

  if (fileType === "application/pdf") {
    // Handle PDF documents
    const newFileName = `${originalFileName}_spliced.pdf`;

    // Load and splice the PDF document
    const fileArrayBuffer = await uploadedFile.arrayBuffer();
    const pdfDoc = await PDFDocument.load(fileArrayBuffer);
    const splicedPdfDoc = await PDFDocument.create({
      updateMetadata: false, // Don't add/update metadata
    });

    // Loop through the specified pages and add them to the new PDF
    for (let i = startPage - 1; i < endPage; i++) {
      const [page] = await splicedPdfDoc.copyPages(pdfDoc, [i]);
      splicedPdfDoc.addPage(page);
    }

    // Save with deterministic settings
    const splicedPdfBytes = await splicedPdfDoc.save({
      useObjectStreams: false, // Avoid non-deterministic stream compression
      addDefaultPage: false, // Don't add default empty page
      preserveEditability: false, // Don't preserve editing features
      updateMetadata: false, // Don't add metadata
    });

    return new File([splicedPdfBytes], newFileName, {
      type: "application/pdf",
    });
  } else if (
    fileType ===
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  ) {
    // Handle Word documents
    const newFileName = `${originalFileName}_spliced.docx`;

    // Read the Word file as an array buffer
    const fileArrayBuffer = await uploadedFile.arrayBuffer();

    // Extract the text content from the Word file using mammoth.js
    const result = await mammoth.extractRawText({
      arrayBuffer: fileArrayBuffer,
    });
    const paragraphs = result.value.split("\n");

    // Extract the specified paragraphs
    const splicedContent = paragraphs
      .slice(startPage - 1, endPage)
      .map((text) => ({
        children: [
          {
            text,
          },
        ],
      }));

    // Create a new Word document
    const newDoc = new Document({
      sections: [
        {
          properties: {},
          children: splicedContent,
        },
      ],
    });

    // Generate the spliced Word document as a Blob
    const splicedDocBlob = await Packer.toBlob(newDoc);
    return new File([splicedDocBlob], newFileName, {
      type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    });
  } else {
    throw new Error(
      "Unsupported file type. Only PDF and Word documents are supported."
    );
  }
};

export const getNumberOfPages = async (file) => {
  if (!file?.type) return 0;
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = async function (e) {
      const arrayBuffer = e.target.result;

      try {
        if (file.type === "application/pdf") {
          // Get number of pages for PDF
          const pdf = await getDocument({ data: arrayBuffer }).promise;
          if (!pdf || typeof pdf.numPages !== "number") {
            throw new Error("Failed to load PDF or PDF has no pages.");
          }
          resolve(pdf.numPages);
        } else if (
          file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ) {
          // Approximate page count for .docx by word count
          const { value } = await mammoth.extractRawText({ arrayBuffer });
          const wordsPerPage = 500; // Approximation
          const pageCount = Math.ceil(value.split(/\s+/).length / wordsPerPage);
          resolve(pageCount);
        } else if (
          file.type ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
          file.type === "application/vnd.ms-excel"
        ) {
          // Get number of sheets for Excel file
          const workbook = XLSX.read(arrayBuffer, { type: "array" });
          const sheetCount = workbook.SheetNames.length;
          resolve(sheetCount);
        } else {
          throw new Error("Unsupported file type");
        }
      } catch (error) {
        console.error("Error processing file:", error);
        reject(error);
      }
    };

    reader.onerror = function (e) {
      reject(e);
    };

    reader.readAsArrayBuffer(file);
  });
};

const getSeverityEmoji = (severity) => {
  switch (severity) {
    case "HIGH":
      return "🔴";
    case "MEDIUM":
      return "🟠";
    case "LOW":
      return "🟢";
    default:
      return "";
  }
};

const getSeverityColor = (severity) => {
  switch (severity) {
    case "HIGH":
      return "D32F2F";
    case "MEDIUM":
      return "FF6D00";
    case "LOW":
      return "2f803a";
    case "NONE":
      return "204787";
    default:
      return "000000";
  }
};

// Copy table data to clipboard
export const copyToClipboard = (table) => {
  const headers = "#|Date|Event|From Time|To Time\n";
  const textData = table
    .map(
      (row, index) =>
        `${index + 1}|${row.date}|${row.event}|${row.from_time}|${row.to_time}`
    )
    .join("\n");
  navigator.clipboard
    .writeText(headers + textData)
    .then(() => {
      alert("Table copied to clipboard!");
    })
    .catch((err) => {
      console.error("Failed to copy:", err);
    });
};

export const copyToClipboardSOFTable = (table) => {
  // Start with the table headers
  let tableHTML = `
 <table border="1" style="border-collapse: collapse; width: 100%; text-align: left;">
   <thead>
     <tr>
       <th style="border: 1px solid black; border-right: none; padding: 8px;" colspan="3"></th>
       <th style="border: 1px solid black; padding: 8px;" colspan="2">Agent Statement</th>
       <th style="border: 1px solid black; padding: 8px;" colspan="2">Master Statement</th>
     </tr>
     <tr>
       <th style="border: 1px solid black; padding: 8px; width: 5%;"></th>
       <th style="border: 1px solid black; padding: 8px; width: 5%;">#</th>
       <th style="border: 1px solid black; padding: 8px; width: 10%;">Date</th>
       <th style="border: 1px solid black; padding: 8px; width: 20%;">Agent Event</th>
       <th style="border: 1px solid black; padding: 8px; width: 20%;">Agent Times</th>
       <th style="border: 1px solid black; padding: 8px; width: 20%;">Master Event</th>
       <th style="border: 1px solid black; padding: 8px; width: 20%;">Master Times</th>
     </tr>
   </thead>
   <tbody>
`;

  // Add table rows
  table.forEach((row, index) => {
    // Determines the row background color
    let severityEmoji = "";
    let severityColor = "000000";
    let typeOfDifference = row.type_of_difference;

    if (typeOfDifference === "EVENT_NAME_DIFFERENCE") {
      severityColor = getSeverityColor("NONE");
      severityEmoji = "🔘";
    }
    if (row.has_time_difference) {
      severityColor = getSeverityColor("HIGH");
      severityEmoji = "🔴";
    }

    tableHTML += `
   <tr style="color: ${severityColor};">
     <td style="border: 1px solid black; padding: 8px;">${severityEmoji}</td>
     <td style="border: 1px solid black; padding: 8px;">${index + 1}</td>
     <td style="border: 1px solid black; padding: 8px;">${row.date}</td>
     <td style="border: 1px solid black; padding: 8px;">${row.agent_event}</td>
     <td style="border: 1px solid black; padding: 8px;">${
       row.agent_from_time || ""
     } - ${row.agent_to_time || ""}</td>
     <td style="border: 1px solid black; padding: 8px;">${
       row.captain_event
     }</td>
     <td style="border: 1px solid black; padding: 8px;">${
       row.captain_from_time || ""
     } - ${row.captain_to_time || ""}</td>
   </tr>
 `;
  });

  // Close the table
  tableHTML += `
      </tbody>
    </table>
  `;

  // Copy the table to the clipboard
  const blob = new Blob([tableHTML], { type: "text/html" });
  const clipboardItem = new ClipboardItem({ "text/html": blob });

  navigator.clipboard
    .write([clipboardItem])
    .then(() => {
      alert("Table copied to clipboard as HTML!");
    })
    .catch((err) => {
      console.error("Failed to copy table: ", err);
    });
};

export const copyToClipboardRemarks = (agentRemarks, masterRemarks) => {
  // Start with the table headers
  let tableHTML = `
  <table border="1" style="border-collapse: collapse; width: 100%; text-align: left;">
  <thead>
    <tr>
      <th style="border: 1px solid black; border-right: none; padding: 8px;" colspan="1"></th>
      <th style="border: 1px solid black; padding: 8px;" colspan="4">Agent Remarks</th>
      <th style="border: 1px solid black; padding: 8px;" ></th>
      <th style="border: 1px solid black; padding: 8px;" colspan="4">Master Remarks</th>
    </tr>
    <tr>
      <th style="border: 1px solid black; padding: 8px; width: ">#</th>
      <th style="border: 1px solid black; padding: 8px; width:">Remark</th>
      <th style="border: 1px solid black; padding: 8px; width:  ">Event</th>
      <th style="border: 1px solid black; padding: 8px; width:  ">Date</th>
      <th style="border: 1px solid black; padding: 8px; width: ">Times</th>
      <th style="border: 1px solid black; padding: 8px; width: "> </th>
      <th style="border: 1px solid black; padding: 8px; width:  ">Remark</th>
      <th style="border: 1px solid black; padding: 8px; width: ">Event</th>
      <th style="border: 1px solid black; padding: 8px; width:  ">Date</th>
      <th style="border: 1px solid black; padding: 8px; width: ">Times</th>
    </tr>
  </thead>
  <tbody>
  `;

  // Determine the number of rows based on the maximum length of agent or master remarks
  const maxRows = Math.max(agentRemarks.length, masterRemarks.length);

  // Add rows for each remark
  for (let i = 0; i < maxRows; i++) {
    const agentRow = agentRemarks[i] || {};
    const masterRow = masterRemarks[i] || {};

    tableHTML += `
      <tr>
        <td style="border: 1px solid black; padding: 8px;">${i + 1}</td>
        <td style="border: 1px solid black; padding: 8px;">${
          agentRow.comment || ""
        }</td>
        <td style="border: 1px solid black; padding: 8px;">${
          agentRow.event || ""
        }</td>
        <td style="border: 1px solid black; padding: 8px;">${
          agentRow.date || ""
        }</td>
        <td style="border: 1px solid black; padding: 8px;">${
          agentRow.from_time || ""
        } - ${agentRow.to_time || ""}</td>
        <td style="border: 1px solid black; padding: 8px;"></td>
        <td style="border: 1px solid black; padding: 8px;">${
          masterRow.comment || ""
        }</td>
        <td style="border: 1px solid black; padding: 8px;">${
          masterRow.event || ""
        }</td>
        <td style="border: 1px solid black; padding: 8px;">${
          masterRow.date || ""
        }</td>
        <td style="border: 1px solid black; padding: 8px;">${
          masterRow.from_time || ""
        } - ${masterRow.to_time || ""}</td>
      </tr>
    `;
  }

  // Close the table
  tableHTML += `
      </tbody>
    </table>
  `;

  // Copy the table to the clipboard
  const blob = new Blob([tableHTML], { type: "text/html" });
  const clipboardItem = new ClipboardItem({ "text/html": blob });

  navigator.clipboard
    .write([clipboardItem])
    .then(() => {
      alert("Agent and Master Remarks copied to clipboard as HTML table!");
    })
    .catch((err) => {
      console.error("Failed to copy table: ", err);
    });
};

const getCargoComparisonTableData = (filteredRows, comparison_result) => {
  const vmLotDetails = comparison_result.second_set_of_documents.flatMap(
    (doc) => doc.lot_details
  );
  const blLotDetails = comparison_result.first_set_of_documents.flatMap(
    (doc) => doc.lot_details
  );

  const vmLotMap = vmLotDetails.reduce((map, vm) => {
    map[vm.lot] = vm;
    return map;
  }, {});

  const blLotMap = blLotDetails.reduce((map, bl) => {
    map[bl.lot] = bl;
    return map;
  }, {});

  // Provides all the lots in filteredRows with the details from vmLotMap and blLotMap
  const enrichedLots = filteredRows.map((row) => {
    const vmLotInfo = vmLotMap[row.lot] || [];
    const blLotInfo = blLotMap[row.lot] || [];

    const quantityMetricsVM = vmLotInfo?.quantity_metrics || [];
    const quantityMetricsBL = blLotInfo?.quantity_metrics || [];

    const piecesVM = quantityMetricsVM[0]?.value || "";
    const volumeVM = quantityMetricsVM[1]?.value || "";

    const piecesBL = quantityMetricsBL[0]?.value || "";
    const volumeBL = quantityMetricsBL[1]?.value || "";

    const missing = vmLotInfo.length === 0 || blLotInfo.length === 0;

    const hasVariance =
      row.quantity_metrics_variance?.some(
        (m) =>
          quantityMetricsVM.some(
            (metric) =>
              m.metric === metric.metric ||
              (Array.isArray(m.metric_synonyms) &&
                m.metric_synonyms.includes(metric.metric))
          ) && m.variance === "MATERIAL_VARIANCE"
      ) ||
      row.quantity_metrics_variance?.some(
        (m) =>
          quantityMetricsBL.some(
            (metric) =>
              m.metric === metric.metric ||
              (Array.isArray(m.metric_synonyms) &&
                m.metric_synonyms.includes(metric.metric))
          ) && m.variance === "MATERIAL_VARIANCE"
      );

    return {
      lot: row.lot,
      vm_pieces: piecesVM,
      vm_volume: volumeVM,
      vm_description: vmLotInfo?.description || "",
      vm_port_of_loading: vmLotInfo?.port_of_loading || "",
      vm_port_of_discharge: vmLotInfo?.port_of_discharge || "",
      vm_page: vmLotInfo?.page || "",
      bl_pieces: piecesBL,
      bl_volume: volumeBL,
      bl_description: blLotInfo?.description || "",
      bl_port_of_loading: blLotInfo?.port_of_loading || "",
      bl_port_of_discharge: blLotInfo?.port_of_discharge || "",
      bl_page: blLotInfo?.page || "",
      has_variance: hasVariance,
      missing: missing,
    };
  });

  return enrichedLots;
};

export const copyToClipboardCargo = (filteredRows, comparison_result) => {
  const comparisonTable = getCargoComparisonTableData(
    filteredRows,
    comparison_result
  );

  // Start with the table headers
  let tableHTML = `
 <table border="1" style="border-collapse: collapse; width: 100%; text-align: left;">
   <thead>
     <tr>
       <th style="border: 1px solid black; padding: 8px;"></th>
       <th style="border: 1px solid black; padding: 8px;" colspan="7">Bill of Lading(s)</th>
       <th style="border: 1px solid black; padding: 8px;"></th>
       <th style="border: 1px solid black; padding: 8px;" colspan="6">Voyage Manifest(s)</th>
     </tr>
     <tr>
       <th style="border: 1px solid black; padding: 8px;"></th>
       <th style="border: 1px solid black; padding: 8px;">Lot</th>
       <th style="border: 1px solid black; padding: 8px;">Pieces</th>
       <th style="border: 1px solid black; padding: 8px;">Volume</th>
       <th style="border: 1px solid black; padding: 8px;">Description</th>
       <th style="border: 1px solid black; padding: 8px;">Port of Loading</th>
       <th style="border: 1px solid black; padding: 8px;">Port of Discharge</th>
       <th style="border: 1px solid black; padding: 8px;">Page</th>
       <th style="border: 1px solid black; padding: 16px;"></th>
       <th style="border: 1px solid black; padding: 8px;">Pieces</th>
       <th style="border: 1px solid black; padding: 8px;">Volume</th>
       <th style="border: 1px solid black; padding: 8px;">Description</th>
       <th style="border: 1px solid black; padding: 8px;">Port of Loading</th>
       <th style="border: 1px solid black; padding: 8px;">Port of Discharge</th>
       <th style="border: 1px solid black; padding: 8px;">Page</th>
     </tr>
   </thead>
   <tbody>
`;

  // Puts comparison table into HTML table
  comparisonTable.forEach((row) => {
    // Determines the row background color
    let severityEmoji = "";
    let severityColor = "000000";
    if (row.missing) {
      severityColor = "#D32F2F";
      severityEmoji = "🔴";
    } else if (row.has_variance) {
      severityColor = "#FF6D00";
      severityEmoji = "🟠";
    }

    // Adds the row with the background color applied
    tableHTML += `
     <tr style="color: ${severityColor};">
       <td style="border: 1px solid black; padding: 8px;">${severityEmoji}</td>
       <td style="border: 1px solid black; padding: 8px;">${row.lot}</td>
       <td style="border: 1px solid black; padding: 8px;">${row.bl_pieces}</td>
       <td style="border: 1px solid black; padding: 8px;">${row.bl_volume}</td>
       <td style="border: 1px solid black; padding: 8px;">${row.bl_description}</td>
       <td style="border: 1px solid black; padding: 8px;">${row.bl_port_of_loading}</td>
       <td style="border: 1px solid black; padding: 8px;">${row.bl_port_of_discharge}</td>
       <td style="border: 1px solid black; padding: 8px;">${row.bl_page}</td>
       <td style="border: 1px solid black; padding: 16px;"></td>
       <td style="border: 1px solid black; padding: 8px;">${row.vm_pieces}</td>
       <td style="border: 1px solid black; padding: 8px;">${row.vm_volume}</td>
       <td style="border: 1px solid black; padding: 8px;">${row.vm_description}</td>
       <td style="border: 1px solid black; padding: 8px;">${row.vm_port_of_loading}</td>
       <td style="border: 1px solid black; padding: 8px;">${row.vm_port_of_discharge}</td>
       <td style="border: 1px solid black; padding: 8px;">${row.vm_page}</td>
     </tr>
   `;
  });

  // Close the table
  tableHTML += `
   </tbody>
 </table>
`;

  const blob = new Blob([tableHTML], { type: "text/html" });
  const clipboardItem = new ClipboardItem({ "text/html": blob });

  navigator.clipboard
    .write([clipboardItem])
    .then(() => {
      alert("Table copied to clipboard as HTML!");
    })
    .catch((err) => {
      console.error("Failed to copy table: ", err);
    });
};

export const copyToClipboardCargoTotals = (filteredRows, fileNames) => {
  if (!filteredRows || filteredRows.length === 0) {
    alert("No data to copy.");
    return;
  }

  // Create a temporary div for HTML content
  const tempDiv = document.createElement("div");

  // Create the table element
  const table = document.createElement("table");
  table.style.width = "100%";
  table.style.borderCollapse = "collapse";

  // Extract all unique metric names
  const metricNames = new Set();
  filteredRows.forEach((row) => {
    row.bill_of_lading_totals?.forEach((metric) =>
      metricNames.add(metric.metric)
    );
  });

  const metricList = Array.from(metricNames);

  // Create table headers
  const headerRow = document.createElement("tr");
  const headers = [
    "Bill of Lading - File Name",
    ...metricList.map((metric) => `BL Extracted ${metric}`),
    ...metricList.map((metric) => `VM Calculated ${metric}`),
    ...metricList.map((metric) => `Difference ${metric}`),
  ];

  headers.forEach((header) => {
    const th = document.createElement("th");
    th.textContent = header;
    th.style.border = "1px solid black";
    th.style.padding = "8px";
    th.style.backgroundColor = "#f2f2f2";
    headerRow.appendChild(th);
  });

  table.appendChild(headerRow);

  // Populate table rows with totals data
  filteredRows.forEach((row, rowIndex) => {
    const tableRow = document.createElement("tr");
    const fileName = fileNames[rowIndex];

    const BLTotals = row.bill_of_lading_totals;
    const VMTotals = row.voyage_manifest_calculated_totals;
    const differences = row.differences;

    [
      fileName,
      ...BLTotals.map((metric) => metric.value ?? ""),
      ...VMTotals.map((metric) => metric.value ?? ""),
      ...metricList.map(
        (metric) =>
          differences.find((m) => m.metric === metric)?.difference ?? "0"
      ),
    ].forEach((value) => {
      const td = document.createElement("td");
      td.textContent = value;
      td.style.border = "1px solid black";
      td.style.padding = "8px";
      tableRow.appendChild(td);
    });

    table.appendChild(tableRow);
  });

  // Append table to temporary div
  tempDiv.appendChild(table);

  // Copy the HTML table to the clipboard
  const blob = new Blob([tempDiv.innerHTML], { type: "text/html" });
  const clipboardItem = new ClipboardItem({ "text/html": blob });

  navigator.clipboard
    .write([clipboardItem])
    .then(() => {
      alert("Table copied to clipboard!");
    })
    .catch((err) => {
      console.error("Failed to copy table: ", err);
    });
};

export const copyToClipboardHireStatement = (
  gatherAllRows,
  filteredRows,
  leftFileName,
  rightFileName
) => {
  const rows = gatherAllRows(filteredRows);

  // Start with the table headers
  let tableHTML = `
    <table border="1" style="border-collapse: collapse; width: 100%; text-align: left;">
      <thead>
        <tr>
          <th style="border: 1px solid black; padding: 8px;"></th>
          <th style="border: 1px solid black; padding: 8px;"></th>
          <th style="border: 1px solid black; padding: 8px;" colspan="2">${leftFileName}</th>
          <th style="border: 1px solid black; padding: 8px;" colspan="2">${rightFileName}</th>
          <th style="border: 1px solid black; padding: 8px;"></th>  
        </tr>
        <tr>
          <th style="border: 1px solid black; padding: 8px;"></th>
          <th style="border: 1px solid black; padding: 8px;"></th>
          <th style="border: 1px solid black; padding: 8px;">Calculated</th>
          <th style="border: 1px solid black; padding: 8px;">Extracted</th>
          <th style="border: 1px solid black; padding: 8px;">Calculated</th>
          <th style="border: 1px solid black; padding: 8px;">Extracted</th>
          <th style="border: 1px solid black; padding: 8px;">Discrepancy</th>

        </tr>
      </thead>
      <tbody>
   `;

  // Puts rows into HTML table
  rows.forEach((row) => {
    const severity = row.pop();
    const severityEmoji = getSeverityEmoji(severity);
    const rowBackgroundColor = getSeverityColor(severity);

    // Adds the row with the text color and emoji highlighting applied
    tableHTML += `
        <tr style="color: #${rowBackgroundColor};">
           <td style="border: 1px solid black; padding: 8px;">${severityEmoji}</td>
          <td style="border: 1px solid black; padding: 8px;">${row[0]}</td>
          <td style="border: 1px solid black; padding: 8px;">${row[1]}</td>
          <td style="border: 1px solid black; padding: 8px;">${row[2]}</td>
          <td style="border: 1px solid black; padding: 8px;">${row[3]}</td>
          <td style="border: 1px solid black; padding: 8px;">${row[4]}</td>
          <td style="border: 1px solid black; padding: 8px;">${row[5]}</td>
        </tr>
      `;
  });

  // Close the table
  tableHTML += `
      </tbody>
    </table>
   `;

  const blob = new Blob([tableHTML], { type: "text/html" });
  const clipboardItem = new ClipboardItem({ "text/html": blob });

  navigator.clipboard
    .write([clipboardItem])
    .then(() => {
      alert("Table copied to clipboard as HTML!");
    })
    .catch((err) => {
      console.error("Failed to copy table: ", err);
    });
};

export const exportToExcelCargo = (filteredRows, comparison_result) => {
  const comparisonTable = getCargoComparisonTableData(
    filteredRows,
    comparison_result
  );

  // Set up worksheet data
  const headers = [
    "Pieces",
    "Volume",
    "Description",
    "Port of Loading",
    "Port of Discharge",
    "Page",
  ];
  const worksheetData = [
    [
      null,
      null,
      null,
      "Bill of",
      "Lading(s)",
      null,
      null,
      null,
      null,
      null,
      "Voyage",
      "Manifest(s)",
      null,
      null,
      null,
    ],
    ["LOT", ...headers, null, ...headers], // Header
    ...comparisonTable.map((row) => [
      row.lot,
      row.bl_pieces,
      row.bl_volume,
      row.bl_description,
      row.bl_port_of_loading,
      row.bl_port_of_discharge,
      row.bl_page,
      null,
      row.vm_pieces,
      row.vm_volume,
      row.vm_description,
      row.vm_port_of_loading,
      row.vm_port_of_discharge,
      row.vm_page,
    ]),
  ];

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Comparison Result");

  // Adds rows from worksheetData into worksheet with highlights
  worksheetData.forEach((row, rowIndex) => {
    const newRow = worksheet.addRow(row);

    if (rowIndex >= 2) {
      let highlightColor = "000000";
      const lot = comparisonTable[rowIndex - 2];
      highlightColor = lot.has_variance ? "FF6D00" : highlightColor;
      highlightColor = lot.missing ? "D32F2F" : highlightColor;
      newRow.eachCell((cell) => {
        cell.font = {
          color: { argb: highlightColor },
        };
      });
    }
  });

  // Sets column width, use small numbers for smaller columns
  worksheet.columns = [
    { width: 5 },
    { width: 12 },
    { width: 12 },
    { width: 12 },
    { width: 12 },
    { width: 12 },
    { width: 5 },
    { width: 5 },
    { width: 12 },
    { width: 12 },
    { width: 12 },
    { width: 12 },
    { width: 12 },
    { width: 5 },
  ];

  // Export as Excel file
  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "cargo_comparison_result.xlsx");
  });
};

export const exportToExcelHireStatement = (
  gatherAllRows,
  filteredRows,
  leftFileName,
  rightFileName
) => {
  const rows = gatherAllRows(filteredRows);

  // Adds headers
  const headers = [
    null,
    "Calculated",
    "Extracted",
    "Calculated",
    "Extracted",
    "Discrepancy",
  ];
  const worksheetData = [
    [null, null, null, null, null, null, null],
    headers,
    ...rows,
  ];

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Comparison Result");

  // Adds rows from worksheetData into worksheet with highlighted text
  worksheetData.forEach((row, rowIndex) => {
    if (rowIndex >= 2) {
      const rowSeverity = row.pop();
      const newRow = worksheet.addRow(row);
      let highlightColor = getSeverityColor(rowSeverity);
      newRow.eachCell((cell) => {
        cell.font = {
          color: { argb: highlightColor },
        };
      });
    } else {
      worksheet.addRow(row);
    }
  });

  // Creates big column for header titles and sets column width
  worksheet.mergeCells("B1:C1");
  worksheet.mergeCells("D1:E1");
  worksheet.getCell("B1").value = leftFileName;
  worksheet.getCell("D1").value = rightFileName;
  worksheet.columns = [
    { width: 22 },
    { width: 12 },
    { width: 30 },
    { width: 12 },
    { width: 30 },
    { width: 12 },
  ];

  // Prevents text overlapping next column values
  worksheet.getColumn(3).alignment = { wrapText: true };
  worksheet.getColumn(5).alignment = { wrapText: true };

  // Export as Excel file
  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "hire_statement_comparison_result.xlsx");
  });
};

// Export table data to Excel
export const exportToExcel = (table) => {
  // Prepare the data for Excel
  const worksheetData = [
    ["#", "Date", "Event", "From Time", "To Time"],
    ...table.map((row, index) => [
      index + 1,
      row.date,
      row.event,
      row.from_time,
      row.to_time,
    ]),
  ];

  // Create a worksheet and a workbook
  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Comparison Result");

  // Export as Excel file
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  saveAs(blob, "comparison_result.xlsx");
};

// Export table data to Excel
export const exportToExcelSOFTable = (table) => {
  // Create a workbook and worksheet
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Comparison Result");

  // Define headers
  const headers = [
    "#",
    "Date",
    "Agent Event",
    "Agent Times",
    "Master Event",
    "Master Times",
  ];
  const worksheetData = [
    [null, null, null, null, null, null],
    headers,
    ...table,
  ];

  // Add table rows to the worksheet with highlights
  worksheetData.forEach((row, index) => {
    if (index >= 2) {
      const rowData = [
        index - 1,
        row.date,
        row.agent_event,
        `${row.agent_from_time} - ${row.agent_to_time}`,
        row.captain_event,
        `${row.captain_from_time} - ${row.captain_to_time}`,
      ];
      const newRow = worksheet.addRow(rowData);
      let highlightColor = getSeverityColor("");
      highlightColor =
        row.type_of_difference === "EVENT_NAME_DIFFERENCE"
          ? getSeverityColor("NONE")
          : highlightColor;
      highlightColor = row.has_time_difference
        ? getSeverityColor("HIGH")
        : highlightColor;
      newRow.eachCell((cell) => {
        cell.font = {
          color: { argb: highlightColor },
        };
      });
    } else {
      worksheet.addRow(row);
    }
  });

  // Creates big column for header titles and sets column width
  worksheet.mergeCells("C1:D1");
  worksheet.mergeCells("E1:F1");
  worksheet.getCell("C1").value = "Agent Statement";
  worksheet.getCell("E1").value = "Master Statement";
  worksheet.columns = [
    { width: 22 },
    { width: 12 },
    { width: 30 },
    { width: 12 },
    { width: 30 },
    { width: 12 },
  ];

  worksheet.columns = [
    { width: 5 },
    { width: 10 },
    { width: 40 },
    { width: 15 },
    { width: 40 },
    { width: 15 },
  ];

  //Text wrapping
  worksheet.getColumn(2).alignment = { wrapText: true };
  worksheet.getColumn(4).alignment = { wrapText: true };

  // Export as Excel file
  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "sof_comparison_result.xlsx");
  });
};

export const exportToExcelRemarks = (agentRemarks, masterRemarks) => {
  const workbook = new ExcelJS.Workbook();

  // Helper function to create a worksheet
  const createWorksheet = (workbook, title, remarks) => {
    const worksheet = workbook.addWorksheet(title);

    const headers = ["#", "Remark", "Event", "Date", "Times"];
    const worksheetData = [
      [null, null, null, null, null],
      headers,
      ...remarks.map((row, index) => [
        index + 1,
        row.comment || "",
        row.event || "",
        row.date || "",
        `${row.from_time || ""} - ${row.to_time || ""}`,
      ]),
    ];

    // Populate data
    worksheetData.forEach((row) => {
      worksheet.addRow(row);
    });

    // Creates big column for header titles and sets column width
    worksheet.mergeCells("A1:E1");
    worksheet.getCell("A1").value = title;

    worksheet.columns = [
      { width: 3 },
      { width: 25 },
      { width: 25 },
      { width: 12 },
      { width: 15 },
    ];

    // Enable text wrapping
    worksheet.columns.forEach((column) => {
      column.alignment = { wrapText: true };
    });
  };

  createWorksheet(workbook, "Agent Remarks", agentRemarks);
  createWorksheet(workbook, "Master Remarks", masterRemarks);

  // Export as Excel file
  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "remarks.xlsx");
  });
};

const getStyle = (significance) => {
  switch (significance) {
    case "EXACT_MATCH":
    default:
      return "inherit";
    case "MATERIAL_VARIANCE":
      return "#DC3058";
    case "INSIGNIFICANT_VARIANCE":
      return "#FF8B00";
  }
};

export const handleCopyMRComparisonTable = (
  filteredRows,
  mrComparisonResult,
  titles
) => {
  if (!filteredRows || !filteredRows.main.length) {
    alert("No data to copy.");
    return;
  }

  // Create a temporary container for the HTML
  const tempDiv = document.createElement("div");

  // Generate the main table
  const mainTable = document.createElement("table");
  mainTable.style.width = "100%";
  mainTable.style.borderCollapse = "collapse";

  // Create table headers
  const headerRow = document.createElement("tr");
  ["Field", titles[0] || "Original MR", titles[1] || "Updated MR"].forEach(
    (header) => {
      const th = document.createElement("th");
      th.textContent = header;
      th.style.border = "1px solid black";
      th.style.padding = "8px";
      th.style.backgroundColor = "#f2f2f2";
      headerRow.appendChild(th);
    }
  );
  mainTable.appendChild(headerRow);

  // Populate main table rows
  filteredRows.main.forEach((field) => {
    const row = document.createElement("tr");
    const fieldName = toTitleCase(field);

    [
      fieldName,
      mrComparisonResult.doc1[field] || "-",
      mrComparisonResult.doc2[field] || "-",
    ].forEach((value) => {
      const change =
        mrComparisonResult.comparisonData[`${field}_change`]
          ?.significant_variance || "EXACT_MATCH";
      const td = document.createElement("td");
      td.textContent = value;
      td.style.border = "1px solid black";
      td.style.padding = "8px";
      td.style.color = getStyle(change);
      row.appendChild(td);
    });

    mainTable.appendChild(row);
  });

  tempDiv.appendChild(mainTable);

  // Add hold details if present
  if (filteredRows.holds.length > 0 || filteredRows.metrics.length > 0) {
    const holdTable = document.createElement("table");
    holdTable.style.width = "100%";
    holdTable.style.borderCollapse = "collapse";
    holdTable.style.marginTop = "16px";

    const holdHeaderRow = document.createElement("tr");
    ["Field", titles[0] || "Original MR", titles[1] || "Updated MR"].forEach(
      (header) => {
        const th = document.createElement("th");
        th.textContent = header;
        th.style.border = "1px solid black";
        th.style.padding = "8px";
        th.style.backgroundColor = "#f2f2f2";
        holdHeaderRow.appendChild(th);
      }
    );
    holdTable.appendChild(holdHeaderRow);

    // Populate hold rows
    filteredRows.holds.forEach((field) => {
      const row = document.createElement("tr");
      const fieldName = toTitleCase(field);

      [
        fieldName,
        mrComparisonResult.doc1.hold_details[0][field] || "-",
        mrComparisonResult.doc2.hold_details[0][field] || "-",
      ].forEach((value) => {
        const change =
          mrComparisonResult.comparisonData.hold_changes[0][
            `${field}_variance`
          ] || "EXACT_MATCH";
        const td = document.createElement("td");
        td.textContent = value;
        td.style.border = "1px solid black";
        td.style.padding = "8px";
        td.style.color = getStyle(change);
        row.appendChild(td);
      });

      holdTable.appendChild(row);
    });

    const row = document.createElement("tr");
    const td = document.createElement("td");
    td.textContent = "QUANTITY METRICS";
    td.style.border = "1px solid black";
    td.style.padding = "8px";
    td.colSpan = 3;
    row.appendChild(td);
    holdTable.appendChild(row);

    filteredRows.metrics.forEach((field) => {
      const row = document.createElement("tr");
      const fieldName = toTitleCase(field.metric);
      const doc1QuantityMetrics =
        mrComparisonResult.doc1.hold_details[0]["quantity_metrics"];
      const doc2QuantityMetrics =
        mrComparisonResult.doc2.hold_details[0]["quantity_metrics"];
      const doc1Metric = doc1QuantityMetrics.find(
        (m) => m.metric?.toLowerCase() === field.metric?.toLowerCase()
      );
      const doc2Metric = doc2QuantityMetrics.find(
        (m) => m.metric?.toLowerCase() === field.metric?.toLowerCase()
      );

      [fieldName, doc1Metric.value || "-", doc2Metric.value || "-"].forEach(
        (value) => {
          const change = field.variance || "EXACT_MATCH";
          const td = document.createElement("td");
          td.textContent = value;
          td.style.border = "1px solid black";
          td.style.padding = "8px";
          td.style.color = getStyle(change);
          row.appendChild(td);
        }
      );

      holdTable.appendChild(row);
    });

    tempDiv.appendChild(holdTable);
  }
  const blob = new Blob([tempDiv.innerHTML], { type: "text/html" });
  const clipboardItem = new ClipboardItem({ "text/html": blob });
  // Add the HTML content to the clipboard
  navigator.clipboard
    .write([clipboardItem])
    .then(() => {
      alert("Table copied to clipboard!");
    })
    .catch((err) => {
      console.error("Failed to copy table: ", err);
    });
};

export const handleSaveExcelMRComparisonTable = (
  filteredRows,
  mrComparisonResult,
  titles
) => {
  if (
    !filteredRows ||
    (!filteredRows.main.length && !filteredRows.holds.length)
  ) {
    alert("No data to save.");
    return;
  }

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Comparison Result");

  let worksheetData = [[null, null, null]];

  // Add main details sheet
  if (filteredRows.main.length > 0) {
    const mainHeaders = [
      "Field",
      titles[0] || "Original MR",
      titles[1] || "Updated MR",
    ];
    const mainData = [
      mainHeaders,
      ...filteredRows.main.map((field) => [
        toTitleCase(field),
        mrComparisonResult.doc1[field] || "-",
        mrComparisonResult.doc2[field] || "-",
        mrComparisonResult.comparisonData[`${field}_change`]
          ?.significant_variance || "EXACT_MATCH",
      ]),
    ];
    worksheetData = [...worksheetData, ...mainData];
  }

  // Add hold details sheet
  if (filteredRows.holds.length > 0 || filteredRows.metrics.length > 0) {
    const holdHeaders = [
      "Field",
      titles[0] || "Original MR",
      titles[1] || "Updated MR",
    ];
    const holdData = [
      holdHeaders,
      ...filteredRows.holds.map((field) => [
        toTitleCase(field),
        mrComparisonResult.doc1.hold_details[0][field] || "-",
        mrComparisonResult.doc2.hold_details[0][field] || "-",
        mrComparisonResult.comparisonData.hold_changes[0][
          `${field}_variance`
        ] || "EXACT_MATCH",
      ]),
    ];

    // Add quantity metrics rows if present
    if (filteredRows.metrics.length > 0) {
      holdData.push(["QUANTITY METRICS", "", ""]);
      holdData.push(
        ...filteredRows.metrics.map((row) => [
          toTitleCase(row.metric),
          mrComparisonResult.doc1.hold_details[0].quantity_metrics.find(
            (m) => m.metric?.toLowerCase() === row.metric?.toLowerCase()
          )?.value || "-",
          mrComparisonResult.doc2.hold_details[0].quantity_metrics.find(
            (m) => m.metric?.toLowerCase() === row.metric?.toLowerCase()
          )?.value || "-",
          row.variance || "EXACT_MATCH",
        ])
      );
    }
    worksheetData = [...worksheetData, ...holdData];
  }

  worksheetData.forEach((row, rowIndex) => {
    if (rowIndex >= 2) {
      const rowSeverity = row.pop();
      const newRow = worksheet.addRow(row);
      let highlightColor = getStyle(rowSeverity).replace("#", "");
      newRow.eachCell((cell) => {
        cell.font = {
          color: { argb: highlightColor },
        };
      });
    } else {
      worksheet.addRow(row);
    }
  });

  worksheet.columns = [{ width: 20 }, { width: 25 }, { width: 25 }];

  // Prevents text overlapping next column values
  worksheet.getColumn(1).alignment = { wrapText: true };
  worksheet.getColumn(2).alignment = { wrapText: true };
  worksheet.getColumn(3).alignment = { wrapText: true };

  // Export as Excel file
  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "MRComparisonTable.xlsx");
  });
};

export const exportToExcelCargoTotals = (filteredRows, fileNames) => {
  if (!filteredRows || filteredRows.length === 0) {
    alert("No data to export.");
    return;
  }

  // Extract all unique metric names
  const metricNames = new Set();
  filteredRows.forEach((row) => {
    row.bill_of_lading_totals.forEach((metric) =>
      metricNames.add(metric.metric)
    );
  });

  const metricList = Array.from(metricNames); // Convert Set to Array for ordered iteration

  // Define column headers dynamically
  const headers = [
    "Bill of Lading - File Name",
    ...metricList.map((metric) => `BL Extracted ${metric}`),
    ...metricList.map((metric) => `VM Calculated ${metric}`),
    ...metricList.map((metric) => `Difference ${metric}`),
  ];

  // Prepare the data for Excel
  const data = filteredRows.map((row, rowIndex) => {
    const fileName = fileNames[rowIndex];

    const BLTotals = row.bill_of_lading_totals;
    const VMTotals = row.voyage_manifest_calculated_totals;
    const differences = row.differences;

    return [
      fileName,
      ...BLTotals.map((metric) => metric.value ?? ""),
      ...VMTotals.map((metric) => metric.value ?? ""),
      ...metricList.map(
        (metric) =>
          differences.find((m) => m.metric === metric)?.difference ?? "0"
      ),
    ];
  });

  // Create a new workbook and worksheet
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet([headers, ...data]); // Array of arrays (Excel format)

  // Append worksheet to workbook
  XLSX.utils.book_append_sheet(wb, ws, "Cargo Totals");

  // Write the file
  XLSX.writeFile(wb, "Cargo_Totals.xlsx");
};

export const handleExportAllLOI = async (
  result,
  exportToggles,
  childRefs,
  toolName,
  exportType
) => {
  if (exportType !== "PDF" && exportType !== "DOCX") {
    throw new Error(
      `Invalid export type: ${exportType}, Please contact support.`
    );
  }

  let wordFiles = {};
  let refs = [];
  let zipFilenames = [];
  let detailsKey;

  switch (toolName) {
    case "BL2LOI":
      detailsKey = "bl2loi_details";
      break;
    case "LOI2LOI":
      detailsKey = "loi2loi_details";
      break;
    default:
      console.error(`Invalid toolName: ${toolName}`);
      return;
  }

  const promises = Object.entries(result[detailsKey]).map(
    async ([, value], index) => {
      const id = value[0].doc_id;
      const ref = childRefs.current[index];
      if (exportToggles[id] && ref) {
        let packageData;
        if (exportType === "PDF" && ref.getPDFPackageDefaultLOI) {
          packageData = await ref.getPDFPackageDefaultLOI();
        } else if (exportType === "DOCX" && ref.getWordPackageDefaultLOI) {
          packageData = await ref.getWordPackageDefaultLOI();
        }

        if (packageData) {
          const { wordDocBlob, filename, zipFilename } = packageData;
          wordFiles[filename] = wordDocBlob;
          zipFilenames.push(zipFilename);
          refs.push(ref);
        }
      } else if (!exportToggles?.[id]) {
        console.error(`Export toggle is missing for document ID: ${id}`);
      } else if (!ref) {
        console.error(`Reference is missing for index ${index}`);
      }
    }
  );

  await Promise.all(promises);

  // If there is only one ref, handle it directly for Word and no need to export as zip
  // also sends track changes to backend
  if (exportType === "DOCX" && refs.length === 1) {
    await refs[0].handleSaveAsWordLOI();
    return;
  }

  refs.forEach((ref) => ref.sendTrackChangesToBackend());

  // Export as Zip for PDF and Word
  handleSaveLOI(wordFiles, "DEFAULT_LOI", exportType, zipFilenames[0]);
};

export const getFormattedFilenameForTool = (filename, type) => {
  switch (type) {
    case "MR_LOI":
      return filename + ".docx";
    default:
      return getFormattedFileName(filename, "LOI_", ".docx");
  }
};

export const getResponseFileNameForTool = (firstLoiDetail, extension, type) => {
  switch (type) {
    case "MR_LOI":
      return firstLoiDetail + `.${extension}`;
    default:
      return getFormattedFileName(firstLoiDetail, "LOI_", `.${extension}`);
  }
};

export const getZipFilename = (exportType, formData, type) => {
  if (exportType !== "PDF" && exportType !== "DOCX") {
    throw new Error(
      `Invalid export type: ${exportType}, Please contact support.`
    );
  }
  let vessel =
    !formData.vessel || formData.vessel === "MISSING" ? "" : formData.vessel;
  const portDischarge =
    !formData.portDischarge || formData.portDischarge === "MISSING"
      ? ""
      : formData.portDischarge;
  const zipFilename =
    type === "MR_LOI"
      ? `LOI_MR_${exportType}_${vessel}__${portDischarge}`
      : `LOI_${exportType}_${vessel}__${portDischarge}`;
  return zipFilename;
};
