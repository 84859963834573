export const getNavigatorSubstituteBLLOITemplate = ({
  shipper,
  consignee,
  portDischarge,
  charterPartyName,
}) => {
  return {
    templateLabel: "Substitute Bill",

    indemnityTerms:
      "In consideration of your complying with our above request, we hereby agree as follows:",

    indemnityAgreement: [
      "1. To indemnify you, your servants and agents and to hold all of you harmless in respect of any liability, loss, damage or expense of whatsoever nature and howsoever arising which you may sustain by reason of your compliance with any or all of our above requests.",
      "2. In the event of any proceedings being commenced against you or any of your servants or agents in any connection whatsoever by reason of your compliance with any or all of our above requests, to provide you or them on demand with sufficient funds to defend the same.",
      "3. If, in connection with your compliance with any or all of our above requests the ship, or any other ship or property in the same or associated ownership, management or control, should be arrested or detained or should the arrest or detention thereof be threatened, or should there be any interference in the use or trading of the ship (whether by virtue of a caveat being entered on the ship’s registry or otherwise howsoever), to provide on demand such bail or other security as may be required to prevent such arrest or detention or to secure the release of such ship or property or to remove such interference and to indemnify you in respect of any liability, loss, damage or expense caused by such arrest or detention or threatened arrest or detention or such interference, whether or not such arrest or detention or threatened arrest or detention or such interference may be justified.",
      "4. The liability of each and every person under this indemnity shall be joint and several and shall not be conditional upon your proceeding first against any person, whether or not such person is party to or liable under this indemnity.",
      "5. This indemnity shall be governed by and construed in accordance with English law and each and every person liable under this indemnity shall at your request submit to the jurisdiction of the High Court of Justice of England.",
    ],

    indemnitySublist: [],

    signatureText: `
            Yours faithfully
            For and on behalf of
            ${charterPartyName}

            .......................................
            Signature
        `,

    word_textBeforeIndemnityList: `
        The above cargo was shipped on the above ship by ${shipper} and consigned to ${consignee} for delivery at ${portDischarge} pursuant to the above bill of lading issued in a set of three (the “First Bills of Lading”). We, ${charterPartyName}, hereby (i) confirm that upon signing this letter of indemnity we shall procure and, as soon as possible thereafter, provide to you all of the originals of the First Bills of Lading and (ii) request that following receipt by you or your appointed agent of all of the originals of the First Bills of Lading you or your appointed agent shall mark them “null and void” and, thereupon, shall issue and release a further bill of lading in a set of three (the “Substitute Bills of Lading”) for the same cargo in the wording attached hereto (attach copy of intended switch bill of lading);

        In consideration of your complying with our above requests, we hereby agree as follows :-

        `,

    word_indemnityAgreementList: `
            To indemnify you, your servants and agents and to hold all of you harmless in respect of any liability, loss, damage or expense of whatsoever nature and howsoever arising which you may sustain by reason of your compliance with any or all of our above requests.
            In the event of any proceedings being commenced against you or any of your servants or agents in any connection whatsoever by reason of your compliance with any or all of our above requests, to provide you or them on demand with sufficient funds to defend the same.
            If, in connection with your compliance with any or all of our above requests the ship, or any other ship or property in the same or associated ownership, management or control, should be arrested or detained or should the arrest or detention thereof be threatened, or should there be any interference in the use or trading of the ship (whether by virtue of a caveat being entered on the ship’s registry or otherwise howsoever), to provide on demand such bail or other security as may be required to prevent such arrest or detention or to secure the release of such ship or property or to remove such interference and to indemnify you in respect of any liability, loss, damage or expense caused by such arrest or detention or threatened arrest or detention or such interference, whether or not such arrest or detention or threatened arrest or detention or such interference may be justified.
            The liability of each and every person under this indemnity shall be joint and several and shall not be conditional upon your proceeding first against any person, whether or not such person is party to or liable under this indemnity.
            This indemnity shall be governed by and construed in accordance with English law and each and every person liable under this indemnity shall at your request submit to the jurisdiction of the High Court of Justice of England.
        `,
  };
};
