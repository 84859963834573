import React, { useEffect, useState } from "react";
import { getDocument } from "pdfjs-dist";
import {
  Box,
  Typography,
  useTheme,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import PropTypes from "prop-types";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

// Padding in rem
const PADDED_AMOUNT = "3rem"; // Equivalent to 48px assuming 16px base font size
// Scale factor for PDF rendering quality
const SCALE_FACTOR = 1.5;

const SignatureStampViewer = ({ file, boundingBoxes, title, width }) => {
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const theme = useTheme();

  useEffect(() => {
    return () => {
      if (croppedImageUrl) {
        URL.revokeObjectURL(croppedImageUrl);
      }
    };
  }, [croppedImageUrl]);

  useEffect(() => {
    if (!file || !boundingBoxes || boundingBoxes.length === 0) {
      setLoading(false);
      setCroppedImageUrl(null);
      return;
    }

    (async () => {
      try {
        setLoading(true);

        const pdfData = await file.arrayBuffer();
        const pdf = await getDocument({ data: pdfData }).promise;

        const pageNumber = boundingBoxes[0]?.page_num ?? 1;
        if (pageNumber < 1 || pageNumber > pdf.numPages) {
          throw new Error(
            `Invalid page number: ${pageNumber}. PDF has ${pdf.numPages} pages.`
          );
        }

        const page = await pdf.getPage(pageNumber);
        const viewport = page.getViewport({ scale: SCALE_FACTOR });

        let yMin =
          Math.min(...boundingBoxes.map((b) => b.y_min * SCALE_FACTOR)) -
          parseFloat(PADDED_AMOUNT);
        let yMax =
          Math.max(...boundingBoxes.map((b) => b.y_max * SCALE_FACTOR)) +
          parseFloat(PADDED_AMOUNT);

        yMin = Math.max(0, yMin);
        yMax = Math.min(viewport.height, yMax);

        const croppedHeight = yMax - yMin;

        const fullCanvas = document.createElement("canvas");
        const fullContext = fullCanvas.getContext("2d");
        fullCanvas.width = viewport.width;
        fullCanvas.height = viewport.height;

        await page.render({ canvasContext: fullContext, viewport }).promise;

        const croppedCanvas = document.createElement("canvas");
        const croppedContext = croppedCanvas.getContext("2d");
        croppedCanvas.width = viewport.width;
        croppedCanvas.height = 200;

        croppedContext.drawImage(
          fullCanvas,
          0,
          yMin,
          viewport.width,
          croppedHeight,
          0,
          0,
          viewport.width,
          croppedCanvas.height
        );

        croppedCanvas.toBlob((blob) => {
          const url = URL.createObjectURL(blob);
          setCroppedImageUrl(url);
          setLoading(false);
          setSnackbar({
            open: true,
            message: "Signature image processed successfully",
            severity: "success",
          });
        }, "image/png");
      } catch (err) {
        console.error("Error rendering PDF:", err);
        setLoading(false);
        setSnackbar({
          open: true,
          message: "Failed to process the document.",
          severity: "error",
        });
      }
    })();
  }, [file, boundingBoxes, width]);

  return (
    <Box width={width} mt="1rem" display="flex" flexDirection="column">
      {title && (
        <Typography variant="h4" display="flex">
          <a
            href={URL.createObjectURL(file)}
            target="_blank"
            rel="noopener noreferrer"
            aria-label={`Open ${title} in new tab`}
            style={{
              color: theme.palette.primary.blue3,
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
            }}
          >
            {title}{" "}
            <OpenInNewIcon
              fontSize="small"
              style={{ marginLeft: "0.3rem" }}
              aria-hidden="true"
            />
          </a>
        </Typography>
      )}
      {loading && <CircularProgress />}
      {croppedImageUrl ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          border="1px solid #ddd"
          width="100%"
          mt="0.5rem"
        >
          <img
            src={croppedImageUrl}
            alt={`Signature section from ${title}`}
            style={{ width: "100%", objectFit: "fill" }}
          />
        </Box>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          bgcolor={theme.palette.background.default}
          color="#FFFFFF"
          width="100%"
          height="100%"
          mt="0.5rem"
        >
          No signature or stamps found
        </Box>
      )}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

SignatureStampViewer.propTypes = {
  file: PropTypes.instanceOf(File).isRequired,
  boundingBoxes: PropTypes.arrayOf(
    PropTypes.shape({
      x_min: PropTypes.number.isRequired,
      y_min: PropTypes.number.isRequired,
      x_max: PropTypes.number.isRequired,
      y_max: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      confidence: PropTypes.number.isRequired,
      page_num: PropTypes.number.isRequired,
    })
  ),
  title: PropTypes.string,
  width: PropTypes.string.isRequired,
};

export default SignatureStampViewer;
