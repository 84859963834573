import React, { useState } from "react";
import { Box, Typography, Tooltip, useTheme } from "@mui/material";
import PropTypes from "prop-types";

export const renderTextTemplate = (
  value,
  context,
  nullContext,
  valueName,
  confidence,
  isEditable,
  updateFormData
) => {
  const valueDisplay = value === "MISSING" ? `MISSING ${nullContext}` : value;
  return (
    <EditableFieldTemplate
      value={valueDisplay}
      context={context}
      nullContext={nullContext}
      confidence={confidence}
      updateFormData={updateFormData}
      valueName={valueName}
      isEditable={isEditable}
    />
  );
};

const EditableFieldTemplate = ({
  value,
  context,
  nullContext,
  confidence,
  updateFormData,
  valueName,
  isEditable,
}) => {
  const [currentConfidence, setCurrentConfidence] = useState(confidence);
  const theme = useTheme();

  // Function to get the style dynamically based on the value
  const getStrongStyle = (value, confidenceLevel) => {
    let color = "inherit";
    switch (value) {
      case "Input Needed":
        color = theme.palette.semantic.warning;
        break;
      case `MISSING ${nullContext}`:
        color = theme.palette.semantic.error;
        break;
      default:
        color = getTooltipStyle(confidenceLevel);
        break;
    }
    return {
      color: color,
      fontWeight: "bold",
      textDecoration: "underline",
      textDecorationStyle: "dotted",
    };
  };

  // Function to determine the color of the confidence level in the tooltip
  const getTooltipStyle = (confidence) => {
    switch (confidence) {
      case "HIGH":
        return theme.palette.semantic.success;
      case "MEDIUM":
        return theme.palette.semantic.warning;
      case "LOW":
        return theme.palette.semantic.error;
      default:
        return theme.palette.semantic.success;
    }
  };

  // Handle text change
  const handleBlur = (e) => {
    const newValue = e.currentTarget.textContent;
    if (newValue !== value) {
      setCurrentConfidence("HIGH");
      updateFormData(valueName, newValue);
    }
  };

  return (
    <Tooltip
      title={
        <Box>
          <Typography>Context: {context ? context : nullContext}</Typography>
          <Typography sx={{ color: getTooltipStyle(currentConfidence) }}>
            Confidence: {currentConfidence || "Unknown"}
          </Typography>
        </Box>
      }
    >
      <Typography
        component="span"
        display="inline"
        suppressContentEditableWarning={true}
        contentEditable={isEditable}
        onBlur={handleBlur}
      >
        {value?.split("\n").map((line, index, array) => (
          <React.Fragment key={index}>
            <Typography
              component="span"
              display="inline"
              sx={getStrongStyle(line, currentConfidence)}
            >
              {line}
            </Typography>
            {index < array.length - 1 && <br />}{" "}
            {/* Only insert <br /> if it's not the last item */}
          </React.Fragment>
        ))}
      </Typography>
    </Tooltip>
  );
};

EditableFieldTemplate.propTypes = {
  value: PropTypes.string.isRequired,
  context: PropTypes.string,
  nullContext: PropTypes.string,
  confidence: PropTypes.string,
  updateFormData: PropTypes.func,
  valueName: PropTypes.string,
  isEditable: PropTypes.bool.isRequired,
};

export default EditableFieldTemplate;
