import { saveAs } from "file-saver";
import { Document, Packer, Paragraph, TextRun, AlignmentType } from "docx";
import { getTemplateFunctions, splitTextToLines } from "../templates/utils";
import { getChristianiaCargoTable } from "../templates/ChristianiaVITemplate";

export const normalText = (text) => new TextRun({ text });
export const strongText = (text) => new TextRun({ text, bold: true });
export const italicText = (text) => new TextRun({ text, italics: true });
export const strongItalicText = (text) =>
  new TextRun({ text, bold: true, italics: true });

// Goes over <b>, <i>, <c>, <u> tags and formats text accordingly
export const formatText = (text) => {
  const parts = [];
  let lastIndex = 0;

  // Define supported tags and their corresponding formatting properties
  const TAG_MAPPINGS = {
    b: "bold",
    i: "italics",
    u: "underline",
    c: "bold", // Assuming <c> is for bold text like <b>
  };

  // Helper function to get text formatting options by checking opening tags
  const getFormatting = (text) => {
    const tags = text.match(/<[biuc]>/g) || [];
    const formatting = {
      bold: false,
      italics: false,
      underline: false,
    };

    // Apply formatting based on each tag found using the mapping
    tags.forEach((tag) => {
      const tagName = tag.replace(/[<>]/g, "").toLowerCase();
      const property = TAG_MAPPINGS[tagName];
      if (property) {
        formatting[property] = true;
      }
    });

    return formatting;
  };

  // Helper to remove all tags from text - more efficient regex
  const removeTags = (text) => text.replace(/<\/?[biuc]>/g, "");

  // Simplified regex pattern for better readability
  const tagGroupRegex =
    /<[biuc]>(?:[^<]*(?:<[biuc]>[^<]*<\/[biuc]>)*[^<]*)<\/[biuc]>/g;
  let match;

  while ((match = tagGroupRegex.exec(text)) !== null) {
    // Add normal text before the match
    if (match.index > lastIndex) {
      parts.push(normalText(text.substring(lastIndex, match.index)));
    }

    const fullMatch = match[0];
    const content = removeTags(fullMatch);
    const formatting = getFormatting(fullMatch);

    // Create TextRun with combined formatting - simplified object creation
    parts.push(
      new TextRun({
        text: content,
        ...formatting,
        underline: formatting.underline ? {} : undefined,
      })
    );

    lastIndex = match.index + fullMatch.length;
  }

  // Add remaining unformatted text
  if (lastIndex < text.length) {
    parts.push(normalText(text.substring(lastIndex)));
  }

  return parts;
};

export const handleExportToWordBlob = async (
  formData,
  organisationId,
  template
) => {
  const { cargo, missingValue } = formData;
  const templateText = await getTemplateFunctions[template](formData);

  // Helper function to create a Paragraph
  const createParagraph = (textRuns, spacingAfter = 100, bulletLevel = -1) => {
    const leftIndent = { 0: 720, 1: 720 };
    const hangingIndent = { 0: 360, 1: 360 };

    return new Paragraph({
      children: textRuns.flatMap((text) => formatText(text)),
      numbering:
        bulletLevel >= 0
          ? { reference: "shipster-numbering", level: bulletLevel }
          : undefined,
      indent:
        bulletLevel >= 0
          ? {
              left: leftIndent[bulletLevel],
              hanging: hangingIndent[bulletLevel],
            }
          : undefined,
      spacing: { after: spacingAfter },
    });
  };

  // Creates a section of paragraphs
  const createSection = (texts, bulletLevel = -1) => {
    let result = [];
    for (let i = 0; i < texts.length; i++) {
      const currentText = texts[i];
      const nextText = texts[i + 1];

      // If newline, adds spacing 200 to simulate a new line
      const spacingAfter = nextText && !nextText.trim() ? 200 : 0;
      result.push(createParagraph([currentText], spacingAfter, bulletLevel));
    }
    return result;
  };

  // Gets all the paragraphs for the template
  const getParagraphs = () => {
    const simpleTextParagraphs = () => {
      const { text1 } = templateText;

      if (!text1) {
        console.error(
          `Missing template text field ${text1}, did you use the right template?`
        );
      }

      return [...createSection(splitTextToLines(text1))];
    };

    const christianiaParagraphs = () => {
      const { text1, text2, cleaningList, text3, regardingList, noteText } =
        templateText;

      const missingFields = [
        "text1",
        "text2",
        "cleaningList",
        "text3",
        "regardingList",
        "noteText",
      ].filter((key) => !templateText?.[key]);

      if (missingFields.length) {
        console.error(
          `Missing template text fields: ${missingFields.join(", ")}. Did you use the right template?`
        );
      }

      return [
        ...createSection(splitTextToLines(text1)),
        createParagraph([""], 100),
        getChristianiaCargoTable(cargo, missingValue),
        createParagraph([""], 100),
        ...createSection(splitTextToLines(text2)),
        ...createSection(splitTextToLines(cleaningList), 0),
        createParagraph([""], 100),
        ...createSection(splitTextToLines(text3)),
        ...createSection(splitTextToLines(regardingList), 1),
        createParagraph([""], 100),
        ...createSection(splitTextToLines(noteText)),
      ];
    };

    switch (organisationId) {
      case "centurion":
        return simpleTextParagraphs();
      case "falcon":
        return simpleTextParagraphs();
      case "navigator":
        return simpleTextParagraphs();
      case "christiania":
        return christianiaParagraphs();
      default:
        return simpleTextParagraphs();
    }
  };

  const doc = new Document({
    numbering: {
      config: [
        {
          reference: "shipster-numbering",
          levels: [
            {
              level: 0,
              format: "bullet",
              text: "•",
              alignment: AlignmentType.START,
              paragraph: {
                indent: { left: 360, hanging: 360 }, // Indentation for first-level bullets
              },
            },
            {
              level: 1,
              format: "lowerLetter", // Sublist uses letters (a, b, c)
              text: "-", // Wrap the letter in parentheses
              alignment: AlignmentType.START,
              style: {
                paragraph: {
                  indent: { left: 360, hanging: 180 }, // Adjust indentation for sublist
                },
              },
            },
          ],
        },
      ],
    },
    sections: [
      {
        children: getParagraphs(),
      },
    ],
  });

  return Packer.toBlob(doc);
};

export const handleSaveAsWord = async (formData, organisationId, template) => {
  const fileName = `${formData.vessel || "unknown"}-${formData.cpDate || "unknown"}-${formData.portOfDischarge || "unknown"}-VOYAGE INSTRUCTIONS.docx`;
  const wordDocBlob = await handleExportToWordBlob(
    formData,
    organisationId,
    template
  );
  saveAs(wordDocBlob, fileName);
};
