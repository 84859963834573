import axios from "axios";
import { API_URL } from "./config";
import { getOrganisationFromUser } from "../utils/organisationUtils";

// Function to compare hire statements using v2 API (kept for backward compatibility)
export const compareHireStatements = async (
  hireStatement1,
  hireStatement2,
  ocr,
  vision,
  useContentCache,
  organisationId
) => {
  const formData = new FormData();

  formData.append("hire_statement_1", hireStatement1);
  formData.append("hire_statement_2", hireStatement2);
  formData.append("ocr", ocr);
  formData.append("vision", vision);
  formData.append("use_cache", useContentCache);
  formData.append("organisation_id", organisationId);

  try {
    const response = await axios.post(
      `${API_URL}/hire_comparison/v2/compare_hire_statements/files`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true, // If you're sending credentials like cookies
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error comparing hire statements:", error);
    throw error;
  }
};

// Function to compare hire statements using v3 API
export const compareHireStatementsV3 = async (
  ownerStatement,
  chartererStatement,
  ocr,
  vision,
  useContentCache,
  organisationId,
  role = "owner"
) => {
  // Validate required parameters with detailed error messages
  if (!ownerStatement) {
    throw new Error("Owner statement is missing");
  }
  if (!chartererStatement) {
    throw new Error("Charterer statement is missing");
  }

  // Validate file types with detailed error messages
  const validFileTypes = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];
  if (!validFileTypes.includes(ownerStatement.type)) {
    throw new Error(
      `Invalid owner statement file type: ${ownerStatement.type}. Supported types: PDF and Word documents`
    );
  }
  if (!validFileTypes.includes(chartererStatement.type)) {
    throw new Error(
      `Invalid charterer statement file type: ${chartererStatement.type}. Supported types: PDF and Word documents`
    );
  }

  // Validate file sizes (optional, adjust max size as needed)
  const maxFileSize = 10 * 1024 * 1024; // 10MB
  if (ownerStatement.size > maxFileSize) {
    throw new Error(
      `Owner statement file is too large: ${ownerStatement.size} bytes. Maximum size: ${maxFileSize} bytes`
    );
  }
  if (chartererStatement.size > maxFileSize) {
    throw new Error(
      `Charterer statement file is too large: ${chartererStatement.size} bytes. Maximum size: ${maxFileSize} bytes`
    );
  }

  const formData = new FormData();

  formData.append("owner_statement_file", ownerStatement);
  formData.append("charterer_statement_file", chartererStatement);
  formData.append("ocr", ocr);
  formData.append("vision", vision);
  formData.append("use_cache", useContentCache);
  formData.append("organisation_id", organisationId);
  formData.append("role", role);

  try {
    const response = await axios.post(
      `${API_URL}/hire_comparison/v3/compare_hire_statements/files`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      }
    );

    return response.data;
  } catch (error) {
    // Enhanced error handling
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error("Error response data:", error.response.data);
      throw new Error(
        error.response.data.message || "Error comparing hire statements"
      );
    } else if (error.request) {
      // The request was made but no response was received
      throw new Error("No response received from server");
    } else {
      // Something happened in setting up the request that triggered an Error
      throw new Error("Error setting up the request: " + error.message);
    }
  }
};

// Function to export hire statements to Excel
export const exportHireStatementsToExcel = async (hireStatements, user) => {
  const formData = new FormData();
  formData.append("hire_statements", JSON.stringify(hireStatements));
  formData.append("organisation_id", getOrganisationFromUser(user));

  try {
    const response = await axios.post(
      `${API_URL}/hire_comparison/v3/hire_statements_to_excel/objects`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        responseType: "blob",
        withCredentials: true,
      }
    );

    // Create a download link and trigger download
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "hire_statements.xlsx");
    document.body.appendChild(link);
    link.click();
    link.remove();

    return true;
  } catch (error) {
    console.error("Error exporting hire statements to Excel:", error);
    throw error;
  }
};
