import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import Lottie from "lottie-react";
import loadingAnimation from "../../lotties/loadingAnimation.json";
import { fullScreenOverlay } from "../../styles/fullScreenOverlay";

const LoadingOverlay = ({ messages }) => {
  const [messageIndex, setMessageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, 5000);

    return () => clearInterval(interval); // Cleanup the interval on unmount
  }, [messages]);

  return (
    <Box>
      <div
        style={{ ...fullScreenOverlay }}
        role="alert"
        aria-busy="true"
        aria-live="polite"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90vw",
            maxWidth: "37.5rem",
            minHeight: "21rem",
            padding: { xs: "1.25rem", sm: "1.875rem", md: "3.125rem" },
            backgroundColor: "#000000",
            color: "#fff",
            borderRadius: "0.5rem",
            boxShadow: "0px 0.25rem 0.75rem rgba(0, 0, 0, 0.2)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                width: { xs: "9.375rem", sm: "12.5rem", md: "15.625rem" },
                height: { xs: "9.375rem", sm: "12.5rem", md: "15.625rem" },
              }}
              className="items-center"
            >
              <Lottie animationData={loadingAnimation} />
            </Box>

            <Typography
              variant="h4"
              sx={{
                mt: "1.5rem",
                textAlign: "center",
              }}
            >
              {messages[messageIndex]}
            </Typography>
          </Box>
        </Box>
      </div>
    </Box>
  );
};

LoadingOverlay.propTypes = {
  messages: PropTypes.array.isRequired,
};

export default LoadingOverlay;
