export const pdfDocxFileTypes = ["pdf", "docx"];
export const pdfXlsxFileTypes = ["pdf", "xlsx"];
export const allFileTypes = ["pdf", "docx", "xlsx"];

const bl2loiGenerationLoadingMessages = [
  "Generating LOIs, just a moment",
  "Extracting key details from your documents.",
  "Shipster is turning your data into something useful.",
];

const blmrComparisonLoadingMessages = [
  "Comparing Mate Receipts and Bill of Lading. This could be a minute...",
  "Shipster is analyzing your files.",
  "Finding Discrepancies",
  "Shipster is turning your data into something useful.",
];

const cargoComparisonLoadingMessages = [
  "Cargo comparison in progress... This could be a minute...",
  "Comparing Voyage Manifest(s) with Bill(s) of Lading",
  "Finding Discrepancies",
  "Shipster is turning your data into something useful.",
];

const hireStatementComparisonLoadingMessages = [
  "Comparing Hire Statements. Please wait, this can take up to 15 minutes.",
  "You can grab a coffee, but leave this tab open.",
  "Comparing Hire Statements",
  "Finding Discrepancies",
];

const loi2loiGenerationLoadingMessages = [
  "Generating LOI(s), just a moment",
  "Extracting key details from your documents.",
  "Shipster is analyzing your files.",
  "Shipster is turning your data into something useful.",
];

const mrComparisonLoadingMessages = [
  "Comparing Mate Receipts, just a moment",
  "Shipster is analyzing your files.",
  "Finding Discrepancies",
  "Shipster is turning your data into something useful.",
];

const recap2VoyageInstructionsGenerationLoadingMessages = [
  "Generating Voyage Instructions, just a moment",
  "Extracting key details from your documents.",
  "Shipster is analyzing your files.",
  "Shipster is turning your data into something useful.",
];
const bunkerInstructionsGenerationLoadingMessages = [
  "Generating Bunker Instructions, just a moment",
  "Extracting key details from your email.",
  "Formatting your instructions to your template.",
  "Shipster is turning your data into something useful.",
];
const sofComparisonLoadingMessages = [
  "Comparing Agent's and Master's Statements of Fact. Please wait, this can take 2 - 3 minutes.",
  "Everyone hates waiting, now is the time to have a coffee.",
  "Comparing Events",
  "Finding Discrepancies",
];

export const getToolMessages = {
  BL2LOI_GENERATION: bl2loiGenerationLoadingMessages,
  BLMR_COMPARISON: blmrComparisonLoadingMessages,
  CARGO_COMPARISON: cargoComparisonLoadingMessages,
  HIRE_STATEMENT_COMPARISON: hireStatementComparisonLoadingMessages,
  LOI2LOI_GENERATION: loi2loiGenerationLoadingMessages,
  MR_COMPARISON: mrComparisonLoadingMessages,
  RECAP2VOYAGE_INSTRUCTIONS_GENERATION:
    recap2VoyageInstructionsGenerationLoadingMessages,
  SOF_COMPARISON: sofComparisonLoadingMessages,
  BUNKER_INSTRUCTIONS_GENERATION: bunkerInstructionsGenerationLoadingMessages,
};
