import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import PropTypes from "prop-types";

const PreviewWrapper = ({ generationCard, file, children }) => {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
        <Button
          onClick={() => setIsPreviewOpen(!isPreviewOpen)}
          variant="contained"
        >
          {isPreviewOpen ? "Hide Preview" : "Source Preview"}
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
        }}
      >
        <Box sx={{ flex: 2 }}>{generationCard}</Box>
        {isPreviewOpen && file && (
          <Box
            sx={{
              flex: 1,
              overflow: "auto",
              borderRadius: 2,
            }}
          >
            {children}
          </Box>
        )}
      </Box>
    </Box>
  );
};

PreviewWrapper.propTypes = {
  generationCard: PropTypes.node.isRequired,
  file: PropTypes.any,
  children: PropTypes.node.isRequired,
};

export default PreviewWrapper;
