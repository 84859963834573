import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  Typography,
  FormControlLabel,
  Collapse,
  Grid2,
  Alert,
} from "@mui/material";
import Feedback from "../../components/common/Feedback";
import { UserContext } from "../../context/UserContext";
import { compareSOFFiles } from "../../services/sofService";
import SOFResult from "./SOFResult";
import { getNumberOfPages } from "../../helpers";
import PageSelector from "../../components/common/PageSelector";
import ToolHeader from "../../components/common/ToolHeader";
import ToolStepProgressBar from "../../components/common/ToolStepProgressBar";
import TwoDocumentUpload from "../../components/common/TwoDocumentUpload";
import DocumentUploader from "../../components/common/DocumentUploader";
import Step1Buttons from "../../components/common/Step1Buttons";
import CloseFooter from "../../components/common/CloseFooter";
import { getOrganisationFromUser } from "../../utils/organisationUtils";
import LoadingOverlay from "../../components/common/LoadingOverlay";
import { pdfDocxFileTypes, getToolMessages } from "../../utils/loaderUtils";

const SOFComparison = () => {
  const [agentFile, setAgentFile] = useState([]);
  const [masterFile, setMasterFile] = useState([]);
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const [useCodeOCR, setUseCodeOCR] = useState(true);
  const [useGPT4VisionOcr, setUseGPT4VisionOCR] = useState(true);
  const [useDetailCache, setUseDetailCache] = useState(true);
  const [useComparisonCache, setUseComparisonCache] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [result, setResult] = useState(null);
  const { user } = useContext(UserContext);
  const [agentPageCount, setAgentPageCount] = useState(1);
  const [agentStartPage, setAgentStartPage] = useState(1);
  const [agentEndPage, setAgentEndPage] = useState(
    agentPageCount ? agentPageCount : 1
  );
  const [masterPageCount, setMasterPageCount] = useState(1);
  const [masterStartPage, setMasterStartPage] = useState(1);
  const [masterEndPage, setMasterEndPage] = useState(
    masterPageCount ? masterPageCount : 1
  );
  const steps = ["Upload & Compare", "Results"];
  const [currentStep, setCurrentStep] = useState(0);

  const handleStepChange = (step) => {
    if (step <= currentStep) setCurrentStep(step);
  };

  useEffect(() => {
    if (agentFile instanceof Blob) {
      getNumberOfPages(agentFile).then((pageCount) => {
        setAgentPageCount(pageCount);
        setAgentEndPage(pageCount);
        if (pageCount === 1) {
          setAgentStartPage(1);
        }
      });
    }
  }, [agentFile]);

  useEffect(() => {
    if (masterFile instanceof Blob) {
      getNumberOfPages(masterFile).then((pageCount) => {
        setMasterPageCount(pageCount);
        setMasterEndPage(pageCount);
        if (pageCount === 1) {
          setMasterStartPage(1);
        }
      });
    }
  }, [masterFile]);

  const handleAdvancedOptionsClick = () => {
    setShowAdvancedOptions(!showAdvancedOptions);
  };

  const handleCompare = async () => {
    if (masterFile.length === 0 || agentFile.length === 0) {
      alert("Please upload file(s) in both sections before comparing.");
      return;
    }

    setLoading(true);
    setError(null);
    setResult(null);

    try {
      const response = await compareSOFFiles(agentFile, masterFile, {
        useOCR: useCodeOCR,
        useVision: useGPT4VisionOcr,
        useDetailCache,
        useComparisonCache,
        agentPageRanges: [[agentStartPage, agentEndPage]],
        masterPageRanges: [[masterStartPage, masterEndPage]],
        organisationId: getOrganisationFromUser(user),
      });
      setResult(response);
      setCurrentStep(1);
      console.log(response);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box p={3}>
      <ToolHeader
        title={"Statement of Fact"}
        subtext={"Compare Agent’s and Master’s Statements of Fact"}
      ></ToolHeader>
      <ToolStepProgressBar
        steps={steps}
        currentStep={currentStep}
        onStepChange={handleStepChange}
      />

      {(!result || currentStep === 0) && (
        <>
          {error && (
            <Alert severity="error" sx={{ my: 2 }}>
              {error}
            </Alert>
          )}

          <TwoDocumentUpload>
            <Grid2 size={6}>
              <DocumentUploader
                files={agentFile}
                setFiles={setAgentFile}
                title="Upload Agent Statement of Fact PDF"
                setResult={setResult}
                allowedFileTypes={pdfDocxFileTypes}
                singleFile={true}
              />
            </Grid2>

            <Grid2 size={6}>
              <DocumentUploader
                files={masterFile}
                setFiles={setMasterFile}
                title="Upload Master Statement of Fact PDF"
                setResult={setResult}
                allowedFileTypes={pdfDocxFileTypes}
                singleFile={true}
              />
            </Grid2>
          </TwoDocumentUpload>

          <Typography variant="h6" sx={{ mt: 2 }}>
            Page Selection
          </Typography>
          <Grid2 container spacing={2}>
            <PageSelector
              pageCount={agentPageCount}
              startPage={agentStartPage}
              setStartPage={setAgentStartPage}
              endPage={agentEndPage}
              setEndPage={setAgentEndPage}
            />
            <PageSelector
              pageCount={masterPageCount}
              startPage={masterStartPage}
              setStartPage={setMasterStartPage}
              endPage={masterEndPage}
              setEndPage={setMasterEndPage}
            />
          </Grid2>

          {user.isAdmin && (
            <>
              <Button
                variant="outlined"
                fullWidth
                onClick={handleAdvancedOptionsClick}
                sx={{ mt: 3 }}
              >
                {showAdvancedOptions
                  ? "Hide Advanced Options"
                  : "Show Advanced Options"}
              </Button>
              <Collapse in={showAdvancedOptions}>
                <Box sx={{ border: "1px solid #ccc", padding: "10px", mt: 2 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={useCodeOCR}
                        onChange={(e) => setUseCodeOCR(e.target.checked)}
                      />
                    }
                    label="Use Tesseract OCR"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={useGPT4VisionOcr}
                        onChange={(e) => setUseGPT4VisionOCR(e.target.checked)}
                      />
                    }
                    label="GPT Vision OCR"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={useDetailCache}
                        onChange={(e) => setUseDetailCache(e.target.checked)}
                      />
                    }
                    label="Use Detail Cache"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={useComparisonCache}
                        onChange={(e) =>
                          setUseComparisonCache(e.target.checked)
                        }
                      />
                    }
                    label="Use Comparison Cache"
                  />
                </Box>
              </Collapse>
            </>
          )}

          {/* Compare Button */}
          <Step1Buttons
            title={"Compare Statement of Fact"}
            validation={
              masterFile?.length === 0 || agentFile?.length === 0 || loading
            }
            handleMoveNextStep={handleCompare}
          ></Step1Buttons>
          {loading && (
            <LoadingOverlay messages={getToolMessages["SOF_COMPARISON"]} />
          )}
        </>
      )}
      {result && currentStep === 1 && (
        <>
          <SOFResult
            result={result}
            files={[agentFile, masterFile]}
          ></SOFResult>
          <Feedback requestId={result.request_id} type={"SOF"}></Feedback>
          <CloseFooter />
        </>
      )}
    </Box>
  );
};

export default SOFComparison;
