import React from "react";
import { Box, Typography } from "@mui/material";
import {
  DetailItem,
  TopPaddedText,
  Section,
  ListSection,
} from "./TemplateSections";

export const getCenturionVIStandardTemplate = ({
  cargo,
  cpDate,
  vessel,
  dischargePortAgents,
  dischargeRate,
  loadRate,
  laycan,
  loadPortAgents,
  missingValue,
  portOfDischarge,
  portOfLoading,
  brokersEmail,
  loadPortAgentEmail,
  dischargePortAgentEmail,
}) => {
  return {
    text1: `
            To: Master, ${vessel}
            Cc: Owners, ${vessel}
            Fm: Centurion Bulk Pte Ltd 
            
            Dear Captain, Good day 
            
            In addition to our general our General TC Instructions and details of the Head Fixture with your Owner, we are hereby pleased to send you voyage instructions for your next employment as follows:
            
            1. General Voyage Details of the first employment
            
            - CP date: ${cpDate}
            - Laycan: ${laycan}
            - Cargo: ${cargo}
            - Loadport: ${portOfLoading}
            - Loadrate: ${loadRate}
            - Disch.port: ${portOfDischarge}
            - Disc.rate: ${dischargeRate}

            Important: 

            - NOR at load and discharge port to be tendered between 08:00 lt and 17:00 lt wherefore please consult with us for slowing down or increasing speed to match this window as early as possible and well in advance of vessels expected arrival. 

            - Please note that the C/P date as advised above must be inserted in the Bills of lading 


            * * Insert/attach load- and discharge port information of available ** 

            * * Insert any special provisions/mentioning of voyage if applicable * * 

            * * Draft restrictions * * 
            
            2. Agents details as follows 
            
            Load port agents: ${loadPortAgents}

            Bunker port agents: ${missingValue}
            
            Discharge port agents: ${dischargePortAgents}

            Important: We must be in copy of all correspondence with agents at all times. In the event that agents have not copied operations@centurionbulk.com to their email, please ensure that same email is forwarded to us. 
            
            3. Stowage plan 

            Please provide us with vessels preliminary stowage plan basis information provided above and vessel max possible cargo intake including full DW calculation and printout from Loadicator for both departure load port and arrival discharge port. 

            Kindly refer to our Time Charter Instructions for the required breakdown of vessels stowage plan. 
             
            4. Notices at load port, bunker and discharge port: 
            
            Load: 

            Please provide a daily ETA/readiness to following addresses: 
            operations@centurionbulk.com 
            ${brokersEmail}
            ${loadPortAgentEmail}

            Bunker port: 
            operations@centurionbulk.com 
            ${brokersEmail}
            ${missingValue}

            Discharge: 
            Please provide a daily ETA/readiness to following addresses: 
            operations@centurionbulk.com 
            ${brokersEmail}
            ${dischargePortAgentEmail}  

            5. Bunker planning: 

            ${missingValue}

            Please confirm full break down of all bunker tanks and present % filling 

            6. Voyage planning 

            Fastest and most economical route in close cooperation with the Weather Routing Company appointed. 

            7. Cargo care 

            Please obtain written instructions from agents and shippers regarding how to care for the cargo during the voyage until completely discharged - drainage log, ventilation, sealed hatches, cargo care etc. 
     
            8. Bills of Lading (Clean) 

            Clean Bill of Lading are required without any remarks. 

            Vessel must reject any unsound cargo and immediately requests that same is replaced by sound cargo. 
            
            9. Moisture Drainage from cargo holds (coal, sulphur and petcoke): 

            Vessel to pump out through vessels bilges in the holds, as necessary or at least 2 times per day, all drainage moisture form the cargo during transit voyage to discharge port(s). Master to keep written records daily of estimated quantity of moisture pumped overboard during the voyage and send same in Excel sheet format every day together with the noon report. Master to keep separate records daily of all the water or liquids pumped out/inboard during voyage. Upon arrival discharge port, before discharging commences, Master to email to Charterers and Voyage Charterers the final Excel sheet and certificate indicating the amount of daily drainage moisture pumped out/or on board such as ballast, etc. 

             
            10. De-ballast 

            In our experience dead ballast can be reduced significantly if you adjust the loading sequence so that vessel is trimming aft 2,5-3 meters in the fist stages of the loading and de-ballasting period. This way, you would be able to reduce dead ballast by stripping ballast tanks with a large trim to next to nothing - in this case less that a few tons in each tank. 

            Of course this requires planning and attention to the matter, but we are sure you can undertake this task in a deeply professionally manner. 

            
            11. SOF 

            It is very important that the SOF is correct and in accordance with vessels logs, we kindly request you to thoroughly check the SOF presented by agents and send SOF to us for approval before signing SOF - 
            Always sign SO:F ""FOR RECEIPT ONLY AND WITHOUT PREJUDICE"" and in case vessel stayed at anchor prior berthing ""Weather/ Swell/ winds/ rain while vessel waited at anchorage for a berth to become available, and their impact on cargo operations, are unknown to and not admitted by Carriers/ Vessel/ Master."" 
            
            AND REFERENCES TO HOLIDAYS IN THE SOF HAVE NOT BEEN VERIFIED AND ARE NOT ADMITTED BY OWNERS 
            

            Important for signing SOF:

            The Master is ONLY to sign 1 (one) set of SOF! 

            The validated and agreed SOF between Centurion Bulk and Master need to be signed by Master and once signed, Master will have to send the SOF to Centurion Bulk for our files.

            Please also refer to General Instruction.

            
            12. Notice of Readiness (NOR) 

            ·Notice of Readiness (NOR) is to be tendered immediately upon arrival at pilot station / load / discharge port stating the following remark: “Please consider this notice as the official NOR tendered in accordance with the relevant Charter Party terms and notify concerned parties accordingly”. 

            ·Re-tender NOR during office hours 09-17 if above NOR was not tendered within office hours, marked “Without prejudice to any previously tendered NOR”. 

            ·In case of berth congestion, NOR to be tendered immediately once vessel on arrival at the pilot station / load / discharge port and to be re-tendered clearly stating the following remark: “Without prejudice to any previously tendered NOR”, upon dropping anchor at designated waiting place. 

            ·If vessel should fail hold inspection, NOR is to be re-tendered immediately once vessel is ready for a re-inspection stating the following remark: “Without prejudice to any previously tendered NOR”. 

            ·Should the vessel arrive ahead of agreed laycan, please tender NOR immediately upon arrival and retender immediately upon actual commencement of laycan and follow above NOR regime. 

            Please also include total cargo quantity to be loaded/discharged in the notice of readiness after consulting with us for maximum cargo intake. 


            At first opportunity, Please hand over to agents your “confirmation of notice of readiness” in writing and showing the same date and time as the cable you have dispatched. Regarding date / time of acceptance, we would like to ask you for the following remark always to be inserted be agents / shippers / receivers “To be accepted as per the provisions of the charter party”

            13. IMPORTANT 

            In our general Time Charter Instructions attached, you will find all information required in regards to vessels stowage, reporting formats, NOR, SOF, Bunkering procedures etc. 

            Please take your time to carefully read through these instructions and do not hesitate to contact us should you require further clarifications 


            Therefore kindly confirm safe receipt of these instructions together with: 
             
            a) Confirmation that our general TC Instructions have been duly read and understood 
            b) Daily ETA Notices to Centurion Bulk and agents, starting right now. 
            c) Stowage plan and DW calculation asap incl print outs from Loadicator for both departure load port and arrival discharge port as per general TC Instructions 
            d) Bunker requirement soonest incl attached Excel bunker tank overview. 

            Thank you very much in advance 
 
            `,
  };
};

export const getFilledCenturionVIStandardTemplate = ({
  cpDate,
  laycan,
  vessel,
  cargo,
  loadingAgents,
  loadRate,
  dischargeAgents,
  dischargeRate,
  portOfLoading,
  portOfDischarge,
  brokersEmail,
  loadAgentEmail,
  dischargeAgentEmail,
  missingValue,
}) => {
  const importantList = [
    "- NOR at load and discharge port to be tendered between 08:00 lt and 17:00 lt wherefore please consult with us for slowing down or increasing speed to match this window as early as possible and well in advance of vessels expected arrival. ",
    "- Please note that the C/P date as advised above must be inserted in the Bills of lading ",
  ];

  const norList = [
    "- Notice of Readiness (NOR) is to be tendered immediately upon arrival at pilot station / load / discharge port stating the following remark: “Please consider this notice as the official NOR tendered in accordance with the relevant Charter Party terms and notify concerned parties accordingly”.",
    "- Re-tender NOR during office hours 09-17 if above NOR was not tendered within office hours, marked “Without prejudice to any previously tendered NOR”.",
    "- In case of berth congestion, NOR to be tendered immediately once vessel on arrival at the pilot station / load / discharge port and to be re-tendered clearly stating the following remark: “Without prejudice to any previously tendered NOR”, upon dropping anchor at designated waiting place.",
    "- If vessel should fail hold inspection, NOR is to be re-tendered immediately once vessel is ready for a re-inspection stating the following remark: “Without prejudice to any previously tendered NOR”.",
    "- Should the vessel arrive ahead of agreed laycan, please tender NOR immediately upon arrival and retender immediately upon actual commencement of laycan and follow above NOR regime.",
  ];

  const specialAttentionList = [
    "a) Confirmation that our general TC Instructions have been duly read and understood ",
    "b) Daily ETA Notices to Centurion Bulk and agents, starting right now. ",
    "c) Stowage plan and DW calculation asap incl print outs from Loadicator for both departure load port and arrival discharge port as per general TC Instructions ",
    "d) Bunker requirement soonest incl attached Excel bunker tank overview.",
  ];

  return (
    <Box sx={{ padding: 2 }} id="text-content">
      <Typography variant="body1">To: Master, {vessel}</Typography>
      <Typography variant="body1">Cc: Owners, {vessel}</Typography>
      <Typography variant="body1">Fm: Centurion Bulk Pte Ltd</Typography>
      <TopPaddedText text="Dear Captain, Good day" />
      <TopPaddedText text="In addition to our general our General TC Instructions and details of the Head Fixture with your Owner, we are hereby pleased to send you voyage instructions for your next employment as follows:" />
      <Section title="1. General Voyage Details of the first employment">
        <Typography sx={{ mt: 2 }} variant="body1">
          - CP Date: {cpDate}
        </Typography>
        <DetailItem label="- Laycan" value={laycan} />
        <DetailItem label="- Cargo" value={cargo} />
        <DetailItem label="- Load Port" value={portOfLoading} />
        <DetailItem label="- Load Rate" value={loadRate} />
        <DetailItem label="- Discharge Port" value={portOfDischarge} />
        <DetailItem label="- Discharge Rate" value={dischargeRate} />
        <TopPaddedText text="Important:" />
        <ListSection items={importantList} />
        <TopPaddedText text="* * Insert/attach load- and discharge port information of available * *" />
        <TopPaddedText text="* * Insert any special provisions/mentioning of voyage if applicable * *" />
        <TopPaddedText text="* * Draft restrictions * *" />
      </Section>
      <Section title="2. Agents details as follows">
        <TopPaddedText text="Load Port Agents:" />
        <Typography variant="body1"> {loadingAgents} </Typography>
        <TopPaddedText text="Bunker Port Agents:" />
        <Typography variant="body1"> {missingValue} </Typography>
        <TopPaddedText text="Discharge Port Agents:" />
        <Typography variant="body1"> {dischargeAgents} </Typography>
        <TopPaddedText text="Important: We must be in copy of all correspondence with agents at all times. In the event that agents have not copied operations@centurionbulk.com to their email, please ensure that same email is forwarded to us. " />
      </Section>
      <Section title="3. Stowage Plan">
        <TopPaddedText text="Please provide us with vessels preliminary stowage plan basis information provided above and vessel max possible cargo intake including full DW calculation and printout from Loadicator for both departure load port and arrival discharge port. " />
        <TopPaddedText text="Kindly refer to our Time Charter Instructions for the required breakdown of vessels stowage plan. " />
      </Section>
      <Section title="4. Notices at load port, bunker and discharge port: ">
        <TopPaddedText text="Load:" />
        <Typography variant="body1">
          Please provide a daily ETA/readiness to following addresses:
        </Typography>
        <Typography variant="body1">operations@centurionbulk.com</Typography>
        <Typography variant="body1"> {brokersEmail} </Typography>
        <Typography variant="body1"> {loadAgentEmail} </Typography>
        <TopPaddedText text="Bunker port:" />
        <Typography variant="body1">operations@centurionbulk.com</Typography>
        <Typography variant="body1"> {missingValue} </Typography>
        <TopPaddedText text="Discharge:" />
        <Typography variant="body1">
          Please provide a daily ETA/readiness to following addresses:
        </Typography>
        <Typography variant="body1">operations@centurionbulk.com</Typography>
        <Typography variant="body1"> {brokersEmail} </Typography>
        <Typography variant="body1"> {dischargeAgentEmail} </Typography>
      </Section>
      <Section title="5. Bunker Planning">
        <TopPaddedText text={missingValue} />
        <TopPaddedText text="Please confirm full break down of all bunker tanks and present % filling " />
      </Section>
      <Section title="6. Voyage Planning">
        <TopPaddedText text="Fastest and most economical route in close cooperation with the Weather Routing Company appointed. " />
      </Section>
      <Section title="7. Cargo Care">
        <TopPaddedText text="Please obtain written instructions from agents and shippers regarding how to care for the cargo during the voyage until completely discharged - drainage log, ventilation, sealed hatches, cargo care etc. " />
      </Section>
      <Section title="8. Bills of Lading (Clean)">
        <TopPaddedText text="Clean Bill of Lading are required without any remarks. " />
        <TopPaddedText text="Vessel must reject any unsound cargo and immediately requests that same is replaced by sound cargo." />
      </Section>
      <Section title="9. Moisture Drainage from cargo holds (coal, sulphur and petcoke):">
        <TopPaddedText text="Vessel to pump out through vessels bilges in the holds, as necessary or at least 2 times per day, all drainage moisture form the cargo during transit voyage to discharge port(s). Master to keep written records daily of estimated quantity of moisture pumped overboard during the voyage and send same in Excel sheet format every day together with the noon report. Master to keep separate records daily of all the water or liquids pumped out/inboard during voyage. Upon arrival discharge port, before discharging commences, Master to email to Charterers and Voyage Charterers the final Excel sheet and certificate indicating the amount of daily drainage moisture pumped out/or on board such as ballast, etc. " />
      </Section>
      <Section title="10. De-ballast">
        <TopPaddedText text="In our experience dead ballast can be reduced significantly if you adjust the loading sequence so that vessel is trimming aft 2,5-3 meters in the fist stages of the loading and de-ballasting period. This way, you would be able to reduce dead ballast by stripping ballast tanks with a large trim to next to nothing - in this case less that a few tons in each tank. " />
        <TopPaddedText text="Of course this requires planning and attention to the matter, but we are sure you can undertake this task in a deeply professionally manner. " />
      </Section>
      <Section title="11. SOF">
        <TopPaddedText text='It is very important that the SOF is correct and in accordance with vessels logs, we kindly request you to thoroughly check the SOF presented by agents and send SOF to us for approval before signing SOF - Always sign SO:F ""FOR RECEIPT ONLY AND WITHOUT PREJUDICE"" and in case vessel stayed at anchor prior berthing ""Weather/ Swell/ winds/ rain while vessel waited at anchorage for a berth to become available, and their impact on cargo operations, are unknown to and not admitted by Carriers/ Vessel/ Master."" ' />
        <TopPaddedText text="AND REFERENCES TO HOLIDAYS IN THE SOF HAVE NOT BEEN VERIFIED AND ARE NOT ADMITTED BY OWNERS" />
        <Typography sx={{ mt: 2, textDecoration: "underline" }} variant="body1">
          Important for signing SOF:
        </Typography>
        <TopPaddedText text="The Master is ONLY to sign 1 (one) set of SOF! " />
        <TopPaddedText text="The validated and agreed SOF between Centurion Bulk and Master need to be signed by Master and once signed, Master will have to send the SOF to Centurion Bulk for our files. " />
        <TopPaddedText text="Please also refer to General Instruction. " />
      </Section>
      <Section title="12. Notice of Readiness (NOR)">
        <ListSection items={norList} />
        <TopPaddedText text="Please also include total cargo quantity to be loaded/discharged in the notice of readiness after consulting with us for maximum cargo intake. " />
        <TopPaddedText text="At first opportunity, Please hand over to agents your “confirmation of notice of readiness” in writing and showing the same date and time as the cable you have dispatched. Regarding date / time of acceptance, we would like to ask you for the following remark always to be inserted be agents / shippers / receivers “To be accepted as per the provisions of the charter party”" />
      </Section>
      <Section title="13. IMPORTANT ">
        <TopPaddedText text="In our general Time Charter Instructions attached, you will find all information required in regards to vessels stowage, reporting formats, NOR, SOF, Bunkering procedures etc. " />
        <TopPaddedText text="Please take your time to carefully read through these instructions and do not hesitate to contact us should you require further clarifications " />
      </Section>
      <TopPaddedText text="Therefore kindly confirm safe receipt of these instructions together with:" />
      <ListSection items={specialAttentionList} />
      <TopPaddedText text="Thank you very much in advance." />
    </Box>
  );
};
