import React, { useState, useContext } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Collapse,
  Alert,
  Grid2,
} from "@mui/material";
import { compareCargoPdfs } from "../../services/cargoService";
import Feedback from "../../components/common/Feedback";
import CargoResult from "./CargoResult";
import { UserContext } from "../../context/UserContext";
import TwoDocumentUpload from "../../components/common/TwoDocumentUpload";
import DocumentUploader from "../../components/common/DocumentUploader";
import ToolHeader from "../../components/common/ToolHeader";
import ToolStepProgressBar from "../../components/common/ToolStepProgressBar";
import Step1Buttons from "../../components/common/Step1Buttons";
import CloseFooter from "../../components/common/CloseFooter";
import { getOrganisationFromUser } from "../../utils/organisationUtils";
import LoadingOverlay from "../../components/common/LoadingOverlay";
import {
  pdfDocxFileTypes,
  pdfXlsxFileTypes,
  getToolMessages,
} from "../../utils/loaderUtils";

const CargoComparison = () => {
  const [billOfLadings, setBillOfLadings] = useState([]);
  const [voyageManifests, setVoyageManifests] = useState([]);
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const [useOCR, setUseOCR] = useState(true);
  const [useVision, setUseVision] = useState(true);
  const [useContentCache, setUseContentCache] = useState(true);
  const [useCargoCache, setUseCargoCache] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [result, setResult] = useState(null);
  const { user } = useContext(UserContext);
  const steps = ["Upload & Compare", "Results"];
  const [currentStep, setCurrentStep] = useState(0);

  const handleStepChange = (step) => {
    if (step <= currentStep) setCurrentStep(step);
  };

  const handleAdvancedOptionsClick = () => {
    setShowAdvancedOptions(!showAdvancedOptions);
  };

  const handleCompare = async () => {
    if (voyageManifests.length === 0 || billOfLadings.length === 0) {
      alert("Please upload file(s) in both sections before comparing.");
      return;
    }

    setLoading(true);
    setError(null); // Clear any previous errors
    setResult(null); // Clear previous result

    try {
      const response = await compareCargoPdfs(billOfLadings, voyageManifests, {
        useOCR,
        useVision,
        useContentCache,
        useCargoCache,
        organisationId: getOrganisationFromUser(user),
      });
      setResult(response); // Store the result
      setCurrentStep(1);
      console.log(response);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box p={3}>
      <ToolHeader
        title={"Voyage Manifest -> draft B/L"}
        subtext={"Compare cargo from Voyage Manifest with draft Bill of Lading"}
      ></ToolHeader>
      <ToolStepProgressBar
        steps={steps}
        currentStep={currentStep}
        onStepChange={handleStepChange}
      />

      {(!result || currentStep === 0) && (
        <>
          {error && (
            <Alert severity="error" sx={{ my: 2 }}>
              {error}
            </Alert>
          )}

          <TwoDocumentUpload>
            <Grid2 size={6}>
              <DocumentUploader
                files={billOfLadings}
                setFiles={setBillOfLadings}
                title="Upload one or more Bill of Ladings PDF(s)"
                setResult={setResult}
                allowedFileTypes={pdfXlsxFileTypes}
                singleFile={false}
              />
            </Grid2>

            <Grid2 size={6}>
              <DocumentUploader
                files={voyageManifests}
                setFiles={setVoyageManifests}
                title="Upload one or more Voyage Manifest PDF(s)"
                setResult={setResult}
                allowedFileTypes={pdfDocxFileTypes}
                singleFile={false}
              />
            </Grid2>
          </TwoDocumentUpload>

          {user.isAdmin && (
            <>
              <Button
                variant="outlined"
                fullWidth
                onClick={handleAdvancedOptionsClick}
                sx={{ mt: 3 }}
              >
                {showAdvancedOptions
                  ? "Hide Advanced Options"
                  : "Show Advanced Options"}
              </Button>
              <Collapse in={showAdvancedOptions}>
                <Box sx={{ border: "1px solid #ccc", padding: "10px", mt: 2 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={useOCR}
                        onChange={(e) => setUseOCR(e.target.checked)}
                      />
                    }
                    label="Use OCR"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={useVision}
                        onChange={(e) => setUseVision(e.target.checked)}
                      />
                    }
                    label="Use Vision"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={useContentCache}
                        onChange={(e) => setUseContentCache(e.target.checked)}
                      />
                    }
                    label="Use Content Cache"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={useCargoCache}
                        onChange={(e) => setUseCargoCache(e.target.checked)}
                      />
                    }
                    label="Use Cargo Cache"
                  />
                </Box>
              </Collapse>
            </>
          )}

          {/* Compare Button */}
          <Step1Buttons
            title={"Compare"}
            validation={
              voyageManifests?.length === 0 ||
              billOfLadings?.length === 0 ||
              loading
            }
            handleMoveNextStep={handleCompare}
          />

          {loading && (
            <LoadingOverlay messages={getToolMessages["CARGO_COMPARISON"]} />
          )}
        </>
      )}
      {result && currentStep === 1 && (
        <>
          <CargoResult
            response={result}
            files={{
              billOfLadings,
              voyageManifests,
            }}
          />
          <Feedback requestId={result.request_id} type={"Cargo"}></Feedback>
          <CloseFooter />
        </>
      )}
    </Box>
  );
};

export default CargoComparison;
