import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Divider,
  Button,
  useTheme,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import mammoth from "mammoth";
import * as XLSX from "xlsx";
import PropTypes from "prop-types";

const DocumentViewer = ({ file }) => {
  const fileArray = useMemo(() => {
    if (!file) return [];
    return Array.isArray(file) ? file : [file];
  }, [file]);

  const [docxContent, setDocxContent] = useState({});
  const [excelData, setExcelData] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const theme = useTheme();

  useEffect(() => {
    const convertFiles = async () => {
      const newDocxContent = {};
      const newExcelData = {};

      for (let i = 0; i < fileArray.length; i++) {
        const file = fileArray[i];

        // Convert DOCX to HTML
        if (
          file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ) {
          const arrayBuffer = await file.arrayBuffer();
          const { value } = await mammoth.convertToHtml({ arrayBuffer });
          newDocxContent[i] = value;
        }

        // Convert Excel to JSON
        if (
          file.type ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
          file.type === "application/vnd.ms-excel"
        ) {
          const arrayBuffer = await file.arrayBuffer();
          const workbook = XLSX.read(arrayBuffer, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          newExcelData[i] = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        }
      }

      setDocxContent(newDocxContent);
      setExcelData(newExcelData);
    };

    if (fileArray.length > 0) convertFiles();
  }, [fileArray]);

  useEffect(() => {
    if (currentIndex >= fileArray.length) {
      setCurrentIndex(0);
    }
  }, [fileArray, currentIndex]);

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + 1, fileArray.length - 1)
    );
  };

  const currentFile = fileArray[currentIndex];

  return (
    <Box>
      {fileArray.length > 0 ? (
        <Box sx={{ mb: 4 }}>
          <Box
            sx={{
              background: "#1A1A21",
              borderRadius: "8px 8px 0px 0px",
              padding: "12px 24px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="body1" gutterBottom>
              {currentFile?.name}
            </Typography>
            {fileArray.length > 1 && (
              <Box>
                <Button
                  onClick={handlePrevious}
                  disabled={currentIndex === 0}
                  sx={{ background: theme.palette.primary.main }}
                  size="small"
                >
                  <ArrowBackIcon />
                </Button>
                <Typography
                  component="span"
                  variant="body2"
                  sx={{ mx: 1 }}
                >{`Document ${currentIndex + 1} / ${fileArray.length}`}</Typography>
                <Button
                  onClick={handleNext}
                  disabled={currentIndex === fileArray.length - 1}
                  sx={{ background: theme.palette.primary.main }}
                  size="small"
                >
                  <ArrowForwardIcon />
                </Button>
              </Box>
            )}
          </Box>
          <Divider
            sx={{ backgroundColor: theme.palette.primary.main }}
          ></Divider>
          {typeof currentFile === "string" ? (
            <TextField
              value={currentFile}
              multiline
              rows={25}
              fullWidth
              slotProps={{
                input: {
                  readOnly: true,
                },
              }}
              sx={{
                borderRadius: "4px",
              }}
            />
          ) : currentFile?.type === "application/pdf" ? (
            <iframe
              title={`PDF Viewer ${currentIndex + 1}`}
              src={URL.createObjectURL(currentFile)}
              width="100%"
              height="500px"
            />
          ) : currentFile?.type ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
            <div
              dangerouslySetInnerHTML={{
                __html: docxContent[currentIndex] || "Loading...",
              }}
              style={{
                padding: "16px",
                height: "500px",
                overflowY: "auto",
                background: "#FFFFFF",
                color: "#000000",
              }}
            />
          ) : currentFile?.type ===
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
            currentFile?.type === "application/vnd.ms-excel" ? (
            <TableContainer
              component={Paper}
              sx={{ maxHeight: 500, overflow: "auto" }}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {excelData[currentIndex]?.[0]?.map((cell, cellIndex) => (
                      <TableCell key={cellIndex}>{cell || "Header"}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {excelData[currentIndex]?.slice(1).map((row, rowIndex) => (
                    <TableRow key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <TableCell key={cellIndex}>{cell}</TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography color="error">Unsupported file type</Typography>
          )}
        </Box>
      ) : (
        <Typography>No document selected</Typography>
      )}
    </Box>
  );
};

DocumentViewer.propTypes = {
  file: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]).isRequired,
};
export default DocumentViewer;
