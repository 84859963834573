import React, { useState, useEffect, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Card,
  CardContent,
  Typography,
  IconButton,
  Grid2,
  Collapse,
  useTheme,
} from "@mui/material";
import {
  borderBRStyle,
  borderStyle,
  boldHeaderStyle,
} from "../../styles/comparisonTableStyles";
import PropTypes from "prop-types";
import VarianceSummary from "../../components/comparison/VarianceSummary";
import ShipsterCard from "../../components/common/ShipsterCard";
import ComparisonTableActions from "../../components/comparison/ComparisonTableActions";
import ComparisonDetails from "../../components/comparison/ComparisonDetails";
import NoDataMessage from "../../components/comparison/NoDataMessage";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import {
  exportToExcelCargo,
  copyToClipboardCargo,
  exportToExcelCargoTotals,
  copyToClipboardCargoTotals,
} from "../../helpers";

const columnWidth = `${100 / 7}%`;

const CargoResult = ({ response, files }) => {
  const [cargoComparisonResult, setCargoComparisonResult] = useState(null);
  const [filteredRows, setFilteredRows] = useState([]);
  const [totalsfilteredRows, setTotalsFilteredRows] = useState([]);
  const [filters, setFilters] = useState({
    filter: "all",
    totalsFilter: "all",
  });

  const handleFilterChange = (key) => (event) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: event.target.value,
    }));
  };

  useEffect(() => {
    setCargoComparisonResult(response);
  }, [response]);

  if (!cargoComparisonResult) return null;
  const { comparison_result } = cargoComparisonResult;
  const fileNames = files.billOfLadings.map((file) => file.name);

  const handleCopy = () => {
    copyToClipboardCargo(filteredRows, comparison_result);
  };

  const handleSavePdf = () => {
    alert("Save as PDF still in development!");
  };

  const handleSaveExcel = () => {
    exportToExcelCargo(filteredRows, comparison_result);
  };

  const handleCopyTotals = () => {
    copyToClipboardCargoTotals(totalsfilteredRows, fileNames);
  };

  const handleSaveExcelTotals = () => {
    exportToExcelCargoTotals(totalsfilteredRows, fileNames);
  };

  return (
    <Box>
      <VarianceSummary
        summary={comparison_result.cargo_comparison.variance_summary}
      />
      <ShipsterCard level={1} title="Comparison Results">
        <ComparisonDetails>
          <CargoDetails
            details={comparison_result.first_set_of_documents}
          ></CargoDetails>
          <CargoDetails
            details={comparison_result.second_set_of_documents}
          ></CargoDetails>
        </ComparisonDetails>
        <ComparisonTableActions
          filter={filters.filter}
          filterOptions={[
            { value: "all", label: "Show All" },
            { value: "discrepancies", label: "Show Only Discrepancies" },
            { value: "missing", label: "Show Only Missing" },
          ]}
          onFilterChange={handleFilterChange("filter")}
          onCopy={handleCopy}
          onSavePdf={handleSavePdf}
          onSaveExcel={handleSaveExcel}
        />
        <CargoTable
          comparisonResult={comparison_result}
          filter={filters.filter}
          filteredRows={filteredRows}
          setFilteredRows={setFilteredRows}
        />
        <ComparisonTableActions
          filter={filters.totalsFilter}
          filterOptions={[
            { value: "all", label: "Show All" },
            { value: "discrepancies", label: "Show Only Discrepancies" },
          ]}
          onFilterChange={handleFilterChange("totalsFilter")}
          onCopy={handleCopyTotals}
          onSavePdf={handleSavePdf}
          onSaveExcel={handleSaveExcelTotals}
        />
        <CargoTotalsTable
          comparisonResult={comparison_result}
          filter={filters.totalsFilter}
          filteredRows={totalsfilteredRows}
          setFilteredRows={setTotalsFilteredRows}
          fileNames={fileNames}
        />
      </ShipsterCard>
    </Box>
  );
};

CargoResult.propTypes = {
  response: PropTypes.object.isRequired,
  files: PropTypes.shape({
    billOfLadings: PropTypes.arrayOf(PropTypes.instanceOf(File)),
    voyageManifests: PropTypes.arrayOf(PropTypes.instanceOf(File)),
  }).isRequired,
};

const CargoTable = ({
  comparisonResult,
  filter,
  filteredRows,
  setFilteredRows,
}) => {
  const theme = useTheme();

  useEffect(() => {
    const applyFilter = () => {
      // Get all lots from cargo comparison
      const comparisonLots = new Set(
        comparisonResult.cargo_comparison.lot_comparisons.map((row) => row.lot)
      );

      const allVMLotDetails = comparisonResult.combined_second_set.lot_details;
      const allBLLotDetails = comparisonResult.combined_first_set.lot_details;

      // Create additional rows for lots that only appear in VM or BL
      // Get lots that only appear in VM or BL
      const additionalRows = [
        ...allVMLotDetails
          .filter((vmLot) => !comparisonLots.has(vmLot.lot))
          .map((vmLot) => ({
            lot: vmLot.lot,
            quantity_metrics_variance: [],
            missing_from: "BL", // This lot only exists in VM, so it's missing from BL
          })),
        ...allBLLotDetails
          .filter((blLot) => !comparisonLots.has(blLot.lot))
          .map((blLot) => ({
            lot: blLot.lot,
            quantity_metrics_variance: [],
            missing_from: "VM", // This lot only exists in BL, so it's missing from VM
          })),
      ];

      // Combine original comparison rows with additional rows
      const allRows = [
        ...comparisonResult.cargo_comparison.lot_comparisons,
        ...additionalRows,
      ];

      return allRows.filter((row) => {
        const voyageManifestRow = allVMLotDetails.find(
          (vm) => vm.lot === row.lot
        );
        const billLadingRow = allBLLotDetails.find((bl) => bl.lot === row.lot);

        const missing =
          !voyageManifestRow || !billLadingRow || row.missing_from;

        const hasChangedVariance = row.quantity_metrics_variance?.some((m) => {
          const variance = m.variance;
          return checkForChanged(variance);
        });

        if (filter === "discrepancies") {
          return hasChangedVariance;
        } else if (filter === "missing") {
          return missing;
        }
        return true;
      });
    };

    setFilteredRows(applyFilter());
  }, [comparisonResult, filter, setFilteredRows]);

  const allVMLotDetails = comparisonResult.combined_second_set.lot_details;
  const allBLLotDetails = comparisonResult.combined_first_set.lot_details;

  const checkForChanged = (significance) => {
    return significance === "MATERIAL_VARIANCE";
  };

  if (filteredRows.length === 0) {
    return <NoDataMessage filter={filter} />;
  }

  return (
    <TableContainer
      style={{
        backgroundColor: theme.palette.background.input,
        maxHeight: 800,
        overflowY: "auto",
      }}
    >
      <Table stickyHeader>
        <TableHead sx={{ position: "sticky", top: 0, zIndex: 1 }}>
          <TableRow>
            <TableCell style={{ ...boldHeaderStyle(theme) }} />
            <TableCell
              style={{
                ...boldHeaderStyle(theme),
                textAlign: "center",
              }}
              colSpan={5}
            >
              {comparisonResult.combined_first_set.short_name}
            </TableCell>

            <TableCell style={{ ...boldHeaderStyle(theme) }} />

            <TableCell
              style={{
                ...boldHeaderStyle(theme),
                textAlign: "center",
              }}
              colSpan={5}
            >
              {comparisonResult.combined_second_set.short_name}
            </TableCell>

            <TableCell style={{ ...boldHeaderStyle(theme) }} />
          </TableRow>
          <TableRow>
            <TableCell style={borderBRStyle(theme)}>LOT</TableCell>
            {comparisonResult.combined_first_set.lot_details[0].quantity_metrics.map(
              (metric) => {
                return (
                  <TableCell style={borderStyle(theme)} key={metric.metric}>
                    {metric.metric}
                  </TableCell>
                );
              }
            )}
            <TableCell style={borderStyle(theme)}>Description</TableCell>
            <TableCell style={borderStyle(theme)}>Port of Loading</TableCell>
            <TableCell style={borderStyle(theme)}>Port of Discharge</TableCell>
            <TableCell style={borderBRStyle(theme)}>Page</TableCell>
            {comparisonResult.combined_second_set.lot_details[0].quantity_metrics.map(
              (metric) => {
                return (
                  <TableCell
                    style={borderStyle(theme)}
                    key={`table cell: ${metric.metric}`}
                  >
                    {metric.metric}
                  </TableCell>
                );
              }
            )}
            <TableCell style={borderStyle(theme)}>Description</TableCell>
            <TableCell style={borderStyle(theme)}>Port of Loading</TableCell>
            <TableCell style={borderStyle(theme)}>Port of Discharge</TableCell>
            <TableCell style={borderStyle(theme)}>Page</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredRows.map((lot, index) => {
            const voyageManifestRow = allVMLotDetails.find(
              (vm) => vm.lot === lot.lot
            );
            const billLadingRow = allBLLotDetails.find(
              (bl) => bl.lot === lot.lot
            );

            const quantityMetricsVM = voyageManifestRow?.quantity_metrics || [];
            const quantityMetricsBL = billLadingRow?.quantity_metrics || [];

            const missing = !voyageManifestRow || !billLadingRow;
            const hasVariance =
              lot.quantity_metrics_variance?.some(
                (m) =>
                  quantityMetricsVM.some(
                    (metric) =>
                      m.metric === metric.metric ||
                      (Array.isArray(m.metric_synonyms) &&
                        m.metric_synonyms.includes(metric.metric))
                  ) && checkForChanged(m.variance)
              ) ||
              lot.quantity_metrics_variance?.some(
                (m) =>
                  quantityMetricsBL.some(
                    (metric) =>
                      m.metric === metric.metric ||
                      (Array.isArray(m.metric_synonyms) &&
                        m.metric_synonyms.includes(metric.metric))
                  ) && checkForChanged(m.variance)
              );

            let textColor = "inherit";
            // Changes color of text based on severity incase of overlap between missing and variance , missing is prioritized
            if (missing) {
              textColor = theme.palette.semantic.error;
            } else if (hasVariance) {
              textColor = theme.palette.semantic.warning;
            }

            return (
              <TableRow key={`row: ${index}`}>
                <TableCell
                  style={{
                    color: textColor,
                    borderRight: `2px solid ${theme.palette.primary.main}`,
                  }}
                >
                  {lot.lot}
                </TableCell>
                {quantityMetricsBL.length === 0
                  ? Array.from({
                      length:
                        comparisonResult.first_set_of_documents[0]
                          .lot_details[0].quantity_metrics.length,
                    }).map((_, i) => (
                      <TableCell
                        key={`bl-missing-${i}`}
                        style={{
                          color: textColor,
                        }}
                      >
                        -
                      </TableCell>
                    ))
                  : quantityMetricsBL.map((metric, metricIndex) => {
                      return (
                        <TableCell
                          key={`metric-${metric.metric}-${metricIndex}`}
                          style={{
                            color: textColor,
                          }}
                        >
                          {metric.value}
                        </TableCell>
                      );
                    })}
                <TableCell style={{ color: textColor }}>
                  {billLadingRow?.description || "-"}
                </TableCell>
                <TableCell style={{ color: textColor }}>
                  {billLadingRow?.port_of_loading || "-"}
                </TableCell>
                <TableCell style={{ color: textColor }}>
                  {billLadingRow?.port_of_discharge || "-"}
                </TableCell>
                <TableCell
                  style={{
                    color: textColor,
                    borderRight: `2px solid ${theme.palette.primary.main}`,
                  }}
                >
                  {billLadingRow?.page || "-"}
                </TableCell>
                {quantityMetricsVM.length === 0
                  ? Array.from({
                      length:
                        comparisonResult.second_set_of_documents[0]
                          .lot_details[0].quantity_metrics.length,
                    }).map((_, i) => (
                      <TableCell
                        key={`vm-missing-${i}`}
                        style={{
                          color: textColor,
                        }}
                      >
                        -
                      </TableCell>
                    ))
                  : quantityMetricsVM.map((metric, metricIndex) => (
                      <TableCell
                        key={`metric-${metric.metric}-${metricIndex}`}
                        style={{
                          color: textColor,
                        }}
                      >
                        {metric.value}
                      </TableCell>
                    ))}
                <TableCell style={{ color: textColor }}>
                  {voyageManifestRow?.description || "-"}
                </TableCell>
                <TableCell style={{ color: textColor }}>
                  {voyageManifestRow?.port_of_loading || "-"}
                </TableCell>
                <TableCell style={{ color: textColor }}>
                  {voyageManifestRow?.port_of_discharge || "-"}
                </TableCell>
                <TableCell style={{ color: textColor }}>
                  {voyageManifestRow?.page || "-"}
                </TableCell>
              </TableRow>
            );
          })}

          {/* Totals Row */}
          <TableRow>
            <TableCell
              style={{
                fontWeight: "bold",
                borderRight: `2px solid ${theme.palette.primary.main}`,
                borderTop: `2px solid ${theme.palette.primary.main}`,
              }}
            >
              TOTAL
            </TableCell>
            {comparisonResult.combined_first_set.total_quantity_metrics.map(
              (metric, index) => (
                <TableCell
                  key={`total-bl-${index}`}
                  style={{
                    fontWeight: "bold",
                    borderTop: `2px solid ${theme.palette.primary.main}`,
                  }}
                >
                  {metric.value}
                </TableCell>
              )
            )}
            <TableCell
              style={{
                fontWeight: "bold",
                borderTop: `2px solid ${theme.palette.primary.main}`,
              }}
              colSpan={3}
            ></TableCell>
            <TableCell
              style={{
                fontWeight: "bold",
                borderRight: `2px solid ${theme.palette.primary.main}`,
                borderTop: `2px solid ${theme.palette.primary.main}`,
              }}
            ></TableCell>
            {comparisonResult.combined_second_set.total_quantity_metrics.map(
              (metric, index) => (
                <TableCell
                  key={`total-vm-${index}`}
                  style={{
                    fontWeight: "bold",
                    borderTop: `2px solid ${theme.palette.primary.main}`,
                  }}
                >
                  {metric.value}
                </TableCell>
              )
            )}
            <TableCell
              style={{
                fontWeight: "bold",
                borderTop: `2px solid ${theme.palette.primary.main}`,
              }}
              colSpan={4}
            ></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
CargoTable.propTypes = {
  comparisonResult: PropTypes.object.isRequired,
  filter: PropTypes.string.isRequired,
  filteredRows: PropTypes.array.isRequired,
  setFilteredRows: PropTypes.func.isRequired,
};

const CargoDetails = ({ details }) => {
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Card
      sx={{
        background: theme.palette.background.level2,
        borderRadius: "8px",
        boxShadow: "0px 4px 8px 0px rgba(38, 40, 44, 0.08)",
      }}
    >
      <CardContent>
        <Typography
          variant="body1"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontWeight: 500,
            marginBottom: isExpanded ? 1 : 0,
            borderBottom: "1px solid #3D86F6",
          }}
        >
          {details[0].short_name}
          <IconButton size="small" onClick={toggleExpand}>
            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Typography>
        {isExpanded && (
          <Box>
            <Grid2 container spacing={2}>
              <Grid2 size={4}>
                <Typography
                  variant="body1"
                  sx={{ color: theme.palette.primary.blue3 }}
                >
                  Vessel
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: theme.palette.primary.blue3 }}
                >
                  Date
                </Typography>
              </Grid2>
              <Grid2 size={8}>
                <Typography
                  variant="body1"
                  sx={{ color: theme.palette.text.main }}
                >
                  {details[0].vessel}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: theme.palette.text.main }}
                >
                  {details[0].date}
                </Typography>
              </Grid2>
            </Grid2>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

CargoDetails.propTypes = {
  details: PropTypes.array.isRequired, // Define the type of response, e.g., object, string, etc.
};

const CargoTotalsTable = ({
  comparisonResult,
  filter,
  filteredRows,
  setFilteredRows,
  fileNames,
}) => {
  const theme = useTheme();

  useEffect(() => {
    const applyFilter = () => {
      return comparisonResult.cargo_comparison.totals_comparison.filter(
        (row) => {
          if (filter === "discrepancies") {
            // Check if any of the differences has a value greater than 0
            return row.differences.some((diff) => diff.difference !== 0);
          }
          return true; // Default: show all
        }
      );
    };

    setFilteredRows(applyFilter());
  }, [comparisonResult, filter, setFilteredRows]);

  if (filteredRows.length === 0) {
    return <NoDataMessage filter={filter} />;
  }

  const BLMetrics = new Set();
  filteredRows.forEach((row) => {
    row.bill_of_lading_totals.forEach((metric) => BLMetrics.add(metric.metric));
  });
  const VMMetrics = new Set();
  filteredRows.forEach((row) => {
    row.voyage_manifest_calculated_totals.forEach((metric) =>
      VMMetrics.add(metric.metric)
    );
  });
  const BLMetricNames = Array.from(BLMetrics);
  const VMMetricNames = Array.from(VMMetrics);

  return (
    <TableContainer
      style={{
        backgroundColor: theme.palette.background.input,
        maxHeight: 800,
        overflowY: "auto",
      }}
    >
      <Table stickyHeader>
        <TableHead sx={{ position: "sticky", top: 0, zIndex: 1 }}>
          <TableRow>
            <TableCell
              style={{ ...boldHeaderStyle(theme), width: columnWidth }}
            />
            <TableCell
              style={{
                ...boldHeaderStyle(theme),
                textAlign: "center",
                width: columnWidth,
              }}
              colSpan={2}
            >
              Bill of Lading
            </TableCell>
            <TableCell
              style={{
                ...boldHeaderStyle(theme),
                textAlign: "center",
                width: columnWidth,
              }}
              colSpan={2}
            >
              Voyage Manifest
            </TableCell>
            <TableCell
              style={{
                ...boldHeaderStyle(theme),
                textAlign: "center",
                width: columnWidth,
              }}
              colSpan={2}
            >
              Difference
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ ...borderBRStyle(theme), width: columnWidth }}>
              Bill Of Lading
            </TableCell>
            {BLMetricNames.map((metric, i) => (
              <TableCell
                key={`BL-header-${i}`}
                style={{
                  ...(i === BLMetricNames.length - 1
                    ? borderBRStyle(theme)
                    : borderStyle(theme)),
                  width: columnWidth,
                }}
              >
                Extracted {metric}
              </TableCell>
            ))}
            {VMMetricNames.map((metric, i) => (
              <TableCell
                key={`VM-header-${i}`}
                style={{
                  ...(i === VMMetricNames.length - 1
                    ? borderBRStyle(theme)
                    : borderStyle(theme)),
                  width: columnWidth,
                }}
              >
                <AutoAwesomeIcon sx={{ fontSize: 12 }} /> Calculated {metric}
              </TableCell>
            ))}
            <TableCell style={{ ...borderStyle(theme), width: columnWidth }}>
              Quantity
            </TableCell>
            <TableCell style={{ ...borderStyle(theme), width: columnWidth }}>
              Volume
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredRows.map((row, rowIndex) => (
            <CollapsibleRow
              key={`collaspable row: ${rowIndex}`}
              row={row}
              rowIndex={rowIndex}
              metricNames={BLMetricNames}
              fileNames={fileNames}
              comparisonResult={comparisonResult}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

CargoTotalsTable.propTypes = {
  comparisonResult: PropTypes.object.isRequired,
  filter: PropTypes.string.isRequired,
  filteredRows: PropTypes.array.isRequired,
  setFilteredRows: PropTypes.func.isRequired,
  fileNames: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const CollapsibleRow = ({
  rowIndex,
  row,
  fileNames,
  metricNames,
  comparisonResult,
}) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const billOfLadingTotals = row.bill_of_lading_totals;
  const voyageManifestTotals = row.voyage_manifest_calculated_totals;
  const differences = row.differences;
  const fileName = fileNames[rowIndex];
  const blLots = comparisonResult.first_set_of_documents[rowIndex].lot_details;

  const hasDiscrepancy = useMemo(
    () => differences.some((metric) => metric.difference !== 0),
    [differences]
  );

  // Utility function: Get metric value difference
  const getMetricValue = (metrics, metricName) =>
    metrics.find((item) => item.metric === metricName)?.difference ?? 0;

  const mapMetrics = (lot) => {
    if (!lot) return {};

    const metrics = {};
    lot.deck_quantity_metrics?.forEach(({ metric, value }) => {
      metrics[metric] = { Deck: value, Hold: 0, Total: value };
    });

    lot.hold_quantity_metrics?.forEach(({ metric, value }) => {
      if (!metrics[metric]) {
        metrics[metric] = { Deck: 0, Hold: value, Total: value };
      } else {
        metrics[metric].Hold = value;
        metrics[metric].Total = metrics[metric].Deck + value;
      }
    });

    lot.quantity_metrics?.forEach(({ metric, value }) => {
      if (!metrics[metric]) {
        metrics[metric] = { Deck: 0, Hold: 0, Total: value };
      } else {
        metrics[metric].Total = value;
      }
    });

    return metrics;
  };

  // Utility function: Calculate metric differences
  const calculateMetricDifferences = (mappedMetrics, comparisonMetrics) => {
    const piecesComparison = comparisonMetrics?.[0]?.value || 0;
    const volumeComparison = comparisonMetrics?.[1]?.value || 0;

    // If mappedMetrics is empty (no VM lot), create difference object using BL values
    if (Object.keys(mappedMetrics).length === 0) {
      return comparisonMetrics.reduce((acc, metric) => {
        const value = metric.value || 0;
        acc[metric.metric] = {
          Deck: value,
          Hold: value,
          Total: value,
        };
        return acc;
      }, {});
    }

    return Object.keys(mappedMetrics).reduce((acc, metric, index) => {
      const comparisonValue = index === 0 ? piecesComparison : volumeComparison;
      acc[metric] = {
        Deck: Number((comparisonValue - mappedMetrics[metric].Deck).toFixed(3)),
        Hold: Number((comparisonValue - mappedMetrics[metric].Hold).toFixed(3)),
        Total: Number(
          (comparisonValue - mappedMetrics[metric].Total).toFixed(3)
        ),
      };
      return acc;
    }, {});
  };

  const calculateDifferencesByIndex = (obj1, obj2 = []) =>
    obj1.map((item, index) => ({
      metric: item.metric,
      difference: item.value - (obj2[index]?.value || 0),
    }));

  return (
    <>
      {/* Main Row */}
      <TableRow>
        <TableCell
          style={{
            width: columnWidth,
            borderRight: `1px solid ${theme.palette.primary.main}`,
          }}
        >
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
          #{rowIndex + 1} - {fileName}
        </TableCell>

        {billOfLadingTotals.map((metric, i) => (
          <TableCell
            key={i}
            style={{
              borderRight:
                i === billOfLadingTotals.length - 1
                  ? `1px solid ${theme.palette.primary.main}`
                  : "none",
              width: columnWidth,
            }}
          >
            {metric.value}
          </TableCell>
        ))}

        {voyageManifestTotals.map((metric, i) => (
          <TableCell
            key={i}
            style={{
              borderRight:
                i === voyageManifestTotals.length - 1
                  ? `1px solid ${theme.palette.primary.main}`
                  : "none",
              width: columnWidth,
            }}
          >
            {metric.value}
          </TableCell>
        ))}

        {metricNames.map((metric, i) => (
          <TableCell
            key={i}
            style={{
              color: hasDiscrepancy ? theme.palette.semantic.error : "inherit",
              width: columnWidth,
            }}
          >
            {getMetricValue(differences, metric)}
          </TableCell>
        ))}
      </TableRow>

      {/* Collapsible Sub-Items Row */}
      <TableRow>
        <TableCell colSpan={7} style={{ padding: 0 }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small" style={{ width: "100%" }}>
              <TableBody>
                {blLots.map((blLot, index) => {
                  const matchingVMLot =
                    comparisonResult.second_set_of_documents
                      .flatMap((doc) => doc.lot_details)
                      .find((l) => l.lot === blLot.lot) || null;

                  const hasDeckAndHold =
                    matchingVMLot &&
                    matchingVMLot.deck_quantity_metrics?.length > 0 &&
                    matchingVMLot.hold_quantity_metrics?.length > 0;

                  return (
                    <TableRow key={index}>
                      <TableCell
                        style={{
                          width: columnWidth,
                          borderRight: `1px solid ${theme.palette.primary.main}`,
                          color: !matchingVMLot
                            ? theme.palette.semantic.error
                            : "inherit",
                        }}
                      >
                        {blLot.lot}
                      </TableCell>

                      {/* BL Metrics */}
                      {blLot.quantity_metrics.map((metric, metricIndex) => (
                        <TableCell
                          key={metricIndex}
                          style={{
                            width: columnWidth,
                            borderRight:
                              metricIndex === blLot.quantity_metrics.length - 1
                                ? `1px solid ${theme.palette.primary.main}`
                                : "none",
                          }}
                        >
                          {metric.value}
                        </TableCell>
                      ))}

                      {/* VM Metrics and Differences */}
                      {hasDeckAndHold ? (
                        <>
                          {Object.values(mapMetrics(matchingVMLot)).map(
                            (values, metricIndex) => (
                              <TableCell
                                key={metricIndex}
                                style={{
                                  width: columnWidth,
                                  whiteSpace: "pre-line",
                                  borderRight:
                                    metricIndex ===
                                    matchingVMLot.quantity_metrics.length - 1
                                      ? `1px solid ${theme.palette.primary.main}`
                                      : "none",
                                }}
                              >
                                Total: {values.Total}
                                {"\n"}
                                Deck: {values.Deck}
                                {"\n"}
                                Hold: {values.Hold}
                              </TableCell>
                            )
                          )}

                          {Object.values(
                            calculateMetricDifferences(
                              mapMetrics(matchingVMLot),
                              blLot.quantity_metrics
                            )
                          ).map((differences, metricIndex) => (
                            <TableCell
                              key={metricIndex}
                              style={{
                                width: columnWidth,
                              }}
                            >
                              <Typography
                                sx={{
                                  color:
                                    differences.Total !== 0
                                      ? theme.palette.semantic.error
                                      : "inherit",
                                  fontSize: "0.875rem",
                                }}
                              >
                                Total: {differences.Total}
                              </Typography>
                              <Typography
                                sx={{
                                  color:
                                    differences.Deck !== 0
                                      ? theme.palette.semantic.error
                                      : "inherit",
                                  fontSize: "0.875rem",
                                }}
                              >
                                Deck: {differences.Deck}
                              </Typography>
                              <Typography
                                sx={{
                                  color:
                                    differences.Hold !== 0
                                      ? theme.palette.semantic.error
                                      : "inherit",
                                  fontSize: "0.875rem",
                                }}
                              >
                                Hold: {differences.Hold}
                              </Typography>
                            </TableCell>
                          ))}
                        </>
                      ) : (
                        <>
                          {/* VM Metrics */}
                          {blLot.quantity_metrics.map((_, metricIndex) => (
                            <TableCell
                              key={metricIndex}
                              style={{
                                width: columnWidth,
                                borderRight:
                                  metricIndex ===
                                  blLot.quantity_metrics.length - 1
                                    ? `1px solid ${theme.palette.primary.main}`
                                    : "none",
                                color: !matchingVMLot
                                  ? theme.palette.semantic.error
                                  : "inherit",
                              }}
                            >
                              {matchingVMLot?.quantity_metrics?.[metricIndex]
                                ?.value ?? "-"}
                            </TableCell>
                          ))}

                          {/* Differences */}
                          {calculateDifferencesByIndex(
                            blLot.quantity_metrics,
                            matchingVMLot?.quantity_metrics
                          ).map((diff, metricIndex) => (
                            <TableCell
                              key={metricIndex}
                              style={{
                                width: columnWidth,
                                color:
                                  diff.difference !== 0
                                    ? theme.palette.semantic.error
                                    : "inherit",
                              }}
                            >
                              {diff.difference}
                            </TableCell>
                          ))}
                        </>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

CollapsibleRow.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  row: PropTypes.object.isRequired,
  fileNames: PropTypes.array.isRequired,
  metricNames: PropTypes.array.isRequired,
  comparisonResult: PropTypes.object.isRequired,
};

export default CargoResult;
