/* eslint-disable no-redeclare */
/* global Intl */
import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tab,
  Button,
  Alert,
  useTheme,
} from "@mui/material";
import ShipsterCard from "../../components/common/ShipsterCard";
import { ContentCopy } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import NoDataMessage from "../../components/comparison/NoDataMessage";
import { exportHireStatementsToExcel } from "../../services/hireService";
import PropTypes from "prop-types";
import { UserContext } from "../../context/UserContext";

// Function to format currency with commas
const formatCurrency = (amount) => {
  if (amount === null || amount === undefined) return "N/A";
  if (typeof amount === "number") {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  }
  return amount;
};

// Utility function for getting severity color
const getSeverityColor = (severity, theme) => {
  switch (severity) {
    case "HIGH":
      return theme.palette.semantic.error;
    case "MEDIUM":
      return theme.palette.semantic.warning;
    case "LOW":
      return theme.palette.semantic.success;
    default:
      return "inherit";
  }
};

const HireStatementResult = ({ comparisonResult, role }) => {
  const theme = useTheme();
  const [tabValue, setTabValue] = useState(0);
  const [exportLoading, setExportLoading] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const [copyError, setCopyError] = useState(null);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const [exportError, setExportError] = useState(null);

  const { user } = useContext(UserContext);

  const handleExportToExcel = async () => {
    try {
      setExportLoading(true);
      setExportError(null);
      // Export both statements to Excel
      await exportHireStatementsToExcel(
        [
          comparisonResult.comparison_result.owner_hire_statement,
          comparisonResult.comparison_result.charterer_hire_statement,
        ],
        user
      );
    } catch (error) {
      console.error("Error exporting to Excel:", error);
      setExportError(
        error.message ||
          "An error occurred exporting to Excel. Please try again."
      );
    } finally {
      setExportLoading(false);
    }
  };

  const handleCopyEmail = () => {
    if (
      comparisonResult?.comparison_result?.comparison_result
        ?.draft_email_response
    ) {
      navigator.clipboard
        .writeText(
          comparisonResult.comparison_result.comparison_result
            .draft_email_response
        )
        .then(() => {
          setCopySuccess(true);
          setTimeout(() => setCopySuccess(false), 3000);
        })
        .catch((err) => {
          console.error("Copy failed:", err);
          setCopyError("Failed to copy to clipboard");
          setTimeout(() => setCopyError(null), 3000);
        });
    }
  };

  if (!comparisonResult) return null;

  // Extract data from API response
  const { owner_hire_statement, charterer_hire_statement, comparison_result } =
    comparisonResult.comparison_result;

  // Get all possible discrepancy categories
  const discrepancyCategories = [
    {
      key: "hire_discrepancies",
      title: "Hire Discrepancies",
      data: comparison_result.hire_discrepancies,
      type: "line",
    },
    {
      key: "commission_discrepancies",
      title: "Commission Discrepancies",
      data: comparison_result.commission_discrepancies,
      type: "line",
    },
    {
      key: "bunker_discrepancies",
      title: "Bunker Discrepancies",
      data: comparison_result.bunker_discrepancies,
      type: "line",
    },
    {
      key: "crew_expenses_discrepancies",
      title: "Crew Expenses Discrepancies",
      data: comparison_result.crew_expenses_discrepancies,
      type: "expense",
    },
    {
      key: "owner_expenses_discrepancies",
      title: "Owner Expenses Discrepancies",
      data: comparison_result.owner_expenses_discrepancies,
      type: "expense",
    },
    {
      key: "charterer_expenses_discrepancies",
      title: "Charterer Expenses Discrepancies",
      data: comparison_result.charterer_expenses_discrepancies,
      type: "expense",
    },
    {
      key: "other_adjustments_discrepancies",
      title: "Other Adjustments Discrepancies",
      data: comparison_result.other_adjustments_discrepancies,
      type: "expense",
    },
    {
      key: "payments_discrepancies",
      title: "Payments Discrepancies",
      data: comparison_result.payments_discrepancies,
      type: "line",
    },
  ];

  // Filter out empty discrepancies
  const activeDiscrepancies = discrepancyCategories.filter(
    (category) => category.data
  );

  return (
    <Box>
      <ShipsterCard>
        <Box>
          <Card>
            <CardContent
              sx={{ flex: 1, display: "flex", flexDirection: "column" }}
            >
              <Typography variant="h4" gutterBottom>
                Amount Due Discrepancy:{" "}
                {formatCurrency(
                  comparison_result.total_discrepancy_breakdown
                    .amount_due_discrepancy.amount
                )}
              </Typography>
              <Typography variant="body2" paragraph>
                {
                  comparison_result.total_discrepancy_breakdown
                    .amount_due_discrepancy.description
                }
              </Typography>

              <Typography variant="h5" gutterBottom>
                Contributing Discrepancies:
              </Typography>
              <ul>
                {comparison_result.total_discrepancy_breakdown.contributing_discrepancies.map(
                  (disc, index) => (
                    <li key={index}>
                      <Typography variant="body2">
                        <strong>{formatCurrency(disc.amount)}:</strong>{" "}
                        {disc.description}
                      </Typography>
                    </li>
                  )
                )}
              </ul>

              <Typography variant="h5" gutterBottom>
                Calculation:
              </Typography>
              <Paper
                elevation={1}
                sx={{
                  p: 2,
                  whiteSpace: "pre-wrap",
                  backgroundColor: theme.palette.background.level2,
                  fontFamily: "monospace",
                }}
              >
                {
                  comparison_result.total_discrepancy_breakdown
                    .sum_of_contributing_discrepancies_calculation
                }
              </Paper>
            </CardContent>
          </Card>
        </Box>

        {/* Display error from API if there's any */}
        {exportError && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {exportError}
          </Alert>
        )}

        {/* SECTION 2: Side-by-side Statements */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h4" sx={{ mb: 2 }}>
            Statement Comparison
          </Typography>
          <StatementsComparisonView
            ownerStatement={owner_hire_statement}
            chartererStatement={charterer_hire_statement}
            activeDiscrepancies={activeDiscrepancies}
          />

          <Box
            sx={{
              mt: 3,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Button
              variant="contained"
              onClick={handleExportToExcel}
              disabled={exportLoading}
              sx={{ minWidth: 250 }}
            >
              Export Statements to Excel
            </Button>
            {exportError && (
              <Alert severity="error" sx={{ mt: 2, width: "100%" }}>
                {exportError}
              </Alert>
            )}
          </Box>

          <Alert
            severity="info"
            sx={{
              mt: 3,
              backgroundColor: theme.palette.background.level2,
              border: `1px solid ${theme.palette.semantic.info}`,
              borderLeft: `8px solid ${theme.palette.semantic.info}`,
              "& .MuiAlert-message": { color: theme.palette.text.primary },
            }}
          >
            <Typography variant="body1">
              <strong>Note:</strong> These extracted hire statements are a
              useful first draft, but there may still be errors. You can
              download the Excel file and manually check. The comparison
              analysis is based both on the original document and the extracted
              statement, so it is often still robust to minor errors in
              extraction.
            </Typography>
          </Alert>
        </Box>

        {/* SECTION 3: Discrepancy Tabs */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h4" sx={{ mb: 2 }}>
            Discrepancy Details
          </Typography>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              border: 1,
              borderColor: "divider",
              minHeight: "400px", // Set a default minimum height for the container
            }}
          >
            <Box
              sx={{
                borderRight: 1,
                borderColor: "divider",
                minWidth: "300px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Tabs
                orientation="vertical"
                value={tabValue}
                onChange={handleTabChange}
                aria-label="hire statement tabs"
                sx={{
                  flex: 1,
                  "& .MuiTabs-indicator": {
                    right: 0,
                    left: "auto",
                  },
                  "& .MuiTabs-flexContainer": {
                    height: "100%", // Make the tab container take the full height
                  },
                }}
              >
                {activeDiscrepancies.map((category) => {
                  const severity = category.data?.severity || "NONE";
                  const severityColor = getSeverityColor(severity, theme);

                  // Display amount in tab if available
                  let amountDisplay = "";

                  if (category.data) {
                    // For expense categories, use net_expenses_discrepancy if available
                    if (category.type === "expense") {
                      const amount =
                        category.data.net_expenses_discrepancy !== undefined
                          ? category.data.net_expenses_discrepancy
                          : category.data.discrepancy_amount;

                      if (amount !== undefined) {
                        amountDisplay = ` (${formatCurrency(amount)})`;
                      }
                    }
                    // For non-expense categories, extract amount from discrepancy text or use direct numeric value
                    else {
                      let amount = 0;

                      if (category.data.discrepancy_amount !== undefined) {
                        amount = category.data.discrepancy_amount;
                      } else if (category.data.discrepancy) {
                        // Try to extract numeric value from discrepancy text
                        const match =
                          category.data.discrepancy.match(/[\d.-]+/);
                        if (match) {
                          amount = parseFloat(match[0] || 0);
                        }
                      }

                      amountDisplay = ` (${formatCurrency(amount)})`;
                    }
                  }

                  return (
                    <Tab
                      key={category.key}
                      label={
                        <span style={{ textAlign: "left", width: "100%" }}>
                          {category.title}
                          {amountDisplay}
                        </span>
                      }
                      sx={{
                        alignItems: "flex-start",
                        textAlign: "left",
                        padding: "12px 16px",
                        minHeight: "48px",
                        borderLeft: `3px solid ${severityColor}`,
                        "&.Mui-selected": {
                          borderRight: `3px solid ${severityColor}`,
                          borderBottom: "none",
                          backgroundColor: `${theme.palette.background.level2}`,
                        },
                      }}
                    />
                  );
                })}
              </Tabs>
            </Box>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              {/* Discrepancy Tabs Content */}
              {activeDiscrepancies.map((category, index) => (
                <TabPanel key={category.key} value={tabValue} index={index}>
                  <DiscrepancyDetailView
                    discrepancy={category.data}
                    title={category.title}
                    type={category.type}
                  />
                </TabPanel>
              ))}
            </Box>
          </Box>
        </Box>

        {/* SECTION 4: Draft Email Response */}
        <Box sx={{ mb: 4, mt: 4 }}>
          <Typography variant="h4" sx={{ mb: 2 }}>
            Draft Email Response
          </Typography>
          <Card>
            <CardContent>
              <Box sx={{ position: "relative" }}>
                <Typography variant="h5" gutterBottom>
                  {role === "owner"
                    ? "Owner to Charterer"
                    : "Charterer to Owner"}
                </Typography>
                <Button
                  variant="contained"
                  startIcon={<ContentCopy />}
                  onClick={handleCopyEmail}
                  sx={{ position: "absolute", top: 0, right: 0, mb: 2 }}
                >
                  Copy to Clipboard
                </Button>
                {copySuccess && (
                  <Alert severity="success" sx={{ mt: 6, mb: 2 }}>
                    Draft email copied to clipboard!
                  </Alert>
                )}
                {copyError && (
                  <Alert severity="error" sx={{ mt: 6, mb: 2 }}>
                    {copyError}
                  </Alert>
                )}
                <Paper
                  elevation={1}
                  sx={{
                    p: 4,
                    mt: 6,
                    whiteSpace: "pre-wrap",
                    backgroundColor: theme.palette.background.level2,
                  }}
                >
                  {comparison_result.draft_email_response}
                </Paper>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </ShipsterCard>
    </Box>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`hire-tabpanel-${index}`}
      aria-labelledby={`hire-tab-${index}`}
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            p: 2,
            flex: 1,
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const DiscrepancyDetailView = ({ discrepancy, title, type }) => {
  const theme = useTheme();

  if (!discrepancy) return <NoDataMessage filter="all" />;

  const getSeverityColor = (severity) => {
    switch (severity) {
      case "HIGH":
        return theme.palette.semantic.error;
      case "MEDIUM":
        return theme.palette.semantic.warning;
      case "LOW":
        return theme.palette.semantic.success;
      default:
        return "inherit";
    }
  };

  return (
    <Box>
      <Card
        sx={{
          mb: 3,
          p: 2,
          border: `1px solid ${getSeverityColor(discrepancy.severity)}`,
          borderLeft: `8px solid ${getSeverityColor(discrepancy.severity)}`,
        }}
      >
        <Typography variant="h4" gutterBottom>
          {title} ({discrepancy.severity})
        </Typography>

        <Typography variant="body2" paragraph>
          <strong>Discrepancy:</strong> {discrepancy.discrepancy}
        </Typography>

        {/* Display discrepancy amount for all categories */}
        {type === "expense" ? (
          // For expense type, show both discrepancy amount and net expenses
          <>
            <Typography variant="body2" paragraph>
              <strong>Discrepancy Amount:</strong>{" "}
              {formatCurrency(discrepancy.discrepancy_amount)}
            </Typography>

            {discrepancy.net_expenses_discrepancy !== undefined && (
              <Typography variant="body2" paragraph>
                <strong>Net Expenses Discrepancy:</strong>{" "}
                {formatCurrency(discrepancy.net_expenses_discrepancy)}
              </Typography>
            )}
          </>
        ) : (
          // For non-expense type, extract and show the amount
          <Typography variant="body2" paragraph>
            <strong>Discrepancy Amount:</strong>{" "}
            {discrepancy.discrepancy_amount !== undefined
              ? formatCurrency(discrepancy.discrepancy_amount)
              : discrepancy.discrepancy &&
                  discrepancy.discrepancy.match(/[\d.-]+/)
                ? formatCurrency(
                    parseFloat(discrepancy.discrepancy.match(/[\d.-]+/)[0] || 0)
                  )
                : "N/A"}
          </Typography>
        )}

        {discrepancy.owner_line_items_missing_from_charterer_statement?.length >
          0 && (
          <>
            <Typography variant="h6" sx={{ mt: 2 }}>
              Owner Line Items Missing from Charterer Statement:
            </Typography>
            <ul>
              {discrepancy.owner_line_items_missing_from_charterer_statement.map(
                (item, idx) => (
                  <li key={`owner-missing-${idx}`}>
                    <Typography variant="body2">{item}</Typography>
                  </li>
                )
              )}
            </ul>
          </>
        )}

        {discrepancy.charterer_line_items_missing_from_owner_statement?.length >
          0 && (
          <>
            <Typography variant="h6" sx={{ mt: 2 }}>
              Charterer Line Items Missing from Owner Statement:
            </Typography>
            <ul>
              {discrepancy.charterer_line_items_missing_from_owner_statement.map(
                (item, idx) => (
                  <li key={`charterer-missing-${idx}`}>
                    <Typography variant="body2">{item}</Typography>
                  </li>
                )
              )}
            </ul>
          </>
        )}

        {discrepancy.line_items_with_different_amounts?.length > 0 && (
          <>
            <Typography variant="h6" sx={{ mt: 2 }}>
              Line Items with Different Amounts:
            </Typography>
            <ul>
              {discrepancy.line_items_with_different_amounts.map(
                (item, idx) => (
                  <li key={`diff-amount-${idx}`}>
                    <Typography variant="body2">{item}</Typography>
                  </li>
                )
              )}
            </ul>
          </>
        )}

        {type === "expense" &&
          discrepancy.differently_classified_line_items?.length > 0 && (
            <>
              <Typography variant="h6" sx={{ mt: 2 }}>
                Items Classified Differently:
              </Typography>
              <ul>
                {discrepancy.differently_classified_line_items.map(
                  (item, idx) => (
                    <li key={`diff-class-${idx}`}>
                      <Typography variant="body2">{item}</Typography>
                    </li>
                  )
                )}
              </ul>
            </>
          )}

        <Typography variant="body2" sx={{ mt: 2 }}>
          <strong>Suggested Action:</strong>{" "}
          {discrepancy.suggested_analyst_action}
        </Typography>
      </Card>
    </Box>
  );
};

DiscrepancyDetailView.propTypes = {
  discrepancy: PropTypes.object,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

const StatementsComparisonView = ({
  ownerStatement,
  chartererStatement,
  activeDiscrepancies,
}) => {
  /* eslint-disable no-unused-vars */
  const theme = useTheme();
  /* eslint-enable no-unused-vars */
  const [expandedSections, setExpandedSections] = useState({
    grossHire: false,
    offHire: false,
    netHire: false,
    hireCommissions: false,
    bunkerDelivery: false,
    bunkerCompletion: false,
    crewExpenses: false,
    ownerExpenses: false,
    chartererExpenses: false,
    otherAdjustments: false,
    payments: false,
    hireTotal: false,
    balance: false,
  });

  // Utility function for getting severity color
  const getSeverityColor = (severity) => {
    switch (severity) {
      case "HIGH":
        return theme.palette.semantic.error;
      case "MEDIUM":
        return theme.palette.semantic.warning;
      case "LOW":
        return theme.palette.semantic.success;
      default:
        return "inherit";
    }
  };

  // Refs to track section heights
  const [sectionHeights, setSectionHeights] = useState({});
  const ownerSectionRefs = useRef({});
  const chartererSectionRefs = useRef({});

  // Effect to synchronize section heights when sections expand or collapse
  useEffect(() => {
    const sectionKeys = Object.keys(expandedSections);
    const newHeights = {};

    // We only need to calculate heights for expanded sections
    sectionKeys.forEach((key) => {
      if (expandedSections[key]) {
        const ownerHeight = ownerSectionRefs.current[key]?.clientHeight || 0;
        const chartererHeight =
          chartererSectionRefs.current[key]?.clientHeight || 0;
        newHeights[key] = Math.max(ownerHeight, chartererHeight);
      }
    });

    setSectionHeights(newHeights);
  }, [expandedSections, ownerStatement, chartererStatement]);

  // Create a map of highlighted indexes from all discrepancies
  const highlightMap = {};

  // Collect highlights from all discrepancies
  activeDiscrepancies.forEach((category) => {
    if (category.data && category.data.highlight_indexes) {
      category.data.highlight_indexes.forEach((index) => {
        highlightMap[index] = getSeverityColor(category.data.severity);
      });
    }
  });

  // Create a map to track which sections have discrepancies for both statements
  const sectionDiscrepancyMap = {
    grossHire: false,
    offHire: false,
    netHire: false,
    hireCommissions: false,
    bunkerDelivery: false,
    bunkerCompletion: false,
    crewExpenses: false,
    ownerExpenses: false,
    chartererExpenses: false,
    otherAdjustments: false,
    payments: false,
    balance: false,
    hireTotal: false,
  };

  // Check for discrepancies in section items and totals
  const checkForTotalDiscrepancies = () => {
    // Define mapping between statement fields and section keys
    const fieldToSectionMap = {
      gross_hire_total: "grossHire",
      total_off_hire: "offHire",
      total_net_hire: "netHire",
      total_hire_commissions: "hireCommissions",
      bunker_value_on_delivery_total: "bunkerDelivery",
      bunker_value_on_completion_total: "bunkerCompletion",
      crew_expenses_total: "crewExpenses",
      owners_expenses_total: "ownerExpenses",
      charterers_expenses_total: "chartererExpenses",
      other_adjustments_total: "otherAdjustments",
      hire_total: "hireTotal",
      payments_so_far_total: "payments",
      balance_due: "balance",
    };

    // Compare each field and mark discrepancies
    Object.entries(fieldToSectionMap).forEach(([field, sectionKey]) => {
      const ownerValue = ownerStatement[field]?.amount_float;
      const chartererValue = chartererStatement[field]?.amount_float;

      // Check if either value is undefined/null or if they're different
      if (ownerValue === undefined || chartererValue === undefined) {
        // One statement is missing this field entirely
        if (ownerValue !== chartererValue) {
          sectionDiscrepancyMap[sectionKey] = true;
        }
      } else {
        // Both have values, check for difference
        // Using a small epsilon for floating point comparison
        const epsilon = 0.0001;
        if (Math.abs(ownerValue - chartererValue) > epsilon) {
          sectionDiscrepancyMap[sectionKey] = true;
        }
      }
    });
  };

  // Call the function to check for discrepancies
  checkForTotalDiscrepancies();

  const toggleSection = (section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        gap: 2,
        width: "100%",
      }}
    >
      <Box sx={{ flex: 1, width: { xs: "100%", md: "50%" } }}>
        <HireStatementView
          statement={ownerStatement}
          title="Owner Statement"
          prefix="owner"
          highlightMap={highlightMap}
          expandedSections={expandedSections}
          toggleSection={toggleSection}
          sectionRefs={ownerSectionRefs}
          sectionHeights={sectionHeights}
          sectionDiscrepancyMap={sectionDiscrepancyMap}
        />
      </Box>
      <Box sx={{ flex: 1, width: { xs: "100%", md: "50%" } }}>
        <HireStatementView
          statement={chartererStatement}
          title="Charterer Statement"
          prefix="charterer"
          highlightMap={highlightMap}
          expandedSections={expandedSections}
          toggleSection={toggleSection}
          sectionRefs={chartererSectionRefs}
          sectionHeights={sectionHeights}
          sectionDiscrepancyMap={sectionDiscrepancyMap}
        />
      </Box>
    </Box>
  );
};

StatementsComparisonView.propTypes = {
  ownerStatement: PropTypes.object.isRequired,
  chartererStatement: PropTypes.object.isRequired,
  activeDiscrepancies: PropTypes.array.isRequired,
};

const HireStatementView = ({
  statement,
  title,
  prefix,
  highlightMap,
  expandedSections,
  toggleSection,
  sectionRefs,
  sectionHeights,
  sectionDiscrepancyMap,
}) => {
  const theme = useTheme();

  // Helper function to get highlight color for an index
  const getHighlightColor = (index) => {
    const fullIndex = `${prefix}${index}`;
    // Return red for discrepancies, otherwise transparent (no highlight)
    return highlightMap[fullIndex] || "transparent";
  };

  // Helper function to format amounts
  const formatAmount = (amount, calculated = null) => {
    if (amount === null || amount === undefined) return "N/A";

    const formattedMain =
      typeof amount === "number" ? formatCurrency(amount) : amount;

    if (calculated !== null && calculated !== undefined) {
      const formattedCalc =
        typeof calculated === "number"
          ? formatCurrency(calculated)
          : calculated;
      return `${formattedMain} (Calculated: ${formattedCalc})`;
    }

    return formattedMain;
  };

  // Check if any items in a section have discrepancies
  const getSectionHighlightColor = (items, sectionKey, totalHighlightColor) => {
    // If there's a discrepancy in the section based on totals comparison
    if (sectionDiscrepancyMap[sectionKey]) {
      return theme.palette.semantic.error; // Red for discrepancies
    }

    // If total already has a highlight, use that
    if (totalHighlightColor !== "transparent") {
      return totalHighlightColor;
    }

    // Special case for non-array sections like total_net_hire
    if (!Array.isArray(items)) {
      return "transparent";
    }

    // Check if any item in the section has a highlight
    const hasDiscrepancy = items.some(
      (item) => getHighlightColor(item.hire_index) !== "transparent"
    );

    return hasDiscrepancy
      ? theme.palette.semantic.error // Red for discrepancies
      : theme.palette.semantic.success; // Green for no discrepancies
  };

  const renderSectionHeader = (
    title,
    isExpanded,
    toggleFn,
    totalAmount,
    sectionKey,
    highlightColor = "transparent",
    sectionItems = []
  ) => {
    // Get highlight color for the section based on its items
    const sectionHighlightColor = getSectionHighlightColor(
      sectionItems,
      sectionKey,
      highlightColor
    );

    return (
      <TableRow>
        <TableCell
          colSpan={2}
          sx={{
            p: 0,
            borderBottom: `1px solid ${theme.palette.divider}`,
            cursor: "pointer", // All sections are clickable now
            borderLeft: `4px solid ${sectionHighlightColor}`,
            width: "100%", // Make sure header cells take full width
            maxWidth: "100%",
          }}
          onClick={() => toggleFn(sectionKey)}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 1,
              fontWeight: "bold",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h5">{title}</Typography>
              {totalAmount && (
                <Typography variant="body1" sx={{ ml: 2 }}>
                  ({formatAmount(totalAmount.amount_float)})
                </Typography>
              )}
            </Box>
            <IconButton size="small">
              {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
    );
  };

  const renderAmountRow = (item, indent = 0) => {
    const highlightColor = getHighlightColor(item.hire_index);
    return (
      <TableRow
        key={item.hire_index}
        sx={{
          borderLeft:
            highlightColor !== "transparent"
              ? `4px solid ${highlightColor}`
              : "none",
          backgroundColor: theme.palette.background.level2,
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
          width: "100%",
        }}
      >
        <TableCell
          sx={{
            pl: indent * 2 + 2,
            width: "70%", // Expanded width percentage for description cell
            maxWidth: "70%",
            wordBreak: "break-word",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <Typography variant="body2">{item.field_name}</Typography>
        </TableCell>
        <TableCell
          align="right"
          sx={{
            width: "30%", // Reduced width percentage for amount cell
            maxWidth: "30%",
            whiteSpace: "nowrap",
          }}
        >
          <Typography variant="body2">
            {formatAmount(item.amount_float, item.calculated_amount)}
          </Typography>
        </TableCell>
      </TableRow>
    );
  };

  const renderTotalRow = (totalItem) => {
    const highlightColor = getHighlightColor(totalItem.hire_index);
    return (
      <TableRow
        key={`total-${totalItem.hire_index}`}
        sx={{
          borderTop: `2px solid ${theme.palette.divider}`,
          borderLeft:
            highlightColor !== "transparent"
              ? `4px solid ${highlightColor}`
              : "none",
          backgroundColor: theme.palette.background.level2,
          fontWeight: "bold",
          width: "100%",
        }}
      >
        <TableCell sx={{ fontWeight: "bold", width: "70%", maxWidth: "70%" }}>
          Total
        </TableCell>
        <TableCell
          align="right"
          sx={{ fontWeight: "bold", width: "30%", maxWidth: "30%" }}
        >
          {formatAmount(totalItem.amount_float, totalItem.calculated_amount)}
        </TableCell>
      </TableRow>
    );
  };

  const renderBunkerRow = (item, indent = 0) => {
    const highlightColor = getHighlightColor(item.hire_index);
    return (
      <TableRow
        key={item.hire_index}
        sx={{
          borderLeft:
            highlightColor !== "transparent"
              ? `4px solid ${highlightColor}`
              : "none",
          backgroundColor: theme.palette.background.level2,
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
          width: "100%",
        }}
      >
        <TableCell
          sx={{
            pl: indent * 2 + 2,
            width: "70%",
            maxWidth: "70%",
            wordBreak: "break-word",
          }}
        >
          {`${item.fuel_name} (${item.metric_tons} MT × ${formatCurrency(item.cost_per_metric_ton)}/MT)`}
        </TableCell>
        <TableCell
          align="right"
          sx={{
            width: "30%",
            maxWidth: "30%",
          }}
        >
          {formatAmount(item.total_value_float, item.total_value_calculated)}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Card
      sx={{
        border: `1px solid ${theme.palette.divider}`,
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CardContent>
        <Typography variant="intro" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body2">
          <strong>Vessel:</strong> {statement.vessel}
        </Typography>
        <Typography variant="body2">
          <strong>Issue Date:</strong> {statement.issue_date}
        </Typography>
        <Typography variant="body2">
          <strong>C/P Date:</strong> {statement.cp_date}
        </Typography>
        <Typography variant="body2" sx={{ mb: 2 }}>
          <strong>Short Name:</strong> {statement.short_name}
        </Typography>

        <TableContainer
          component={Paper}
          sx={{ mb: 4, width: "100%", overflow: "auto", maxWidth: "100%" }}
        >
          <Table
            size="small"
            sx={{
              tableLayout: "fixed",
              width: "100%",
              minWidth: { xs: "400px", sm: "500px" },
              borderCollapse: "collapse",
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "70%" }}>Item</TableCell>
                <TableCell align="right" sx={{ width: "30%" }}>
                  Amount
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* Gross Hire Section */}
              {renderSectionHeader(
                "Gross Hire",
                expandedSections.grossHire,
                toggleSection,
                statement.gross_hire_total,
                "grossHire",
                getHighlightColor("1"),
                statement.gross_hire_items
              )}
              {expandedSections.grossHire && (
                <TableRow>
                  <TableCell colSpan={2} sx={{ p: 0, border: 0 }}>
                    <Box
                      ref={(el) => (sectionRefs.current.grossHire = el)}
                      sx={{
                        minHeight: sectionHeights.grossHire
                          ? `${sectionHeights.grossHire}px`
                          : "auto",
                        width: "100%",
                        backgroundColor: theme.palette.background.level2,
                      }}
                    >
                      <Table sx={{ tableLayout: "fixed", width: "100%" }}>
                        <TableBody>
                          {statement.gross_hire_items.map((item) =>
                            renderAmountRow(item, 1)
                          )}
                          {renderTotalRow(statement.gross_hire_total)}
                        </TableBody>
                      </Table>
                    </Box>
                  </TableCell>
                </TableRow>
              )}

              {/* Off Hire Section */}
              {renderSectionHeader(
                "Off Hire",
                expandedSections.offHire,
                toggleSection,
                statement.total_off_hire,
                "offHire",
                getHighlightColor("2"),
                statement.off_hire_items
              )}
              {expandedSections.offHire && (
                <TableRow>
                  <TableCell colSpan={2} sx={{ p: 0, border: 0 }}>
                    <Box
                      ref={(el) => (sectionRefs.current.offHire = el)}
                      sx={{
                        minHeight: sectionHeights.offHire
                          ? `${sectionHeights.offHire}px`
                          : "auto",
                        width: "100%",
                        backgroundColor: theme.palette.background.level2,
                      }}
                    >
                      <Table sx={{ tableLayout: "fixed", width: "100%" }}>
                        <TableBody>
                          {statement.off_hire_items.map((item) =>
                            renderAmountRow(item, 1)
                          )}
                          {renderTotalRow(statement.total_off_hire)}
                        </TableBody>
                      </Table>
                    </Box>
                  </TableCell>
                </TableRow>
              )}

              {/* Net Hire Section */}
              {renderSectionHeader(
                "Net Hire",
                expandedSections.netHire,
                toggleSection,
                statement.total_net_hire,
                "netHire",
                getHighlightColor("3")
              )}
              {expandedSections.netHire && (
                <TableRow>
                  <TableCell colSpan={2} sx={{ p: 0, border: 0 }}>
                    <Box
                      ref={(el) => (sectionRefs.current.netHire = el)}
                      sx={{
                        minHeight: sectionHeights.netHire
                          ? `${sectionHeights.netHire}px`
                          : "auto",
                        width: "100%",
                        backgroundColor: theme.palette.background.level2,
                      }}
                    >
                      <Table sx={{ tableLayout: "fixed", width: "100%" }}>
                        <TableBody>
                          {renderAmountRow(statement.total_net_hire)}
                        </TableBody>
                      </Table>
                    </Box>
                  </TableCell>
                </TableRow>
              )}

              {/* Hire Commissions Section */}
              {renderSectionHeader(
                "Hire Commissions",
                expandedSections.hireCommissions,
                toggleSection,
                statement.total_hire_commissions,
                "hireCommissions",
                getHighlightColor("4"),
                statement.hire_commissions_items
              )}
              {expandedSections.hireCommissions && (
                <TableRow>
                  <TableCell colSpan={2} sx={{ p: 0, border: 0 }}>
                    <Box
                      ref={(el) => (sectionRefs.current.hireCommissions = el)}
                      sx={{
                        minHeight: sectionHeights.hireCommissions
                          ? `${sectionHeights.hireCommissions}px`
                          : "auto",
                        width: "100%",
                        backgroundColor: theme.palette.background.level2,
                      }}
                    >
                      <Table sx={{ tableLayout: "fixed", width: "100%" }}>
                        <TableBody>
                          {statement.hire_commissions_items.map((item) =>
                            renderAmountRow(item, 1)
                          )}
                          {renderTotalRow(statement.total_hire_commissions)}
                        </TableBody>
                      </Table>
                    </Box>
                  </TableCell>
                </TableRow>
              )}

              {/* Bunker Value on Delivery Section */}
              {renderSectionHeader(
                "Bunker Value on Delivery",
                expandedSections.bunkerDelivery,
                toggleSection,
                statement.bunker_value_on_delivery_total,
                "bunkerDelivery",
                getHighlightColor("5"),
                statement.bunker_value_on_delivery_items
              )}
              {expandedSections.bunkerDelivery && (
                <TableRow>
                  <TableCell colSpan={2} sx={{ p: 0, border: 0 }}>
                    <Box
                      ref={(el) => (sectionRefs.current.bunkerDelivery = el)}
                      sx={{
                        minHeight: sectionHeights.bunkerDelivery
                          ? `${sectionHeights.bunkerDelivery}px`
                          : "auto",
                        width: "100%",
                        backgroundColor: theme.palette.background.level2,
                      }}
                    >
                      <Table sx={{ tableLayout: "fixed", width: "100%" }}>
                        <TableBody>
                          {statement.bunker_value_on_delivery_items.map(
                            (item) => renderBunkerRow(item, 1)
                          )}
                          {renderTotalRow(
                            statement.bunker_value_on_delivery_total
                          )}
                        </TableBody>
                      </Table>
                    </Box>
                  </TableCell>
                </TableRow>
              )}

              {/* Bunker Value on Completion Section */}
              {renderSectionHeader(
                "Bunker Value on Completion",
                expandedSections.bunkerCompletion,
                toggleSection,
                statement.bunker_value_on_completion_total,
                "bunkerCompletion",
                getHighlightColor("6"),
                statement.bunker_value_on_completion_items
              )}
              {expandedSections.bunkerCompletion && (
                <TableRow>
                  <TableCell colSpan={2} sx={{ p: 0, border: 0 }}>
                    <Box
                      ref={(el) => (sectionRefs.current.bunkerCompletion = el)}
                      sx={{
                        minHeight: sectionHeights.bunkerCompletion
                          ? `${sectionHeights.bunkerCompletion}px`
                          : "auto",
                        width: "100%",
                        backgroundColor: theme.palette.background.level2,
                      }}
                    >
                      <Table sx={{ tableLayout: "fixed", width: "100%" }}>
                        <TableBody>
                          {statement.bunker_value_on_completion_items.map(
                            (item) => renderBunkerRow(item, 1)
                          )}
                          {renderTotalRow(
                            statement.bunker_value_on_completion_total
                          )}
                        </TableBody>
                      </Table>
                    </Box>
                  </TableCell>
                </TableRow>
              )}

              {/* Crew Expenses Section */}
              {renderSectionHeader(
                "Crew Expenses",
                expandedSections.crewExpenses,
                toggleSection,
                statement.crew_expenses_total,
                "crewExpenses",
                getHighlightColor("7"),
                statement.crew_expenses_items
              )}
              {expandedSections.crewExpenses && (
                <TableRow>
                  <TableCell colSpan={2} sx={{ p: 0, border: 0 }}>
                    <Box
                      ref={(el) => (sectionRefs.current.crewExpenses = el)}
                      sx={{
                        minHeight: sectionHeights.crewExpenses
                          ? `${sectionHeights.crewExpenses}px`
                          : "auto",
                        width: "100%",
                        backgroundColor: theme.palette.background.level2,
                      }}
                    >
                      <Table sx={{ tableLayout: "fixed", width: "100%" }}>
                        <TableBody>
                          {statement.crew_expenses_items.map((item) =>
                            renderAmountRow(item, 1)
                          )}
                          {renderTotalRow(statement.crew_expenses_total)}
                        </TableBody>
                      </Table>
                    </Box>
                  </TableCell>
                </TableRow>
              )}

              {/* Owner Expenses Section */}
              {renderSectionHeader(
                "Owner Expenses",
                expandedSections.ownerExpenses,
                toggleSection,
                statement.owners_expenses_total,
                "ownerExpenses",
                getHighlightColor("8"),
                statement.owners_expenses_items
              )}
              {expandedSections.ownerExpenses && (
                <TableRow>
                  <TableCell colSpan={2} sx={{ p: 0, border: 0 }}>
                    <Box
                      ref={(el) => (sectionRefs.current.ownerExpenses = el)}
                      sx={{
                        minHeight: sectionHeights.ownerExpenses
                          ? `${sectionHeights.ownerExpenses}px`
                          : "auto",
                        width: "100%",
                        backgroundColor: theme.palette.background.level2,
                      }}
                    >
                      <Table sx={{ tableLayout: "fixed", width: "100%" }}>
                        <TableBody>
                          {statement.owners_expenses_items.map((item) =>
                            renderAmountRow(item, 1)
                          )}
                          {renderTotalRow(statement.owners_expenses_total)}
                        </TableBody>
                      </Table>
                    </Box>
                  </TableCell>
                </TableRow>
              )}

              {/* Charterer Expenses Section */}
              {renderSectionHeader(
                "Charterer Expenses",
                expandedSections.chartererExpenses,
                toggleSection,
                statement.charterers_expenses_total,
                "chartererExpenses",
                getHighlightColor("9"),
                statement.charterers_expenses_items
              )}
              {expandedSections.chartererExpenses && (
                <TableRow>
                  <TableCell colSpan={2} sx={{ p: 0, border: 0 }}>
                    <Box
                      ref={(el) => (sectionRefs.current.chartererExpenses = el)}
                      sx={{
                        minHeight: sectionHeights.chartererExpenses
                          ? `${sectionHeights.chartererExpenses}px`
                          : "auto",
                        width: "100%",
                        backgroundColor: theme.palette.background.level2,
                      }}
                    >
                      <Table sx={{ tableLayout: "fixed", width: "100%" }}>
                        <TableBody>
                          {statement.charterers_expenses_items.map((item) =>
                            renderAmountRow(item, 1)
                          )}
                          {renderTotalRow(statement.charterers_expenses_total)}
                        </TableBody>
                      </Table>
                    </Box>
                  </TableCell>
                </TableRow>
              )}

              {/* Other Adjustments Section */}
              {renderSectionHeader(
                "Other Adjustments",
                expandedSections.otherAdjustments,
                toggleSection,
                statement.other_adjustments_total,
                "otherAdjustments",
                getHighlightColor("10"),
                statement.other_adjustments_items
              )}
              {expandedSections.otherAdjustments && (
                <TableRow>
                  <TableCell colSpan={2} sx={{ p: 0, border: 0 }}>
                    <Box
                      ref={(el) => (sectionRefs.current.otherAdjustments = el)}
                      sx={{
                        minHeight: sectionHeights.otherAdjustments
                          ? `${sectionHeights.otherAdjustments}px`
                          : "auto",
                        width: "100%",
                        backgroundColor: theme.palette.background.level2,
                      }}
                    >
                      <Table sx={{ tableLayout: "fixed", width: "100%" }}>
                        <TableBody>
                          {statement.other_adjustments_items.map((item) =>
                            renderAmountRow(item, 1)
                          )}
                          {renderTotalRow(statement.other_adjustments_total)}
                        </TableBody>
                      </Table>
                    </Box>
                  </TableCell>
                </TableRow>
              )}

              {/* Total Hire */}
              {renderSectionHeader(
                "Total Hire",
                expandedSections.hireTotal,
                toggleSection,
                statement.hire_total,
                "hireTotal",
                getHighlightColor("11")
              )}
              {expandedSections.hireTotal && (
                <TableRow>
                  <TableCell colSpan={2} sx={{ p: 0, border: 0 }}>
                    <Box
                      ref={(el) => (sectionRefs.current.hireTotal = el)}
                      sx={{
                        minHeight: sectionHeights.hireTotal
                          ? `${sectionHeights.hireTotal}px`
                          : "auto",
                        width: "100%",
                        backgroundColor: theme.palette.background.level2,
                      }}
                    >
                      <Table sx={{ tableLayout: "fixed", width: "100%" }}>
                        <TableBody>
                          {renderAmountRow(statement.hire_total)}
                        </TableBody>
                      </Table>
                    </Box>
                  </TableCell>
                </TableRow>
              )}

              {/* Payments So Far Section */}
              {renderSectionHeader(
                "Payments So Far",
                expandedSections.payments,
                toggleSection,
                statement.payments_so_far_total,
                "payments",
                getHighlightColor("12"),
                statement.payments_so_far_items
              )}
              {expandedSections.payments && (
                <TableRow>
                  <TableCell colSpan={2} sx={{ p: 0, border: 0 }}>
                    <Box
                      ref={(el) => (sectionRefs.current.payments = el)}
                      sx={{
                        minHeight: sectionHeights.payments
                          ? `${sectionHeights.payments}px`
                          : "auto",
                        width: "100%",
                        backgroundColor: theme.palette.background.level2,
                      }}
                    >
                      <Table sx={{ tableLayout: "fixed", width: "100%" }}>
                        <TableBody>
                          {statement.payments_so_far_items.map((item) =>
                            renderAmountRow(item, 1)
                          )}
                          {renderTotalRow(statement.payments_so_far_total)}
                        </TableBody>
                      </Table>
                    </Box>
                  </TableCell>
                </TableRow>
              )}

              {/* Balance Due */}
              {renderSectionHeader(
                "Balance Due",
                expandedSections.balance,
                toggleSection,
                statement.balance_due,
                "balance",
                getHighlightColor("13")
              )}
              {expandedSections.balance && (
                <TableRow>
                  <TableCell colSpan={2} sx={{ p: 0, border: 0 }}>
                    <Box
                      ref={(el) => (sectionRefs.current.balance = el)}
                      sx={{
                        minHeight: sectionHeights.balance
                          ? `${sectionHeights.balance}px`
                          : "auto",
                        width: "100%",
                        backgroundColor: theme.palette.background.level2,
                      }}
                    >
                      <Table sx={{ tableLayout: "fixed", width: "100%" }}>
                        <TableBody>
                          {renderAmountRow(statement.balance_due)}
                        </TableBody>
                      </Table>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {statement.extraction_comments && (
          <>
            <Typography variant="h5" gutterBottom>
              Extraction Comments:
            </Typography>
            <Paper
              elevation={1}
              sx={{
                p: 2,
                backgroundColor: theme.palette.background.level2,
                whiteSpace: "pre-wrap",
              }}
            >
              {statement.extraction_comments}
            </Paper>
          </>
        )}
      </CardContent>
    </Card>
  );
};

HireStatementView.propTypes = {
  statement: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  prefix: PropTypes.string.isRequired,
  highlightMap: PropTypes.object.isRequired,
  expandedSections: PropTypes.object.isRequired,
  toggleSection: PropTypes.func.isRequired,
  sectionRefs: PropTypes.object.isRequired,
  sectionHeights: PropTypes.object.isRequired,
  sectionDiscrepancyMap: PropTypes.object.isRequired,
};

HireStatementResult.propTypes = {
  comparisonResult: PropTypes.object.isRequired,
  role: PropTypes.string,
};

export default HireStatementResult;
