import axios from "axios";
import applyCaseMiddleware from "axios-case-converter";

import { API_URL } from "./config";

const client = applyCaseMiddleware(axios.create());

export const generateBunkerInstructions = async (
  email_text,
  organisationId
) => {
  const formData = new FormData();

  formData.append("original_text", email_text);
  formData.append("organisation_id", organisationId);

  try {
    const response = await client.post(
      `${API_URL}/bunker_instruction/extract_bunker_instruction`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error generating bunker instructions:", error);
    throw error;
  }
};
