import axios from "axios";
import { API_URL } from "./config";
import {
  getFormattedFileName,
  getFormattedFilenameForTool,
  getResponseFileNameForTool,
} from "../helpers";
import { saveAs } from "file-saver";

export const handleSaveLOI = async (
  wordFiles,
  loiType,
  exportType,
  zipFilename
) => {
  if (exportType !== "PDF" && exportType !== "DOCX") {
    throw new Error(
      `Invalid export type: ${exportType}, Please contact support.`
    );
  }

  const formData = new FormData();
  for (const [filename, value] of Object.entries(wordFiles)) {
    const formattedFilename =
      exportType === "PDF"
        ? getFormattedFilenameForTool(filename, loiType)
        : filename;
    const wordFile = new File([value], formattedFilename);
    formData.append("files", wordFile);
  }

  const endpoint =
    exportType === "PDF"
      ? `${API_URL}/file_conversion/docx2pdf`
      : `${API_URL}/file_conversion/docx2zip`;

  try {
    const response = await axios.post(endpoint, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      withCredentials: true,
      responseType: "blob",
    });

    if (!response?.headers?.["content-type"]) {
      console.error("Missing content-type header in response");
      throw new Error("Unexpected server response. Please contact support.");
    }

    const responseType = response.headers["content-type"];
    const extension = responseType.split("/")[1];

    if (
      (exportType === "PDF" && !["pdf", "zip"].includes(extension)) ||
      (exportType === "DOCX" && !["docx", "zip"].includes(extension))
    ) {
      console.error(`Unsupported file type received: .${extension}`);
      throw new Error("Unexpected server response. Please contact support.");
    }

    const responseBlob = new Blob([response.data], { type: responseType });
    let responseFileName =
      responseType === "PDF"
        ? getResponseFileNameForTool(
            Object.keys(wordFiles)[0],
            extension,
            loiType
          )
        : getFormattedFileName(zipFilename, "", `.${extension}`);

    saveAs(responseBlob, responseFileName);
  } catch (error) {
    console.error(`Error generating LOI from ${exportType} files:`, error);
    alert(
      `Error generating LOI from ${exportType} files, Please contact support.`
    );
    throw error;
  }
};
