import React from "react";
import { Box, Button } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import PropTypes from "prop-types";

const ExportAllButtons = ({ actions, exportToggles }) => {
  const numberOfActiveToggles =
    Object.values(exportToggles).filter(Boolean).length;

  return (
    <Box display="flex" gap={2} my={2}>
      <Button
        variant="contained"
        fullWidth
        onClick={actions.handleExportAllPDF}
        startIcon={<PictureAsPdfIcon />}
        disabled={!Object.values(exportToggles).some((value) => value)}
      >
        Export All ({numberOfActiveToggles}) to PDF
      </Button>
      <Button
        variant="contained"
        fullWidth
        onClick={actions.handleExportAllDocx}
        startIcon={<TextSnippetIcon />}
        disabled={!Object.values(exportToggles).some((value) => value)}
      >
        Export All ({numberOfActiveToggles}) to DOCX
      </Button>
    </Box>
  );
};

ExportAllButtons.propTypes = {
  actions: PropTypes.shape({
    handleExportAllPDF: PropTypes.func.isRequired,
    handleExportAllDocx: PropTypes.func.isRequired,
  }).isRequired,
  exportToggles: PropTypes.object.isRequired,
};

export default ExportAllButtons;
