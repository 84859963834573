import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import { UserContext } from "../../context/UserContext";
import { getFilledChristianiaVITemplate } from "../../templates/ChristianiaVITemplate";
import { getFilledCenturionVITemplate } from "../../templates/CenturionVIScrapTemplate";
import { getFilledCenturionVIColombiaTemplate } from "../../templates/CenturionVIColombiaTemplate";
import { getFilledCenturionVIStandardTemplate } from "../../templates/CenturionVIStandardTemplate";
import { getFilledFalconVITemplate } from "../../templates/FalconVITemplate";
import { renderTextTemplate } from "../../components/generation/EditableFieldTemplate";
import { sendFieldFeedback } from "../../services/feedbackService";
import { handleSaveAsWord } from "../../services/viDocxService";
import { handleSaveAsPDF } from "../../services/viPdfService";
import ExportButtons from "../../components/generation/ExportButtons";
import GenerationCard from "../../components/generation/GenerationCard";
import DocumentViewer from "../../components/common/DocumentViewer";
import ShipsterCard from "../../components/common/ShipsterCard";
import PreviewWrapper from "../../components/generation/PreviewWrapper";
import { getOrganisationFromUser } from "../../utils/organisationUtils";

const Recap2InstructionsResult = ({ response, file, template }) => {
  const { user } = useContext(UserContext);
  const [trackedChanges, setTrackedChanges] = useState({});
  const [isEditable, setIsEditable] = useState(true);
  const [formData, setFormData] = useState({});

  const extractFormData = useCallback((fixureRecapDetails) => {
    const fields = [
      "cpDate",
      "charterer",
      "laycan",
      "speed",
      "cleaning",
      "heating",
      "cargo",
      "loadPortAgents",
      "loadPortAgentEmail",
      "dischargePortAgents",
      "dischargePortAgentEmail",
      "portOfLoading",
      "portOfDischarge",
      "portOfLoadingShort",
      "portOfDischargeShort",
      "previousPort",
      "brokersEmail",
      "brokers",
      "dischargeRate",
      "loadRate",
      "segregation",
      "vessel",
    ];

    return fields.reduce((acc, field) => {
      acc[field] = fixureRecapDetails?.[field]?.value || "MISSING";
      return acc;
    }, {});
  }, []);

  useEffect(() => {
    if (response?.fixureRecapDetails) {
      setFormData({
        ...extractFormData(response.fixureRecapDetails),
        missingValue: "Input Needed",
      });
    }
  }, [response, extractFormData]);

  const trackChange = useCallback(
    (field, newValue, parentField = "fixureRecapDetails") => {
      setTrackedChanges((prevChanges) => {
        const originalValue = response[parentField]?.[field]?.value;
        return originalValue !== newValue
          ? { ...prevChanges, [field]: { value: newValue } }
          : prevChanges;
      });
    },
    [response]
  );

  const updateFormData = useCallback(
    (key, value) => {
      trackChange(key, value);
      setFormData((prevData) => ({ ...prevData, [key]: value }));
    },
    [trackChange]
  );

  const sendTrackChangesToBackend = useCallback(async () => {
    try {
      await sendFieldFeedback(
        getOrganisationFromUser(user),
        response.requestId,
        trackedChanges,
        "VI"
      );
    } catch (error) {
      console.log(error);
    }
  }, [user, response, trackedChanges]);

  const handleCopyToClipboard = useCallback(() => {
    const element = document.getElementById("text-content");
    if (!element) return;

    const textToCopy = element.innerText || element.textContent;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => alert("Text copied to clipboard!"))
      .catch((err) => console.error("Failed to copy text: ", err));

    sendTrackChangesToBackend();
  }, [sendTrackChangesToBackend]);

  const toggleEdit = () => {
    setIsEditable(!isEditable);
  };

  const renderedTemplates = useMemo(() => {
    const fieldTemplates = [
      { key: "cpDate", label: "CP Date" },
      { key: "portOfLoading", label: "Port of Loading" },
      { key: "portOfDischarge", label: "Port of Discharge" },
      { key: "portOfLoadingShort", label: "Port of Loading Short Name" },
      {
        key: "portOfDischargeShort",
        label: "Port of Discharge Short Name",
      },
      { key: "previousPort", label: "Starting point of vessel" },
      { key: "charterer", label: "Charterer Name" },
      { key: "cargo", label: "Cargo Description" },
      { key: "laycan", label: "Laycan" },
      { key: "speed", label: "Speed" },
      { key: "heating", label: "Heating" },
      { key: "cleaning", label: "Cleaning Details" },
      { key: "loadPortAgents", label: "Load Port Agents" },
      { key: "loadPortAgentEmail", label: "Load Port Agent Email" },
      { key: "dischargePortAgents", label: "Discharge Port Agent" },
      {
        key: "dischargePortAgentEmail",
        label: "Discharge Port Agent Email",
      },
      { key: "loadRate", label: "Load Rate" },
      { key: "brokers", label: "Brokers" },
      { key: "brokersEmail", label: "Brokers Email" },
      { key: "vessel", label: "Vessel" },
      { key: "dischargeRate", label: "Discharge Rate" },
    ];
    return fieldTemplates.reduce((acc, { key, label }) => {
      acc[key] = renderTextTemplate(
        formData[key],
        response?.fixureRecapDetails?.[key]?.context,
        label,
        key,
        response?.fixureRecapDetails?.[key]?.confidence,
        isEditable,
        updateFormData
      );
      return acc;
    }, {});
  }, [formData, response, isEditable, updateFormData]);

  const missingValueTemplate = renderTextTemplate(
    "Input Needed",
    null,
    "Data to be filled out",
    "LOW",
    null,
    isEditable,
    updateFormData
  );

  const renderGeneratedTemplate = () => {
    if (!user || !response?.fixureRecapDetails) return;

    // Common properties for all templates
    const commonProps = {
      cpDate: renderedTemplates.cpDate,
      cargo: renderedTemplates.cargo,
      laycan: renderedTemplates.laycan,
      speed: renderedTemplates.speed,
      vessel: renderedTemplates.vessel,
      loadRate: renderedTemplates.loadRate,
      dischargeRate: renderedTemplates.dischargeRate,
      loadingAgents: renderedTemplates.loadPortAgents,
      loadAgentEmail: renderedTemplates.loadPortAgentEmail,
      portOfLoading: renderedTemplates.portOfLoading,
      portOfDischarge: renderedTemplates.portOfDischarge,
      brokersEmail: renderedTemplates.brokersEmail,
      charterer: renderedTemplates.charterer,
      cleaning: renderedTemplates.cleaning,
      heating: renderedTemplates.heating,
      dischargeAgents: renderedTemplates.dischargePortAgents,
      dischargeAgentEmail: renderedTemplates.dischargePortAgentEmail,
      previousPort: renderedTemplates.previousPort,
      broker: renderedTemplates.brokers,
      portOfLoadingShort: renderedTemplates.portOfLoadingShort,
      portOfDischargeShort: renderedTemplates.portOfDischargeShort,
      missingValue: missingValueTemplate,
    };

    // Template-specific property mapping
    const templateMap = {
      CHRISTIANIA_VOYAGE_INSTRUCTIONS: getFilledChristianiaVITemplate({
        ...commonProps,
        formData,
      }),
      CENTURION_SCRAP_VOYAGE_INSTRUCTIONS:
        getFilledCenturionVITemplate(commonProps),
      CENTURION_COLOMBIA_VOYAGE_INSTRUCTIONS:
        getFilledCenturionVIColombiaTemplate(commonProps),
      CENTURION_STANDARD_VOYAGE_INSTRUCTIONS:
        getFilledCenturionVIStandardTemplate(commonProps),
      FALCON_VOYAGE_INSTRUCTIONS: getFilledFalconVITemplate(commonProps),
    };

    return (
      templateMap[template] ||
      templateMap["CENTURION_STANDARD_VOYAGE_INSTRUCTIONS"]
    );
  };

  const exportActions = useMemo(
    () => ({
      handleCopy: handleCopyToClipboard,
      handleExportPDF: () => handleSaveAsPDF(formData, user.orgId, template),
      handleExportDocx: () => handleSaveAsWord(formData, user.orgId, template),
    }),
    [handleCopyToClipboard, formData, user.orgId, template]
  );

  return (
    <Box>
      <PreviewWrapper
        generationCard={
          <GenerationCard
            title={`Voyage Instructions`}
            multiple={false}
            toggleEdit={toggleEdit}
            id={response.requestId}
            handleToggleExport={() => {}}
          >
            {renderGeneratedTemplate()}
          </GenerationCard>
        }
        file={file}
      >
        <ShipsterCard level={0} title="Source Preview">
          <DocumentViewer file={file} />
        </ShipsterCard>
      </PreviewWrapper>
      <ExportButtons actions={exportActions} validation={false} />
    </Box>
  );
};

Recap2InstructionsResult.propTypes = {
  response: PropTypes.object.isRequired,
  file: PropTypes.string.isRequired,
  template: PropTypes.string,
};

export default Recap2InstructionsResult;
