import React, { useState, useContext } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Collapse,
  Alert,
  Typography,
  Grid2,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { compareHireStatementsV3 } from "../../services/hireService";
import HireStatementResult from "./HireStatementResult";
import Feedback from "../../components/common/Feedback";
import TwoDocumentUpload from "../../components/common/TwoDocumentUpload";
import DocumentUploader from "../../components/common/DocumentUploader";
import ToolHeader from "../../components/common/ToolHeader";
import ToolStepProgressBar from "../../components/common/ToolStepProgressBar";
import Step1Buttons from "../../components/common/Step1Buttons";
import { UserContext } from "../../context/UserContext";
import CloseFooter from "../../components/common/CloseFooter";
import InfoIcon from "@mui/icons-material/Info";
import { useTheme } from "@emotion/react";
import { getOrganisationFromUser } from "../../utils/organisationUtils";
import LoadingOverlay from "../../components/common/LoadingOverlay";
import { pdfDocxFileTypes, getToolMessages } from "../../utils/loaderUtils";

const HireStatement = () => {
  const theme = useTheme();
  const { user } = useContext(UserContext);

  const [ownerStatement, setOwnerStatement] = useState(null);
  const [chartererStatement, setChartererStatement] = useState(null);
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const [role, setRole] = useState("owner");
  const [useOCR, setUseOCR] = useState(true);
  const [useVision, setUseVision] = useState(true);
  const [useContentCache, setUseContentCache] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [result, setResult] = useState(null);
  const steps = ["Upload & Compare", "Results"];
  const [currentStep, setCurrentStep] = useState(0);

  const handleStepChange = (step) => {
    if (step <= currentStep) setCurrentStep(step);
  };

  const handleAdvancedOptionsClick = () => {
    setShowAdvancedOptions(!showAdvancedOptions);
  };

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  // Add handler for file upload
  const handleOwnerFileUpload = (file) => {
    setOwnerStatement(file);
  };

  const handleChartererFileUpload = (file) => {
    setChartererStatement(file);
  };

  const handleCompare = async () => {
    if (!ownerStatement || !chartererStatement) {
      setError("Please upload a file in both sections before comparing.");
      return;
    }

    setLoading(true);
    setError(null); // Clear any previous errors
    setResult(null); // Clear previous result

    try {
      // Validate files before sending
      if (!(ownerStatement instanceof File)) {
        console.error("Invalid owner file:", ownerStatement);
        throw new Error("Invalid owner statement file");
      }
      if (!(chartererStatement instanceof File)) {
        console.error("Invalid charterer file:", chartererStatement);
        throw new Error("Invalid charterer statement file");
      }

      const comparisonResult = await compareHireStatementsV3(
        ownerStatement,
        chartererStatement,
        useOCR,
        useVision,
        useContentCache,
        getOrganisationFromUser(user),
        role
      );
      setResult(comparisonResult);
      setCurrentStep(1);
    } catch (error) {
      console.error("Comparison error:", error);
      setError(
        error.message ||
          "An error occurred while comparing the statements. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box p={3}>
      <ToolHeader
        title={"Hire Statement Comparison"}
        subtext={
          "Compare owner and charterer hire statements to identify discrepancies"
        }
      />
      <ToolStepProgressBar
        steps={steps}
        currentStep={currentStep}
        onStepChange={handleStepChange}
      />

      <Alert
        icon={
          <InfoIcon
            fontSize="inherit"
            sx={{ color: theme.palette.semantic.info }}
          />
        }
        sx={{
          border: `1px solid ${theme.palette.semantic.info}`,
          borderLeft: `8px solid ${theme.palette.semantic.info}`,
          borderRadius: "4px",
          mb: 2,
        }}
      >
        <Typography variant="body1" sx={{ color: "white" }}>
          This is a beta version of the Hire Statement Comparison Tool. The
          analysis is meant as a helpful first draft and should not be
          considered financial advice. Please verify all findings before taking
          action.
          <br />
          <br />
          <strong>Note:</strong> Processing hire statements can take up to 15
          minutes. After submission, you may need to check back later to see
          your results.
        </Typography>
      </Alert>

      {(!result || currentStep === 0) && (
        <>
          {error && (
            <Alert severity="error" sx={{ my: 2 }}>
              {error}
            </Alert>
          )}

          <FormControl fullWidth sx={{ mb: 3 }}>
            <InputLabel id="role-label">Your Role</InputLabel>
            <Select
              labelId="role-label"
              id="role-select"
              value={role}
              label="Your Role"
              onChange={handleRoleChange}
            >
              <MenuItem value="owner">Owner</MenuItem>
              <MenuItem value="charterer">Charterer</MenuItem>
            </Select>
          </FormControl>

          <TwoDocumentUpload>
            <Grid2 size={6}>
              <DocumentUploader
                files={ownerStatement}
                setFiles={handleOwnerFileUpload}
                title="Upload Owner Statement PDF"
                setResult={setResult}
                allowedFileTypes={pdfDocxFileTypes}
                singleFile={true}
              />
            </Grid2>

            <Grid2 size={6}>
              <DocumentUploader
                files={chartererStatement}
                setFiles={handleChartererFileUpload}
                title="Upload Charterer Statement PDF"
                setResult={setResult}
                allowedFileTypes={pdfDocxFileTypes}
                singleFile={true}
              />
            </Grid2>
          </TwoDocumentUpload>

          {user.isAdmin && (
            <>
              {/* Advanced Options Toggle */}
              <Button
                variant="outlined"
                onClick={handleAdvancedOptionsClick}
                sx={{ mt: 3 }}
              >
                {showAdvancedOptions
                  ? "Hide Advanced Options"
                  : "Show Advanced Options"}
              </Button>

              {/* Advanced Options Section */}
              <Collapse in={showAdvancedOptions}>
                <Box sx={{ border: "1px solid #ccc", padding: "10px", mt: 2 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={useOCR}
                        onChange={(e) => setUseOCR(e.target.checked)}
                      />
                    }
                    label="Use OCR for Hire"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={useVision}
                        onChange={(e) => setUseVision(e.target.checked)}
                      />
                    }
                    label="Use Vision for Hire"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={useContentCache}
                        onChange={(e) => setUseContentCache(e.target.checked)}
                      />
                    }
                    label="Use Cache for Hire"
                  />
                </Box>
              </Collapse>
            </>
          )}

          {/* Compare Button */}
          <Step1Buttons
            title={"Compare Hire Statements"}
            validation={!ownerStatement || !chartererStatement || loading}
            handleMoveNextStep={handleCompare}
          />

          {loading && (
            <LoadingOverlay
              messages={getToolMessages["HIRE_STATEMENT_COMPARISON"]}
            />
          )}
        </>
      )}

      {result && currentStep === 1 && (
        <>
          <HireStatementResult
            comparisonResult={result}
            role={role}
          ></HireStatementResult>
          <Feedback requestId={result.request_id} type={"Hire"}></Feedback>
          <CloseFooter />
        </>
      )}
    </Box>
  );
};

export default HireStatement;
