import { getDefaultNoBillLOITemplate } from "./defaultNoBillLOITemplate.js";
import { getDefaultOtherPortLOITemplate } from "./defaultOtherPortLOITemplate.js";
import { getDefaultOtherPortNoBillLOITemplate } from "./defaultOtherPortNoBillLOITemplate.js";
import { getChristianiaVITemplate } from "./ChristianiaVITemplate.js";
import { getCenturionVITemplate } from "./CenturionVIScrapTemplate.js";
import { getCenturionVIColombiaTemplate } from "./CenturionVIColombiaTemplate.js";
import { getCenturionVIStandardTemplate } from "./CenturionVIStandardTemplate.js";
import { getFalconVITemplate } from "./FalconVITemplate.js";
import { getNavigatorNoBillLOITemplate } from "./navigator_lois/noBillLOITemplate.js";
import { getNavigatorOtherPortNoBillLOITemplate } from "./navigator_lois/otherPortNoBillLOITemplate.js";
import { getNavigatorSubstituteBLLOITemplate } from "./navigator_lois/substituteBillLOITemplate.js";
import { getNavigatorBendingCargoLOITemplate } from "./navigator_lois/blendingCargoLOITemplate.js";
import { getNavigatorBunkerInstructionsTemplate } from "./NavigatorBunkerInstructionsTemplate.js";
export const defaultLOITemplates = [
  { label: "No bill", type: "DEFAULT_LOI_NO_BILL" },
  { label: "Other port", type: "DEFAULT_LOI_OTHER_PORT" },
  { label: "No bill and other port", type: "DEFAULT_LOI_NO_BILL_OTHER_PORT" },
];

export const navigatorLOITemplates = [
  { label: "Delivery of cargo without BL", type: "NAVIGATOR_LOI_NO_BILL" },
  { label: "No BL and other port", type: "NAVIGATOR_LOI_OTHER_PORT_NO_BILL" },
  { label: "Substitute BL", type: "NAVIGATOR_LOI_SUBSTITUTE_BL" },
  {
    label: "Commingling or blending cargo",
    type: "NAVIGATOR_LOI_BLENDING_CARGO",
  },
];

export const loiTemplateChoices = {
  navigator: navigatorLOITemplates,
  default: defaultLOITemplates,
};

export const centurionVITemplates = [
  { label: "Scrap", type: "CENTURION_SCRAP_VOYAGE_INSTRUCTIONS" },
  { label: "Colombia", type: "CENTURION_COLOMBIA_VOYAGE_INSTRUCTIONS" },
  { label: "Standard", type: "CENTURION_STANDARD_VOYAGE_INSTRUCTIONS" },
];

export const voyageInstructionsChoices = {
  centurion: centurionVITemplates,
  christiania: [
    {
      label: "Christiania",
      type: "CHRISTIANIA_VOYAGE_INSTRUCTIONS",
    },
  ],
  falcon: [
    {
      label: "Falcon",
      type: "FALCON_VOYAGE_INSTRUCTIONS",
    },
  ],
  default: [
    {
      label: "Default",
      type: "CENTURION_STANDARD_VOYAGE_INSTRUCTIONS",
    },
  ],
};

export const getTemplateFunctions = {
  DEFAULT_LOI_NO_BILL: getDefaultNoBillLOITemplate,
  DEFAULT_LOI_OTHER_PORT: getDefaultOtherPortLOITemplate,
  DEFAULT_LOI_NO_BILL_OTHER_PORT: getDefaultOtherPortNoBillLOITemplate,

  NAVIGATOR_LOI_NO_BILL: getNavigatorNoBillLOITemplate,
  NAVIGATOR_LOI_OTHER_PORT_NO_BILL: getNavigatorOtherPortNoBillLOITemplate,
  NAVIGATOR_LOI_SUBSTITUTE_BL: getNavigatorSubstituteBLLOITemplate,
  NAVIGATOR_LOI_BLENDING_CARGO: getNavigatorBendingCargoLOITemplate,

  CHRISTIANIA_VOYAGE_INSTRUCTIONS: getChristianiaVITemplate,
  CENTURION_SCRAP_VOYAGE_INSTRUCTIONS: getCenturionVITemplate,
  CENTURION_COLOMBIA_VOYAGE_INSTRUCTIONS: getCenturionVIColombiaTemplate,
  CENTURION_STANDARD_VOYAGE_INSTRUCTIONS: getCenturionVIStandardTemplate,
  FALCON_VOYAGE_INSTRUCTIONS: getFalconVITemplate,

  NAVIGATOR_BUNKER_INSTRUCTIONS: getNavigatorBunkerInstructionsTemplate,
};

// Splits text into lines with newlines as ""
export const splitTextToLines = (text) => {
  const result = text
    .split("\n")
    .map((line) => line.trim())
    .filter((line) => line !== "" || line.trim().length === 0);

  // Removes first and last empty lines
  if (result[0] === "") {
    result.shift();
  }
  if (result[result.length - 1] === "") {
    result.pop();
  }

  return result;
};
