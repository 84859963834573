import React from "react";
import { Typography, List, ListItem, ListItemText } from "@mui/material";
import PropTypes from "prop-types";

export const Section = ({ title, children, textDecoration, bold }) => (
  <>
    <Typography
      variant="body1"
      style={{
        marginTop: "16px",
        textDecoration,
        fontWeight: bold ? "bold" : "normal",
      }}
    >
      {title}
    </Typography>
    {children}
  </>
);
Section.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  textDecoration: PropTypes.string,
  bold: PropTypes.bool,
};

export const ListSection = ({ items }) => (
  <>
    <List style={{ listStyleType: "none", paddingLeft: 0 }}>
      {items.map((item, index) => (
        <ListItem
          key={`list-${items[0]}-${index}`}
          style={{ display: "list-item", paddingTop: 0, paddingBottom: 0 }}
        >
          <ListItemText primary={item} />
        </ListItem>
      ))}
    </List>
  </>
);
ListSection.propTypes = {
  items: PropTypes.array.isRequired,
};

export const DetailItem = ({ label, value, bold }) => (
  <Typography variant="body1">
    <span style={{ display: "inline" }}>
      <span style={{ fontWeight: bold ? "bold" : "normal" }}>{label}</span>:{" "}
      {value}
    </span>
  </Typography>
);
DetailItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  bold: PropTypes.bool,
};

export const TopPaddedText = ({ text, textDecoration, italics, bold }) => (
  <Typography
    variant="body1"
    style={{
      marginTop: "16px",
      textDecoration,
      fontStyle: italics ? "italic" : "normal",
      fontWeight: bold ? "bold" : "normal",
    }}
  >
    {text}
  </Typography>
);
TopPaddedText.propTypes = {
  text: PropTypes.string.isRequired,
  textDecoration: PropTypes.string,
  italics: PropTypes.bool,
  bold: PropTypes.bool,
};

export const NoPaddedText = ({ text, italics, bold }) => (
  <Typography
    variant="body1"
    style={{
      fontStyle: italics ? "italic" : "normal",
      fontWeight: bold ? "bold" : "normal",
    }}
  >
    {text}
  </Typography>
);
NoPaddedText.propTypes = {
  text: PropTypes.string.isRequired,
  italics: PropTypes.bool,
  bold: PropTypes.bool,
};
