import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import LoginScreen from "./views/auth/LoginScreen";
import Dashboard from "./views/Dashboard";
import CargoComparison from "./views/tools/CargoComparison";
import MatesReceiptsComparison from "./views/tools/MatesReceiptsComparison";
import BLMRComparison from "./views/tools/BLMRComparison";
import HireStatement from "./views/tools/HireStatement";
import SOFComparison from "./views/tools/SOFComparison";
import BL2LOI from "./views/tools/BL2LOI";
import LOI2LOI from "./views/tools/LOI2LOI";
import Recap2Instructions from "./views/tools/Recap2Instructions";
import BunkerInstructions from "./views/tools/BunkerInstructions";
import { UserContext } from "./context/UserContext";
import { Box, Avatar, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";

const Header = () => {
  const { user, clearUser } = useContext(UserContext);
  const navigate = useNavigate();
  const theme = useTheme();

  const handleLogout = () => {
    clearUser();
    navigate("/");
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      p={2}
    >
      <Avatar
        src="/assets/shipsterIcon.png"
        alt="SC"
        sx={{ width: 50, height: 50 }}
      />
      <Box display="flex" alignItems="center">
        <Typography
          variant="body1"
          sx={{ cursor: "pointer", mr: 1.5 }}
          onClick={handleLogout}
        >
          {user?.fullName}
        </Typography>
        <Avatar
          sx={{
            mr: 2,
            width: 40,
            height: 40,
            cursor: "pointer",
            backgroundImage: theme.palette.gradients.buttons,
            color: theme.palette.text.primary,
            "&:hover": {
              backgroundImage: theme.palette.gradients.buttons,
            },
          }}
        >
          {user?.fullName?.[0]}
        </Avatar>
      </Box>
    </Box>
  );
};

const Footer = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        mt: "auto", // Push the footer to the bottom when content is small
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        p: 4,
        px: 4, // Adds horizontal padding to ensure spacing
        boxSizing: "border-box", // Ensures padding is accounted for in layout
      }}
    >
      {/* Left-aligned text */}
      <Typography variant="small" sx={{ color: theme.palette.text.secondary }}>
        © 2025 Shipster - All rights reserved
      </Typography>

      {/* Right-aligned links */}
      <Box
        sx={{
          display: "flex",
          gap: 2,
          flexWrap: "wrap", // Ensures wrapping if screen size is too small
        }}
      >
        <Typography
          variant="small"
          sx={{
            color: theme.palette.text.secondary,
            cursor: "pointer",
            "&:hover": { textDecoration: "underline" },
          }}
        >
          About
        </Typography>
        <Typography
          variant="small"
          sx={{
            color: theme.palette.text.secondary,
            cursor: "pointer",
            "&:hover": { textDecoration: "underline" },
          }}
        >
          Help & Support
        </Typography>
      </Box>
    </Box>
  );
};

function App() {
  const { user } = useContext(UserContext);

  const isRouteAccessible = (restrictedTo) => {
    if (!restrictedTo) return true;
    return restrictedTo.includes(user?.orgId);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <Router>
        {user && <Header />}
        <Box sx={{ flex: 1 }}>
          <Routes>
            {!user ? (
              <Route path="/" element={<LoginScreen />} />
            ) : (
              <>
                <Route path="/" element={<Navigate to="/dashboard" />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route
                  path="/dashboard/cargo-comparison"
                  element={<CargoComparison />}
                />
                <Route
                  path="/dashboard/mr-comparison"
                  element={<MatesReceiptsComparison />}
                />
                <Route
                  path="/dashboard/mr-bl-comparison"
                  element={<BLMRComparison />}
                />
                <Route
                  path="/dashboard/hire-comparison"
                  element={<HireStatement />}
                />
                <Route
                  path="/dashboard/sof-comparison"
                  element={<SOFComparison />}
                />
                <Route path="/dashboard/bl-to-loi" element={<BL2LOI />} />
                <Route path="/dashboard/loi-to-loi" element={<LOI2LOI />} />
                <Route
                  path="/dashboard/recap-to-instructions"
                  element={<Recap2Instructions />}
                />
                <Route
                  path="/dashboard/bunker-instructions"
                  element={
                    isRouteAccessible(["navigator"]) ? (
                      <BunkerInstructions />
                    ) : (
                      <Navigate to="/dashboard" replace />
                    )
                  }
                />
              </>
            )}
          </Routes>
        </Box>
        <Footer />
      </Router>
    </Box>
  );
}

export default App;
