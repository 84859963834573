import React from "react";
import {
  Box,
  Card,
  CardContent,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";

const TextInputCard = ({ title, value, onChange, placeholder, rows = 25 }) => {
  const theme = useTheme();

  return (
    <Box sx={{ mb: 3 }}>
      <Card
        sx={{
          background: theme.palette.background.level1,
          my: 2,
          borderRadius: "20px",
        }}
      >
        <CardContent>
          <Box sx={{ mb: 3 }}>
            <Typography variant="intro" sx={{ fontWeight: 600, mb: 3 }}>
              {title}
            </Typography>
          </Box>

          <TextField
            fullWidth
            multiline
            rows={rows}
            variant="outlined"
            value={value}
            onChange={onChange}
            placeholder={placeholder}
          />
        </CardContent>
      </Card>
    </Box>
  );
};

TextInputCard.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
};

export default TextInputCard;
