import axios from "axios";
import { API_URL } from "./config";
import { handleExportToWordBlob } from "./viDocxService";
import { saveAs } from "file-saver";

export const handleSaveAsPDF = async (formData, organisationId, template) => {
  const wordDocBlob = await handleExportToWordBlob(
    formData,
    organisationId,
    template
  );
  const fileName = `${formData.vessel || "unknown"}-${formData.date || "unknown"}-${formData.port || "unknown"}-BUNKER INSTRUCTIONS.pdf`;
  const wordFile = new File([wordDocBlob], "Bunker_Instructions.docx");
  const formDataObj = new FormData();
  formDataObj.append("files", wordFile);

  try {
    const response = await axios.post(
      `${API_URL}/file_conversion/docx2pdf`,
      formDataObj,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
        responseType: "blob",
      }
    );

    const pdfBlob = new Blob([response.data], { type: "application/pdf" });
    saveAs(pdfBlob, fileName);
  } catch (error) {
    console.error("Error generating bunker instructions:", error);
    throw error;
  }
};
