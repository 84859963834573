import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import TableChartIcon from "@mui/icons-material/TableChart";

const ComparisonTableActions = ({
  filter,
  filterOptions,
  onFilterChange,
  onCopy,
  onSavePdf,
  onSaveExcel,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 2,
        marginTop: 4,
      }}
    >
      {/* Filter Options */}
      <RadioGroup row value={filter} onChange={onFilterChange} sx={{ gap: 2 }}>
        {filterOptions.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio />}
            label={<Typography>{option.label}</Typography>}
          />
        ))}
      </RadioGroup>

      {/* Action Buttons */}
      <Box sx={{ display: "flex", gap: 2 }}>
        <Button
          variant="outlined"
          startIcon={<ContentCopyIcon />}
          onClick={() => onCopy()}
          sx={{ textTransform: "none" }}
        >
          Copy to Clipboard
        </Button>
        <Button
          variant="contained"
          startIcon={<PictureAsPdfIcon />}
          onClick={() => onSavePdf()}
          sx={{ textTransform: "none" }}
        >
          Save as PDF
        </Button>
        <Button
          variant="contained"
          startIcon={<TableChartIcon />}
          onClick={() => onSaveExcel()}
          sx={{ textTransform: "none" }}
        >
          Save as Excel
        </Button>
      </Box>
    </Box>
  );
};

ComparisonTableActions.propTypes = {
  filter: PropTypes.string.isRequired,
  filterOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onCopy: PropTypes.func.isRequired,
  onSavePdf: PropTypes.func.isRequired,
  onSaveExcel: PropTypes.func.isRequired,
};

export default ComparisonTableActions;
