import React, {
  useState,
  useEffect,
  useContext,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from "react";
import {
  Box,
  Divider,
  Typography,
  Grid2,
  List,
  ListItem,
  ListItemText,
  useTheme,
} from "@mui/material";
import { handleExportToWordBlobLOI } from "../../services/loiDocxService";
import { handleSaveLOI } from "../../services/loiExportService";
import { sendFieldFeedback } from "../../services/feedbackService";
import { UserContext } from "../../context/UserContext";
import { getTemplateFunctions } from "../../templates/utils";
import { contentStyle } from "../../styles/templateStyles";
import { getFormattedFileName } from "../../helpers";
import PropTypes from "prop-types";
import { renderTextTemplate } from "./EditableFieldTemplate";
import GenerationCard from "./GenerationCard";
import ExportButtons from "./ExportButtons";
import Feedback from "../common/Feedback";
import { saveAs } from "file-saver";
import { getOrganisationFromUser } from "../../utils/organisationUtils";
import DocumentViewer from "../common/DocumentViewer";
import ShipsterCard from "../common/ShipsterCard";
import PreviewWrapper from "./PreviewWrapper";
import { getZipFilename } from "../../helpers";

const Indemnity = forwardRef(
  (
    {
      details,
      staticData,
      requestId,
      multiple,
      requestorName,
      setRequestorName,
      requestorDesignation,
      setRequestorDesignation,
      ownerName,
      setOwnerName,
      ownerAddress,
      setOwnerAddress,
      isLoi2Loi,
    },
    ref
  ) => {
    const [trackedChanges, setTrackedChanges] = useState({});
    const { user } = useContext(UserContext);
    const [template, setTemplate] = useState(null);
    const [isEditable, setIsEditable] = useState(
      !multiple.isMultipleGeneration
    );
    const theme = useTheme();

    const strongStyle = {
      color: theme.palette.semantic.success,
      fontWeight: "bold",
      textDecoration: "underline",
      textDecorationStyle: "dotted",
    };

    // Helper function to get the value or return "MISSING"
    const getValue = useCallback(
      (key) => {
        const value = details[key]?.value;
        return value || "MISSING";
      },
      [details]
    );

    const toggleEdit = () => {
      setIsEditable(!isEditable);
    };

    // Function to add or update tracked changes with document IDs
    const trackChange = (field, newValue) => {
      setTrackedChanges((prevChanges) => {
        const originalValue = formData[field];
        // Only add to trackedChanges if the value is actually changed
        if (originalValue !== newValue) {
          return {
            ...prevChanges,
            [field]: { value: newValue },
          };
        } else {
          // Remove from trackedChanges if it reverts back to original
          // eslint-disable-next-line no-unused-vars
          const { [field]: _, ...remainingChanges } = prevChanges;
          return remainingChanges;
        }
      });
    };

    const sendTrackChangesToBackend = async () => {
      try {
        await sendFieldFeedback(
          getOrganisationFromUser(user),
          requestId,
          trackedChanges,
          "MR"
        );
      } catch (error) {
        console.log(error);
      }
    };

    const getWordPackageDefaultLOI = async () => {
      const wordDocBlob = await handleExportToWordBlobLOI(formData, isLoi2Loi);
      const filename = getFormattedFileName(
        staticData.file?.name || "",
        "LOI_",
        ".docx"
      );
      return {
        wordDocBlob,
        filename,
        zipFilename: getZipFilename("DOCX", formData, "DEFAULT_LOI"),
      };
    };

    const getPDFPackageDefaultLOI = async () => {
      const wordDocBlob = await handleExportToWordBlobLOI(formData, isLoi2Loi);
      return {
        wordDocBlob,
        filename: staticData.file?.name || "",
        zipFilename: getZipFilename("PDF", formData, "DEFAULT_LOI"),
      };
    };

    const handleSaveAsWordLOI = async () => {
      try {
        sendTrackChangesToBackend();
        const { wordDocBlob, filename } = await getWordPackageDefaultLOI();
        saveAs(wordDocBlob, filename);
      } catch (error) {
        console.error("Error generating Word document:", error);
      }
    };

    const handleSaveAsPDFLOI = async () => {
      sendTrackChangesToBackend();
      const { wordDocBlob, filename, zipFilename } =
        await getPDFPackageDefaultLOI();
      await handleSaveLOI(
        { [filename]: wordDocBlob },
        "DEFAULT_LOI",
        "PDF",
        zipFilename
      );
    };

    useImperativeHandle(ref, () => ({
      handleSaveAsWordLOI,
      handleSaveAsPDFLOI,
      sendTrackChangesToBackend,
      getWordPackageDefaultLOI,
      getPDFPackageDefaultLOI,
    }));

    const getDeliveryPlace = () => {
      if (isLoi2Loi) {
        return getValue("delivery_place");
      } else {
        if (staticData.loiTemplate.type === "DEFAULT_LOI_NO_BILL") {
          return getValue("delivery_place") !== "MISSING"
            ? getValue("delivery_place")
            : getValue("port_of_discharge") !== "MISSING"
              ? getValue("port_of_discharge")
              : "MISSING";
        } else if (
          staticData.loiTemplate.type === "DEFAULT_LOI_OTHER_PORT" ||
          staticData.loiTemplate.type === "DEFAULT_LOI_NO_BILL_OTHER_PORT"
        ) {
          return "MISSING";
        } else {
          return getValue("delivery_place");
        }
      }
    };

    const [formData, setFormData] = useState({
      currentDate: staticData.currentDate,
      vessel: getValue("vessel_name"),
      owners: getValue("owners_managers"),
      ownerName: ownerName ? ownerName : getValue("owners_managers"),
      ownerAddress: ownerAddress,
      portLoading: getValue("port_of_loading"),
      portDischarge: getValue("port_of_discharge"),
      cargo: getValue("cargo_description"),
      cargoQuantity: getValue("cargo_quantity"),
      billOfLadingPlaceAndDateOfIssue: getValue(
        "bill_of_lading_place_and_date_of_issue"
      ),
      billOfLadingIdentificationNumber: getValue(
        "bill_of_lading_identification_number"
      ),
      billOfLadingDescription: getValue("bill_of_lading_description"),
      consignee: getValue("consignee_details"),
      shipper: getValue("shipper_details"),
      deliveryParty: getValue("delivery_party"),
      requestorParty: staticData.requestorCompany,
      requestorName: requestorName,
      requestorDesignation: requestorDesignation,
      deliveryPlace: getDeliveryPlace(),
      letterhead: staticData.letterheadImage,
      loiTemplate: staticData.loiTemplate,
      missingValue: getValue("missing_value"),
      charterPartyName: getValue("charter_party_name"),
      charterDate: getValue("charter_date"),
    });

    // useEffect to update formData.letterhead whenever letterheadImage changes
    useEffect(() => {
      setFormData((prevFormData) => ({
        ...prevFormData,
        letterhead: staticData.letterheadImage,
        loiTemplate: staticData.loiTemplate,
        requestorName: requestorName,
        requestorDesignation: requestorDesignation,
        ownerName: ownerName ? ownerName : getValue("owners_managers"),
        ownerAddress: ownerAddress,
      }));
    }, [
      staticData.letterheadImage,
      staticData.loiTemplate,
      requestorName,
      requestorDesignation,
      ownerName,
      ownerAddress,
      getValue,
    ]);

    // useEffect to update template whenever loiTemplate changes
    // separate to prevent infinite rerenders
    useEffect(() => {
      if (!staticData.loiTemplate) return;

      // Gets the template based on the template type
      const newTemplate = getTemplateFunctions[staticData.loiTemplate.type]({
        shipper: formData.shipper,
        consignee: formData.consignee,
        requestorParty: formData.requestorParty,
        deliveryParty: formData.deliveryParty,
        deliveryPlace: formData.deliveryPlace,
        requestorName: formData.requestorName,
        requestorDesignation: formData.requestorDesignation,
        charterPartyName: formData.charterPartyName,
      });

      setTemplate(newTemplate);
    }, [formData, staticData.letterheadImage, staticData.loiTemplate]);

    const handleCopyToClipboard = () => {
      const element = document.getElementById(`text-content-${details.doc_id}`);
      const textToCopy = element.innerText || element.textContent; // Get only the text content
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          alert("Text copied to clipboard!");
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
        });
      sendTrackChangesToBackend();
    };

    // Function to update formData for simple fields
    const updateFormData = (key, value) => {
      setFormData((prevData) => {
        // Track changes before updating formData (no document ID for simple fields)
        trackChange(key, value);
        if (key === "requestorName") {
          setRequestorName(value); // also update requestorName in parent so all LOIs have the same requestorName
        }
        if (key === "requestorDesignation") {
          setRequestorDesignation(value); // also update requestorDesignation in parent so all LOIs have the same requestorDesignation
        }
        if (key === "ownerName") {
          setOwnerName(value); // also update ownerName in parent so all LOIs have the same ownerName
        }
        if (key === "ownerAddress") {
          setOwnerAddress(value); // also update ownerAddress in parent so all LOIs have the same ownerAddress
        }
        return {
          ...prevData,
          [key]: value,
        };
      });
    };

    const vesselTemplate = renderTextTemplate(
      formData.vessel,
      details.vessel_name?.context,
      "Vessel Name",
      "vessel",
      details.vessel_name?.confidence,
      isEditable,
      updateFormData
    );
    const portLoadingTemplate = renderTextTemplate(
      formData.portLoading,
      details.port_of_loading?.context,
      "Port of Loading",
      "portLoading",
      details.port_of_loading?.confidence,
      isEditable,
      updateFormData
    );
    const portDischargeTemplate = renderTextTemplate(
      formData.portDischarge,
      details.port_of_discharge?.context,
      "Port of Discharge",
      "portDischarge",
      details.port_of_discharge?.confidence,
      isEditable,
      updateFormData
    );

    const cargoDescriptionTemplate = renderTextTemplate(
      formData.cargo,
      details.cargo_description?.context,
      "Cargo Description",
      "cargo",
      details.cargo_description?.confidence,
      isEditable,
      updateFormData
    );
    const billOfLadingIdentificationNumberTemplate = renderTextTemplate(
      formData.billOfLadingIdentificationNumber,
      details.bill_of_lading_identification_number?.context,
      "Bill of Lading Identification Number",
      "billOfLadingIdentificationNumber",
      details.bill_of_lading_identification_number?.confidence,
      isEditable,
      updateFormData
    );
    const billOfLadingPlaceAndDateOfIssueTemplate = renderTextTemplate(
      formData.billOfLadingPlaceAndDateOfIssue,
      details.bill_of_lading_place_and_date_of_issue?.context,
      "Place and Date of Issue in the Bill of Lading",
      "billOfLadingPlaceAndDateOfIssue",
      details.bill_of_lading_place_and_date_of_issue?.confidence,
      isEditable,
      updateFormData
    );

    const billOfLadingDescriptionTemplate = renderTextTemplate(
      formData.billOfLadingDescription,
      details.bill_of_lading_description?.context,
      "Bill of Lading Description",
      "billOfLadingDescription",
      details.bill_of_lading_description?.confidence,
      isEditable,
      updateFormData
    );

    const shipperTemplate = renderTextTemplate(
      formData.shipper,
      details.shipper_details?.context,
      "Shipper Name",
      "shipper",
      details.shipper_details?.confidence,
      isEditable,
      updateFormData
    );
    const consigneeTemplate = renderTextTemplate(
      formData.consignee,
      details.consignee_details?.context,
      "Consignee Name",
      "consignee",
      details.consignee_details?.confidence,
      isEditable,
      updateFormData
    );

    const deliveryPlaceTemplate = renderTextTemplate(
      formData.deliveryPlace,
      details.delivery_place?.context,
      "Delivery Place",
      "deliveryPlace",
      details.delivery_place?.confidence,
      isEditable,
      updateFormData
    );

    const deliveryPartyTemplate = renderTextTemplate(
      formData.deliveryParty,
      details.delivery_party?.context,
      "Delivery Party",
      "deliveryParty",
      details.delivery_party?.confidence,
      isEditable,
      updateFormData
    );

    const missingValueTemplate = renderTextTemplate(
      formData.missingValue,
      null,
      "Data to be filled out",
      "missingValue",
      "LOW",
      isEditable,
      updateFormData
    );

    const toTemplate = renderTextTemplate(
      formData.ownerName,
      null,
      "Disponent owner",
      "ownerName",
      ownerName ? "HIGH" : details.owners_managers?.confidence,
      isEditable,
      updateFormData
    );

    const dateTemplate = renderTextTemplate(
      formData.currentDate,
      null,
      "Today's date",
      "currentDate",
      "HIGH",
      isEditable,
      updateFormData
    );

    const cargoQuantityTemplate = renderTextTemplate(
      formData.cargoQuantity,
      details.cargo_quantity?.context,
      "Cargo Quantity",
      "cargoQuantity",
      details.cargo_quantity?.confidence,
      isEditable,
      updateFormData
    );

    const requestorPartyTemplate = renderTextTemplate(
      formData.requestorParty,
      null,
      "Requestor Party",
      "requestorParty",
      details.requestor_party?.confidence,
      isEditable,
      updateFormData
    );

    const requestorNameTemplate = renderTextTemplate(
      formData.requestorName,
      null,
      "Requestor Name",
      "requestorName",
      details.requestor_name?.confidence,
      isEditable,
      updateFormData
    );

    const requestorDesignationTemplate = renderTextTemplate(
      formData.requestorDesignation,
      null,
      "Requestor Designation",
      "requestorDesignation",
      details.requestor_designation?.confidence,
      isEditable,
      updateFormData
    );

    const charterPartyNameTemplate = renderTextTemplate(
      formData.charterPartyName,
      details.charter_party_name?.context,
      "Charter Party Name",
      "charterPartyName",
      details.charter_party_name?.confidence,
      isEditable,
      updateFormData
    );

    const charterDateTemplate = renderTextTemplate(
      formData.charterDate,
      details.charter_date?.context,
      "Charter Date",
      "charterDate",
      details.charter_date?.confidence,
      isEditable,
      updateFormData
    );

    const ownerAddressTemplate = renderTextTemplate(
      formData.ownerAddress,
      null,
      "Owner Address",
      "ownerAddress",
      null,
      isEditable,
      updateFormData
    );

    const LabelValueRow = ({ label, value }) => (
      <Grid2 container spacing={2}>
        <Grid2 item xs={12}>
          <Typography variant="body1">
            <Box
              component="span"
              sx={{ display: "flex", alignItems: "flex-start" }}
            >
              <Box component="span" sx={{ fontWeight: "bold", marginRight: 1 }}>
                {label}:
              </Box>
              <Box component="span" sx={{ ...strongStyle }}>
                {value}
              </Box>
            </Box>
          </Typography>
        </Grid2>
      </Grid2>
    );
    LabelValueRow.propTypes = {
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    };

    const HeaderSection = ({ type }) => (
      <>
        <Typography
          variant="body1"
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Box>
            To:
            <Box component="strong" sx={{ ml: 4, ...strongStyle }}>
              {toTemplate}
            </Box>
          </Box>
          {type !== "NAVIGATOR_LOI_BLENDING_CARGO" && (
            <Box>
              Date:
              <Box component="strong" sx={{ ml: 1, ...strongStyle }}>
                {dateTemplate}
              </Box>
            </Box>
          )}
        </Typography>

        <>
          <Typography sx={{ ml: 7 }} variant="body1">
            The Owners of the&nbsp;{vesselTemplate}
          </Typography>
          {type !== "NAVIGATOR_LOI_BLENDING_CARGO" && (
            <Typography sx={{ ml: 7 }} variant="body1">
              {ownerAddressTemplate}
            </Typography>
          )}
        </>

        <Typography sx={{ mt: 2 }} variant="body1">
          Dear Sirs,
        </Typography>
      </>
    );
    HeaderSection.propTypes = {
      type: PropTypes.string.isRequired,
    };

    const renderGrid = (gridData, marginLeft) => (
      <Grid2 container direction="column" sx={{ mt: 2, ml: marginLeft }}>
        {gridData.map(({ label, value }, index) => (
          <Grid2 key={index}>
            <LabelValueRow label={label} value={value} />
          </Grid2>
        ))}
      </Grid2>
    );

    const renderHeader = () => {
      const headerCommonProps = {
        type: staticData.loiTemplate.type,
      };

      const gridDataNavigatorVoyage = [
        {
          label: "Ship",
          value: vesselTemplate,
        },
        {
          label: "Voyage",
          value: (
            <>
              {portLoadingTemplate} to {portDischargeTemplate}
            </>
          ),
        },
        {
          label: "Cargo",
          value: (
            <>
              {cargoDescriptionTemplate}{" "}
              {isLoi2Loi ? null : cargoQuantityTemplate}
            </>
          ),
        },
        {
          label: "Bill of Lading",
          value: (
            <>
              {isLoi2Loi ? (
                billOfLadingDescriptionTemplate
              ) : (
                <>
                  {billOfLadingIdentificationNumberTemplate}{" "}
                  {billOfLadingPlaceAndDateOfIssueTemplate}
                </>
              )}
            </>
          ),
        },
      ];

      const gridDataNavigatorVoyageSplit = [
        { label: "Ship", value: vesselTemplate },
        { label: "Load port", value: portLoadingTemplate },
        { label: "Delivery port", value: portDischargeTemplate },
        { label: "Cargo", value: cargoDescriptionTemplate },
        { label: "Cargo quantity", value: cargoQuantityTemplate },
        {
          label: "Bill of Lading",
          value: (
            <>
              {isLoi2Loi ? (
                billOfLadingDescriptionTemplate
              ) : (
                <>
                  {billOfLadingIdentificationNumberTemplate}{" "}
                  {billOfLadingPlaceAndDateOfIssueTemplate}
                </>
              )}
            </>
          ),
        },
        {
          label: "Charter Party",
          value: (
            <>
              {toTemplate} {charterPartyNameTemplate} {charterDateTemplate}
            </>
          ),
        },
      ];

      const gridDataDefault = [
        { label: "Vessel", value: vesselTemplate },
        { label: "Port of Loading", value: portLoadingTemplate },
        { label: "Port of Discharge", value: portDischargeTemplate },
        {
          label: "Cargo",
          value: (
            <>
              {cargoDescriptionTemplate}{" "}
              {isLoi2Loi ? null : cargoQuantityTemplate}
            </>
          ),
        },
        {
          label: "Bill of Lading",
          value: (
            <>
              {isLoi2Loi ? (
                billOfLadingDescriptionTemplate
              ) : (
                <>
                  {billOfLadingIdentificationNumberTemplate}{" "}
                  {billOfLadingPlaceAndDateOfIssueTemplate}
                </>
              )}
            </>
          ),
        },
      ];

      switch (staticData.loiTemplate.type) {
        case "NAVIGATOR_LOI_NO_BILL":
        case "NAVIGATOR_LOI_OTHER_PORT_NO_BILL":
          return (
            <>
              <HeaderSection {...headerCommonProps} />
              {renderGrid([...gridDataNavigatorVoyage])}
            </>
          );
        case "NAVIGATOR_LOI_SUBSTITUTE_BL":
          return (
            <>
              <HeaderSection {...headerCommonProps} />
              {renderGrid(gridDataNavigatorVoyageSplit)}
            </>
          );
        case "NAVIGATOR_LOI_BLENDING_CARGO":
          return (
            <>
              <Typography variant="body1" sx={{ mt: 2, mb: 2 }}>
                <Box component="strong" sx={{ ...strongStyle }}>
                  {staticData.currentDate}
                </Box>
              </Typography>

              <HeaderSection {...headerCommonProps} />
              {renderGrid([...gridDataNavigatorVoyage])}
            </>
          );
        default:
          return (
            <>
              <Typography variant="body1">
                To:
                <Box component="strong" sx={{ ml: 4, ...strongStyle }}>
                  {toTemplate}
                </Box>
              </Typography>

              <Typography sx={{ ml: 7 }} variant="body1">
                and/or the Owners and/or Managers of the&nbsp;
                {vesselTemplate}
              </Typography>
              <Typography sx={{ ml: 7 }} variant="body1">
                {ownerAddressTemplate}
              </Typography>

              <Typography variant="body1" sx={{ mt: 2 }}>
                Date:
                <Box component="strong" sx={{ ml: 2, ...strongStyle }}>
                  {dateTemplate}
                </Box>
              </Typography>

              <Typography sx={{ mt: 2 }} variant="body1">
                Dear Sirs,
              </Typography>

              {renderGrid(gridDataDefault, 7)}
            </>
          );
      }
    };

    // Function to render cargo description based on template type
    const renderCargoDescription = () => {
      if (!staticData.loiTemplate) return null;
      switch (staticData.loiTemplate.type) {
        case "DEFAULT_LOI_NO_BILL":
          return (
            <Typography variant="body1" sx={{ marginTop: 2 }}>
              The above cargo was shipped on the above vessel by&nbsp;
              {shipperTemplate}
              &nbsp;and consigned to&nbsp;
              {consigneeTemplate}
              &nbsp;for delivery at the Port of Discharge but the Bill(s) of
              Lading is (are) not currently available to be presented.
            </Typography>
          );

        case "DEFAULT_LOI_OTHER_PORT":
          return (
            <Typography variant="body1" sx={{ marginTop: 2 }}>
              The above cargo was shipped on the above vessel by&nbsp;
              {shipperTemplate}
              &nbsp;and consigned to&nbsp;
              {consigneeTemplate}
              &nbsp;for delivery at the Port of Discharge but we,{" "}
              {requestorPartyTemplate}&nbsp; hereby request you to order the
              Vessel to proceed to and deliver the said cargo at&nbsp;
              {deliveryPlaceTemplate}
              &nbsp;against production of at least one original Bill(s) of
              Lading.
            </Typography>
          );

        case "DEFAULT_LOI_NO_BILL_OTHER_PORT":
          return (
            <Typography variant="body1" sx={{ marginTop: 2 }}>
              The above cargo was shipped on the above vessel by&nbsp;
              {shipperTemplate}
              &nbsp;and consigned to&nbsp;
              {consigneeTemplate}
              &nbsp;for delivery at the Port of Discharge but we,{" "}
              {requestorPartyTemplate}&nbsp; hereby request you to order the
              Vessel to proceed to and deliver the said cargo at&nbsp;
              {deliveryPlaceTemplate}.
            </Typography>
          );
        case "NAVIGATOR_LOI_NO_BILL":
          return (
            <Typography variant="body1" sx={{ marginTop: 2 }}>
              The above cargo was shipped on the above vessel by{" "}
              {shipperTemplate} and consigned to {consigneeTemplate} for
              delivery at the port of {portDischargeTemplate} but the bill of
              lading has not arrived and we, {requestorPartyTemplate}, hereby
              request you to order the Vessel to proceed to and deliver the said
              cargo to {deliveryPartyTemplate} or to such party as you believe
              to be or to represent {deliveryPartyTemplate} or to be acting on
              behalf of {deliveryPartyTemplate} at {deliveryPlaceTemplate}{" "}
              without production of the original bill of lading.
            </Typography>
          );
        case "NAVIGATOR_LOI_OTHER_PORT_NO_BILL":
          return (
            <Typography variant="body1" sx={{ marginTop: 2 }}>
              The above cargo was shipped on the above vessel by{" "}
              {shipperTemplate} and consigned to {consigneeTemplate} for
              delivery at the port of {portDischargeTemplate} but we,{" "}
              {requestorPartyTemplate}, hereby request you to order the vessel
              to proceed to and deliver the said cargo at{" "}
              {deliveryPlaceTemplate} to {deliveryPartyTemplate} or to such
              party as you believe to be or to represent {deliveryPartyTemplate}{" "}
              or to be acting on behalf of {deliveryPartyTemplate} without
              production of the original bill of lading.
            </Typography>
          );
        case "NAVIGATOR_LOI_SUBSTITUTE_BL":
          return (
            <Typography variant="body1" sx={{ marginTop: 2 }}>
              The above cargo was shipped on the above ship by {shipperTemplate}
              , and consigned to {consigneeTemplate} for delivery at{" "}
              {portDischargeTemplate} pursuant to the above bill of lading
              issued in a set of three (the “First Bills of Lading”). We,{" "}
              {charterPartyNameTemplate} Charterers under the above Charter
              Party, hereby (i) confirm that upon signing this letter of
              indemnity we shall procure and, as soon as possible thereafter,
              provide to you all of the originals of the First Bills of Lading
              and (ii) request that following receipt by you or your appointed
              agent of all of the originals of the First Bills of Lading you or
              your appointed agent shall mark them “null and void” and,
              thereupon, shall issue and release a further bill of lading in a
              set of three (the “Substitute Bills of Lading”) for the same cargo
              in the wording attached hereto (attach copy of intended switch
              bill of lading);
            </Typography>
          );
        case "NAVIGATOR_LOI_BLENDING_CARGO":
          return (
            <Typography variant="body1" sx={{ marginTop: 2 }}>
              The above cargo was shipped on the above vessel by{" "}
              {shipperTemplate} and consigned to {consigneeTemplate} for
              delivery at the port of {portDischargeTemplate}.
            </Typography>
          );
        default:
          return null;
      }
    };

    // Function to render indemnity request based on template type
    const renderIndemnityRequest = () => {
      if (!staticData.loiTemplate) return null;
      switch (staticData.loiTemplate.type) {
        case "DEFAULT_LOI_NO_BILL":
          return (
            <Typography variant="body1" sx={{ marginTop: 2 }}>
              We, {requestorPartyTemplate} hereby represent and undertake
              that&nbsp;
              {deliveryPartyTemplate}
              &nbsp;is the party lawfully entitled to delivery of the said Cargo
              and request you to deliver the said Cargo to&nbsp;
              {deliveryPartyTemplate}
              &nbsp;or to such party as you believe to be or to represent&nbsp;
              {deliveryPartyTemplate}
              &nbsp;or to be acting on behalf of&nbsp;
              {deliveryPartyTemplate}
              &nbsp;at&nbsp;
              {deliveryPlaceTemplate}
              &nbsp;without production of the original Bill(s) of Lading.&nbsp;
            </Typography>
          );
        case "DEFAULT_LOI_NO_BILL_OTHER_PORT":
          return (
            <Typography variant="body1" sx={{ marginTop: 2 }}>
              Further, as the Bill(s) of Lading is (are) not currently available
              to be presented, we also hereby represent and undertake that&nbsp;
              {deliveryPartyTemplate}
              &nbsp;is the party lawfully entitled to delivery of the said Cargo
              and request you to deliver the said Cargo to&nbsp;
              {deliveryPartyTemplate}
              &nbsp;or to such party as you believe to be or to represent&nbsp;
              {deliveryPartyTemplate}
              &nbsp;or to be acting on behalf of&nbsp;
              {deliveryPartyTemplate}
              &nbsp;at&nbsp;
              {deliveryPlaceTemplate}
              &nbsp;without production of the original Bill(s) of Lading.&nbsp;
            </Typography>
          );
        case "NAVIGATOR_LOI_BLENDING_CARGO":
          return (
            <Typography variant="body1" sx={{ marginTop: 2 }}>
              We, {requestorPartyTemplate} hereby request you to proceed to{" "}
              {portLoadingTemplate}, and commingle / blend{" "}
              {cargoDescriptionTemplate} with the cargo described above and
              presently on board CT# {missingValueTemplate}, for discharge at{" "}
              {portDischargeTemplate}.
            </Typography>
          );
        default:
          return null;
      }
    };

    // Function to render indemnity agreement text for template
    const renderIndemnityAgreementText = () => {
      if (!template) return null;
      let indemnityList = template.indemnityAgreement;
      return (
        <List sx={{ listStyle: "none", marginLeft: "-1rem" }}>
          {indemnityList.map((item, index) => (
            <ListItem key={index} sx={{ display: "list-item" }}>
              <ListItemText primary={item} />

              {/* If the sublist is not empty, render it */}
              {index === 2 && template.indemnitySublist.length > 0 && (
                <List sx={{ paddingLeft: 4 }}>
                  {template.indemnitySublist.map((subItem, subIndex) => (
                    <ListItem key={subIndex} sx={{ display: "list-item" }}>
                      <ListItemText primary={subItem} />
                    </ListItem>
                  ))}
                </List>
              )}
            </ListItem>
          ))}
        </List>
      );
    };

    // Function to render indemnity terms for template
    const renderIndemnityTerms = () => {
      if (!template) return null;
      return (
        <Typography variant="body1" sx={{ marginTop: 2 }}>
          {template.indemnityTerms}
        </Typography>
      );
    };

    // Function to render signature text for template
    const renderSignatureText = () => {
      if (!template) return null;
      switch (staticData.loiTemplate.type) {
        case "NAVIGATOR_LOI_NO_BILL":
        case "NAVIGATOR_LOI_OTHER_PORT_NO_BILL":
        case "NAVIGATOR_LOI_BLENDING_CARGO":
          return (
            <>
              <Typography variant="body1" sx={{ marginTop: 2 }}>
                Yours faithfully
              </Typography>
              <Typography variant="body1">For and on behalf of</Typography>
              {requestorNameTemplate}
              <Typography variant="body1">The Requestor</Typography>
              <Typography sx={{ marginTop: 6 }} variant="body1">
                …………………………………
              </Typography>
              <Typography variant="body1">Signature </Typography>
            </>
          );
        case "NAVIGATOR_LOI_SUBSTITUTE_BL":
          return (
            <>
              <Typography variant="body1" sx={{ marginTop: 2 }}>
                Yours faithfully
              </Typography>
              <Typography variant="body1">For and on behalf of</Typography>
              {charterPartyNameTemplate}
              <Typography sx={{ marginTop: 6 }} variant="body1">
                …………………………………
              </Typography>
              <Typography variant="body1">Signature </Typography>
            </>
          );
        default:
          return (
            <>
              <Typography variant="body1" sx={{ marginTop: 2 }}>
                Yours faithfully
              </Typography>
              <Typography variant="body1">For and on behalf of</Typography>
              {requestorPartyTemplate}
              <Typography variant="body1">The Requestor</Typography>
              <Typography variant="body1">Signature …………………………………</Typography>
              <Typography variant="body1">Name: </Typography>
              {requestorNameTemplate}
              <Typography variant="body1">Title: </Typography>
              {requestorDesignationTemplate}
            </>
          );
      }
    };

    const generationCard = (
      <GenerationCard
        title={`LOI Preview - ${
          isLoi2Loi
            ? formData.billOfLadingDescription !== "MISSING"
              ? formData.billOfLadingDescription
              : ""
            : formData.billOfLadingIdentificationNumber !== "MISSING"
              ? `B/L No. ${formData.billOfLadingIdentificationNumber}`
              : ""
        }`}
        multiple={multiple.isMultipleGeneration}
        handleToggleExport={multiple.handleToggleExport}
        toggleEdit={toggleEdit}
        id={details.doc_id}
      >
        <Box sx={contentStyle} id={`text-content-${details.doc_id}`}>
          {renderHeader()}
          <Divider
            sx={{ marginY: 2, background: theme.palette.primary.blue3 }}
          />
          {/* Rendered based on Loi Template Type */}
          {renderCargoDescription()}
          {renderIndemnityRequest()}
          {renderIndemnityTerms()}
          {renderIndemnityAgreementText()}
          {renderSignatureText()}
        </Box>
        <ExportButtons
          actions={{
            handleCopy: () => handleCopyToClipboard(),
            handleExportPDF: () => handleSaveAsPDFLOI(),
            handleExportDocx: () => handleSaveAsWordLOI(),
          }}
          validation={false}
        />
        <Feedback requestId={requestId} type={"LOI2LOI"} />
      </GenerationCard>
    );

    return (
      <PreviewWrapper generationCard={generationCard} file={staticData.file}>
        <ShipsterCard level={0} title="Source Preview">
          <DocumentViewer file={staticData.file} />
        </ShipsterCard>
      </PreviewWrapper>
    );
  }
);

Indemnity.displayName = "Indemnity";
Indemnity.propTypes = {
  details: PropTypes.object.isRequired,
  staticData: PropTypes.shape({
    currentDate: PropTypes.string.isRequired,
    requestorCompany: PropTypes.string,
    owners: PropTypes.string,
    letterheadImage: PropTypes.string,
    loiTemplate: PropTypes.object,
    file: PropTypes.object,
  }).isRequired,
  requestId: PropTypes.string.isRequired,
  multiple: PropTypes.shape({
    isMultipleGeneration: PropTypes.bool.isRequired,
    handleToggleExport: PropTypes.func.isRequired,
  }),
  requestorName: PropTypes.string,
  setRequestorName: PropTypes.func,
  requestorDesignation: PropTypes.string,
  setRequestorDesignation: PropTypes.func,
  ownerName: PropTypes.string,
  setOwnerName: PropTypes.func,
  ownerAddress: PropTypes.string,
  setOwnerAddress: PropTypes.func,
  isLoi2Loi: PropTypes.bool,
};

export default Indemnity;
