import React from "react";
import { Box, Typography } from "@mui/material";
import { contentStyle } from "../styles/templateStyles";
import {
  NoPaddedText,
  TopPaddedText,
  Section,
  DetailItem,
} from "./TemplateSections";

export const getNavigatorBunkerInstructionsTemplate = ({
  cargo,
  date,
  port,
  vessel,
  vesselImo,
  surveyor,
  agent,
  supplier,
  missingValue,
}) => {
  return {
    text1: `
            ++++ Please copy technical@navigatorgas.com in this message +++
        
            Dear Captain ${missingValue}

            Please note that we have arranged bunker for your good vessel as per below:

            <b>Vessel:</b> ${vessel}(${vesselImo})
            <b>Port:</b> ${port}
            <b>ETA:</b> ${date}
            <b>Physical Supplier:</b> ${supplier}
            <b>Spec & Quantity:</b> ${cargo}
            <b>Surveyor:</b> ${surveyor}
            <b>Agent:</b> ${agent}

            In attachment, please see Owners Advice for bunkering operation, NOR, SOF templates which kindly fill out and submit to us after sailing together with BDN.

            Please note we have updated our “Guidelines to the Vessel for Bunkering Operations”, please review carefully together with CE.   

            <b>${agent} (RIC)</b>  -
            Please kindly liaise closely with Master, bunker supplier, surveyor for smooth and timely bunker delivery and advise if any additional arrangements may be needed. 
            If landing sample received by you, please keep us and technical@navigatorgas.com updated on the AWB number. 

            <b>VIKING MARINE (RIC)</b> -
            Please treat this message as our official appointment. 
            Please note bunker operation should not commence without your presence, therefore please keep close communication with agent /supplier to ensure timely attendance. 
            Master has been advised about your appointment and vessel crew will assist you during your attendance. 

            <b><u>IMPORTANT</u></b> 
            <b><i>Master/Agent - Please do not include bunker times in main port SOF/any cargo documents - only on attached separate SOF.</i></b> 

            <b>All samples taken must be witnessed by the crew & Surveyor.</b> 
            <b><u>DO NOT</u></b> accept unknown samples from the supplier/ barge. 
            <b>Sample should be sent urgently to testing at designated Lab. Please forward the Landing sample advise to Technical and Operations.</b> 

            Should any discrepancies on bunker quantities received exceed <b>1.5 mt for VLFO and 1mt for LSMGO</b> the Master must inform designated operator arranging bunkers immediately.        

            <b><u>Hoses should not be disconnected, and barge should NOT be cast off unless approval is obtained by the operation department.</u></b> 

            Thank you very much and please acknowledge safe receipt. 
            `,
  };
};

export const getFilledNavigatorBunkerInstructionsTemplate = ({
  cargo,
  date,
  port,
  vessel,
  vesselImo,
  surveyor,
  agent,
  supplier,
}) => {
  return (
    <Box sx={contentStyle} style={{ ...contentStyle }} id="text-content">
      <Typography
        variant="body1"
        sx={{ mt: 2 }}
        style={{ fontSize: 20, color: "red" }}
      >
        ++++ Please copy technical@navigatorgas.com in this message +++{" "}
      </Typography>
      <Section title="Dear Captain">
        <TopPaddedText text="Please note that we have arranged bunker for your good vessel as per below: " />
        <Typography style={{ mt: 2 }} variant="body1">
          <b>Vessel:</b> {vessel} ({vesselImo})
        </Typography>
        <DetailItem label="Port" value={port} bold />
        <DetailItem label="ETA" value={date} bold />
        <DetailItem label="Physical Supplier" value={supplier} bold />
        <DetailItem label="Spec & Quantity" value={cargo} bold />
        <DetailItem label="Surveyor" value={surveyor} bold />
        <DetailItem label="Agent" value={agent} bold />
        <TopPaddedText text="In attachment, please see Owners Advice for bunkering operation, NOR, SOF templates which kindly fill out and submit to us after sailing together with BDN. " />
        <TopPaddedText text="Please note we have updated our “Guidelines to the Vessel for Bunkering Operations”, please review carefully together with CE." />
      </Section>
      <Typography variant="body1" sx={{ mt: 2 }} style={{ fontWeight: "bold" }}>
        {agent} (RIC) -
      </Typography>
      <NoPaddedText text="Please kindly liaise closely with Master, bunker supplier, surveyor for smooth and timely bunker delivery and advise if any additional arrangements may be needed." />
      <NoPaddedText text="If landing sample received by you, please keep us and technical@navigatorgas.com updated on the AWB number. " />
      <Section title="VIKING MARINE (RIC) - " bold>
        <NoPaddedText text="Please treat this message as our official appointment. " />
        <NoPaddedText text="Please note bunker operation should not commence without your presence, therefore please keep close communication with agent /supplier to ensure timely attendance." />
        <NoPaddedText text="Master has been advised about your appointment and vessel crew will assist you during your attendance." />
      </Section>
      <Section title="IMPORTANT" textDecoration="underline" bold>
        <NoPaddedText
          text="Master/Agent - Please do not include bunker times in main port SOF/any cargo documents - only on attached separate SOF."
          italics
          bold
        />
        <TopPaddedText
          text="All samples taken must be witnessed by the crew & Surveyor. "
          bold
        />
        <Typography variant="body1" style={{ fontWeight: "bold" }}>
          <span style={{ textDecoration: "underline" }}>DO NOT</span> accept
          unknown samples from the supplier/ barge.
        </Typography>
        <NoPaddedText
          text="Sample should be sent urgently to testing at designated Lab. Please forward the Landing sample advise to Technical and Operations."
          bold
        />
        <Typography style={{ marginTop: "16px" }} variant="body1">
          Should any discrepancies on bunker quantities received exceed{" "}
          <span style={{ fontWeight: "bold" }}>
            1.5 mt for VLFO and 1 mt for LSMGO{" "}
          </span>
          the Master must inform designated operator arranging bunkers
          immediately.
        </Typography>
        <TopPaddedText
          text="Hoses should not be disconnected, and barge should NOT be cast off unless approval is obtained by the operation department. "
          textDecoration="underline"
          bold
        />
        <TopPaddedText text="Thank you very much and please acknowledge safe receipt." />
      </Section>
    </Box>
  );
};
