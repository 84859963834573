import React, { useState, useContext } from "react";
import { Box, Typography, Alert, Select, MenuItem } from "@mui/material";
import { generateInstructions } from "../../services/recap2viService";
import { UserContext } from "../../context/UserContext";
import Recap2InstructionsResult from "./Recap2InstructionsResult";
import Feedback from "../../components/common/Feedback";
import ToolHeader from "../../components/common/ToolHeader";
import ToolStepProgressBar from "../../components/common/ToolStepProgressBar";
import Step1Buttons from "../../components/common/Step1Buttons";
import CloseFooter from "../../components/common/CloseFooter";
import { getOrganisationFromUser } from "../../utils/organisationUtils";
import LoadingOverlay from "../../components/common/LoadingOverlay";
import { getToolMessages } from "../../utils/loaderUtils";
import ShipsterCard from "../../components/common/ShipsterCard";
import { voyageInstructionsChoices } from "../../templates/utils";
import TextInputCard from "../../components/common/TextInputCard";

const Recap2Instructions = () => {
  const [inputText, setInputText] = useState("");
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { user } = useContext(UserContext);
  const steps = ["Upload", "Generate Instructions"];
  const [currentStep, setCurrentStep] = useState(0);
  const loiTemplates =
    voyageInstructionsChoices[user.orgId] || voyageInstructionsChoices.default;

  const [selectedVITemplate, setSelectedVITemplate] = useState(loiTemplates[0]);

  const handleStepChange = (step) => {
    if (step <= currentStep) setCurrentStep(step);
  };

  const handleGeneration = async () => {
    if (!inputText.trim()) {
      alert("Please enter fixture recap text");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const result = await generateInstructions(
        inputText,
        getOrganisationFromUser(user)
      );
      setResult(result);
      setCurrentStep(1);
    } catch (err) {
      setError("Error processing fixture recap: " + err.message);
      console.error("Error:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <ToolHeader
        title={"Recap -> Voyage Instructions"}
        subtext={"Generate Voyage Instructions from a clean fixture recap"}
      ></ToolHeader>
      <ToolStepProgressBar
        steps={steps}
        currentStep={currentStep}
        onStepChange={handleStepChange}
      />

      {(!result || currentStep === 0) && (
        <>
          {error && (
            <Alert severity="error" sx={{ my: 2 }}>
              {error}
            </Alert>
          )}

          <TextInputCard
            title="Upload"
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            placeholder="Paste your recap text here..."
            rows={25}
          />
          {inputText.length > 0 && loiTemplates.length > 1 && (
            <ShipsterCard title={"Select Template"} level={1}>
              <Typography variant="body1" sx={{ fontWeight: 500, mb: 1 }}>
                Choose Voyage Instructions Template
              </Typography>
              <Select
                value={selectedVITemplate}
                onChange={(e) => setSelectedVITemplate(e.target.value)}
                fullWidth
                variant="outlined"
                renderValue={(value) => value.label}
              >
                {loiTemplates.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </ShipsterCard>
          )}

          {/* Submit Button */}
          <Step1Buttons
            title={"Generate Instructions"}
            validation={!inputText.length === 0 || loading}
            handleMoveNextStep={handleGeneration}
          ></Step1Buttons>

          {loading && (
            <LoadingOverlay
              messages={getToolMessages["RECAP2VOYAGE_INSTRUCTIONS_GENERATION"]}
            />
          )}
        </>
      )}
      {result && currentStep === 1 && (
        <>
          <Recap2InstructionsResult
            response={result}
            file={inputText}
            template={selectedVITemplate.type}
          />
          <Feedback
            requestId={result.requestId}
            type={"Voyage Instructions"}
          ></Feedback>
          <CloseFooter />
        </>
      )}
    </Box>
  );
};

export default Recap2Instructions;
